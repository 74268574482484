/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Autocomplete, Box, Button, MenuItem, Paper, TextField, ThemeProvider, Tooltip, Typography } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import React, { useEffect, useRef, useState } from 'react';
import { theme } from '../../Medical_History_Forms/themeprovider';
import PriscriptionSVG from '../../../assets/SVG/Prescription.svg';
import styles from './Cyclelist.module.css';
import { ProcedureMaster, ServiceMaster } from './cyclelist-helper';
import addCircle from '../../../assets/SVG/add-black.svg';
import { useAlert } from '../../alertProvider';
import { useLoader } from '../../loaderProvider/loader';
import cart from '../../../assets/SVG/shopping-cart.svg';
import  CloseCircle  from '../../../assets/SVG/closecircle.svg';
import closeCircleRed from '../../../assets/SVG/close-circle.svg';
import { getProcedures, getTreatmentServices } from './cyclelist-helper';
import CreateCycle from '../createCycle/createCycle';
import { getDoctors, User } from '../../Diagnosis/diagnosisHelper';
import { ConfirmationModal } from '../../confirmationModal';
import { useNavigate, useParams } from 'react-router-dom';
import { addProcedures, PatientProcedureCreate } from '../createCycle/createCycle-helper';
import { usePatientContext } from '../../patinetProvider';
import { patientDetailsObservable } from '../../../services/sharedService';
import { debounce } from 'lodash';

type OnDataType = (value: boolean) => void;

const CycleList = ({ onClose }: { onClose: OnDataType }) => {

  const [selectedProcedures , SetSelectedProcedures] = useState<ServiceMaster[]>([]);
  const { addAlert } = useAlert();
  const [CycleRelatedProcedures, setCycleRelatedProcedures]= useState<ProcedureMaster[]>([]); 
  const [NonCycleRelatedProcedures, setNonCycleRelatedProcedures]= useState<ServiceMaster[]>([]); 
  const { showLoader, hideLoader } = useLoader();
  const [hoveredButton, setHoveredButton] = useState<number | null>(null);
  const onDataRef = useRef<OnDataType | null>(null); // Initialize with null
  const [selectedCycle, setSelectedCycle] = useState<ProcedureMaster |null>(null);
  const [selectedDoctorid , setSelectedDoctorId] = useState<number | null>(null);
  const [doctorError, setDoctorError] = useState(false); 
  const [doctors, setDoctors] = useState<User[]>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const { id } = useParams<{ id: string }>(); 
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { patientId } = usePatientContext();
  const [showAll, setShowAll] = useState(false); // State to toggle between showing 10 or all items
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [patientDetails, setpatientDetails] = useState<any >(null);

  useEffect(() => {
    const subscription = patientDetailsObservable.subscribe((value) => {
      setpatientDetails(value);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(()=>{
    console.log(patientDetails);
    
  }, [patientDetails]);
  // Determine the procedures to display based on `showAll` state
  const displayedProcedures = showAll
    ? NonCycleRelatedProcedures
    : NonCycleRelatedProcedures.slice(0, 10);

  useEffect(() => {
    onDataRef.current = onClose;
  }, [onClose]);
  

  const onBack = () => {
    onClose(false);
  };



  const handleCycleClick = (cycle: ProcedureMaster) => {
    setSelectedCycle(cycle);
  };


  const handleCloseCycleList = () => {
    setSelectedCycle(null);
  };
    
  useEffect(() => {
    if (!patientDetails) return;

    const fetchProcedures = (url:string) => {
      showLoader();
      getProcedures(url)
        .then((data) => {
          setCycleRelatedProcedures(data.objects);
        })
        .catch((error) => console.error('Error fetching procedures:', error))
        .finally(() => hideLoader());
    };

    const fetchNonCycleRelatedProcedures = () => {
      showLoader();
      getTreatmentServices(`?service_type=NON_CYCLE_RELATED&gender=${patientDetails.gender}&search=${searchTerm}`)
        .then((data) => {
          setNonCycleRelatedProcedures(data.objects);
        })
        .catch((error) => console.error('Error fetching non-cycle-related services:', error))
        .finally(() => hideLoader());
    };

    const patientType = patientDetails.patient_type;
    const gender = patientDetails.gender;
    const isCoupleOrSingle = ['Patient-Single', 'PATIENT_SINGLE', 'Patient-Couple', 'PATIENT_COUPLE'].includes(patientType);
    const proceduresUrl = patientType === 'OOCYTE_DONOR'
      ? `?for_icon=OOCYTE_DONOR|${gender}&name__icontains=${searchTerm}`
      : isCoupleOrSingle
        ? `?for_icon=PATIENT_COUPLE|${gender}&name__icontains=${searchTerm}`
        : `?for_icon=${patientType}|${gender}&name__icontains=${searchTerm}`;

    const debouncedFetch = debounce(() => {
      fetchProcedures(proceduresUrl);
      fetchNonCycleRelatedProcedures();
    }, 200); // 500ms debounce delay

    debouncedFetch();

    // Cleanup the debounced function on unmount
    return () => debouncedFetch.cancel();
  }, [searchTerm, patientDetails, showLoader, hideLoader]);

  

  useEffect(()=>{
    // Fetch doctors
    getDoctors()
      .then((res) => {
        setDoctors(res.objects);
      })
      .catch((error) => {
        console.error('Error fetching doctors:', error);
      });
  },[]);
  const handleAddProcedure = (order: ServiceMaster) =>{
    const isOrderAlreadyAdded = selectedProcedures.some((service) => service.id === order.id);

    if (isOrderAlreadyAdded) {
      addAlert('This service is already added.', 'info');
    } else {
      SetSelectedProcedures((prev) => [...prev, order]);
    }
  };

  const [showOverlay, setShowOverlay] = useState(false);

  const handleToggleOverlay = () => {
    setShowOverlay((prev) => !prev);
  };

  const handleRemoveService = (index:number) => {
    SetSelectedProcedures((prev) => {
      const updatedServices = prev.filter((_, i) => i !== index);
      return updatedServices;
    });
  };

  function handleDoctorChange( value: number): void {
    setSelectedDoctorId(value);
    setDoctorError(false);
  }
  const handleOpenModal=()=>{
    if (!selectedDoctorid) {
      setDoctorError(true); // Set error if no doctor is selected
      return;
    }

    setOpenModal(true);
  };

  const handleCloseModal = () => {setOpenModal(false);};


  const handleSave = ()=>{

    console.log('selectedProceduresselectedProcedures', selectedProcedures);

    showLoader();
    const convertedData = selectedProcedures.map(test => ({
      patient: Number(id),
      procedures:null,
      services: [test.id],
      indication:null,
      doctor:selectedDoctorid?selectedDoctorid  :0,
      donor_info:null,
      oocyte_source_self:false,
      sperm_source_self:false,
      recipient_self:false,
      procedure_notes:test.notes
    }));


    const sendProcedure = async (data:PatientProcedureCreate) => {
      return await addProcedures(data);
    };

    Promise.all(convertedData.map(data => sendProcedure(data)))
      .then(() => {
        addAlert('Order placed successfully.', 'success');
        SetSelectedProcedures([]);
        setSelectedCycle(null);
        setDoctorError(false);
        setSelectedDoctorId(null);
        hideLoader();

        navigate(`/clinical/patients/treatment-plan/${patientId}?view=procedure_list`);
        onBack();  

      })
      .catch((error) => {
        console.error('An error occurred:', error);
        addAlert('An error occurred while saving the tests. Please try again.', 'error');
      });


    
  };


  const handleRemarkChange = (index: number, value: string) => {
    const updatedProcedures = [...selectedProcedures];
    updatedProcedures[index] = { ...updatedProcedures[index], notes: value };
    SetSelectedProcedures(updatedProcedures);
  };
  return (

    <ThemeProvider theme={theme}>

      <>
        {selectedCycle ? (
        // Render CycleCreate Component
          <Box sx={{ padding: 2 }}>
            <CreateCycle cycleDetails={selectedCycle} onClose={handleCloseCycleList} />
          </Box>
        ) : (
          <div>
            <Grid2 container sx={{ width: '100%' }}>
  
              {/* Search Field */}
              <Grid2 offset={6} size={4} justifyContent="flex-end"  sx={{
                alignSelf: 'flex-end', // Align this specific item to the bottom of the container
              }} >
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Search"
                  placeholder="Search by Procedure, Services, code"
                  size="small"
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  sx={{
                  
                    '& .MuiInputBase-root': {
                      height: 46, // Adjust height here
                    },
                  }}
                /> 
              </Grid2>
  
              {/* Cart Button */}
              <Grid2 size={2}>
                <div   style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', marginRight:'15px' }}>
                  {/* Notification Badge and Cart Button */}
                  <div>
                    <span
                      style={{
                        position: 'relative',
                        top: '25px',
                        left: '35px',
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#f00',
                        color: '#fff',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        zIndex: 1111,
                      }}
                    >
                      {selectedProcedures.length}
                    </span>
                    <Button
                      onClick={handleToggleOverlay}
                      style={{
                        width: '55px',
                        height: '53px',
                        border: 'solid 1px #E2E3E5',
                        borderRadius: '15px',
                        padding: '0px',
                        zIndex: 1000,
                        backgroundColor: '#FFF',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <img src={cart} alt="Cart Icon" />
                    </Button>
                  </div>
  
                  {/* Overlay */}
                  {showOverlay && (
                    <Paper
                      className={styles.overlayFadeIn}
                      elevation={4}
                      style={{
                        position: 'absolute',
                        top: '85px',
                        right: '0',
                        width: '400px',
                        backgroundColor: '#E2E3E5',
                        borderRadius: '10px',
                        padding: '16px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
                        zIndex: 2000,
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          right: '20px',
                          width: '0',
                          height: '0',
                          borderLeft: '10px solid transparent',
                          borderRight: '10px solid transparent',
                          borderBottom: '10px solid #E2E3E5',
                          zIndex: 2001,
                        }}
                      ></div>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                          padding: '10px',
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontSize: '18px',
                            fontWeight: 700,
                          }}
                        >
                  Orders ({selectedProcedures.length})
                        </Typography>
                        <img src={CloseCircle} alt="close"  onClick={handleToggleOverlay}/>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: '#FFF',
                          borderRadius: '10px',
                          padding: '10px',
                          maxHeight: '250px',
                          overflow:'auto'
                        }}
                      >

                        {selectedProcedures.length > 0 && (
                          <Grid2 size={12}  sx={{
                            padding:'16px'
                          }}  >
                            <Autocomplete
                              options={doctors}
                              getOptionLabel={(option) => `Dr. ${option.first_name} ${option.last_name}`}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  handleDoctorChange(newValue.id); // Passes the doctor ID
                                } else {
                                  handleDoctorChange(-1); // Pass a default ID or handle null as needed
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Doctor"
                                  variant="outlined"
                                  error={doctorError} // Show error state
                                  helperText={doctorError ? 'Doctor is required' : ''} // Error message
                                  fullWidth
                                />
                              )}
                              isOptionEqualToValue={(option, value) => option.id === value?.id}
                            
                            />
                          </Grid2>
                        )}
                        {selectedProcedures.length > 0 ? (
                          <Box>
                            {selectedProcedures.map((procedure, index) => (
                              <Box
                                key={index}
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                p={2}
                                borderRadius="8px"
                                border="1px solid grey"
                                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                bgcolor="#fff"
                                m={2}
                              >
                                <Grid2 container spacing={2}>
                                  <Grid2  size={10} display="flex" justifyContent="start">
                                    <p>
                                      {procedure.name} ({procedure.code})
                                    </p>
                                  </Grid2>
                                  <Grid2  size={2} display="flex" justifyContent="end">
                                    <Button onClick={() => handleRemoveService(index)}>
                                      <img
                                        src={closeCircleRed}
                                        alt="Close Icon"
                                        style={{ width: '20px', height: '20px' }}
                                      />
                                    </Button>
                                  </Grid2>
                                  <Grid2 size={12}>
                                    <TextField
                                      label="Remark"
                                      type="textarea"
                                      multiline
                                      fullWidth
                                      rows={1}
                                      //   value={service.remark || ''}
                                      name={`remarks_${index}`}
                                      variant="outlined"
                                      onChange={(e) => handleRemarkChange(index, e.target.value)}
                                    />
                                  </Grid2>
                                </Grid2>
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          <Typography style={{ fontSize: '14px', color: '#888' }}>
                    No procedures selected.
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <div className="d-flex justify-content-end">
                          <button
                            onClick={handleOpenModal}
                            style={{
                              padding: '5px 10px',
                              border: 'none',
                              borderRadius: '7px',
                              fontSize: 'larger',
                              fontWeight: '700',
                              marginTop: '15px',
                              backgroundColor: '#505050',
                              color: '#FFF',
                            }}
                            disabled={selectedProcedures.length === 0}
                          >
        Proceed
                          </button>
                        </div>
                      </Box>
                    </Paper>
                  )}
                </div>
              </Grid2>
        
            </Grid2>
  
  
  
            <Grid2 container spacing={2} my={1} mx={3}>
              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '26.1px',
                  textAlign: 'left',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                }} 
              >
                  Cycles Related Procedure
              </p>
            </Grid2>
  
            <Grid2 
              container 
              spacing={2} 
              m={2}
            >
              {CycleRelatedProcedures.map((cycle, index) => {
                const delay = `${index * 0.06}s`; // Staggered delay for each card

                return (
                  <Grid2
                    key={index}
                    size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2.4 }}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Paper
                      elevation={3}
                      className={`${styles.card} fadein`} // Add the fadein class for animation
                      style={{ animationDelay: delay }} // Dynamically apply animation delay
                      sx={{
                        padding: 2,
                        textAlign: 'center',
                        borderRadius: 2,
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleCycleClick(cycle)}
                    >
                      <Grid2 container sx={{ flexGrow: 1 }}>
                        <Grid2 size={2}>
                          <img src={PriscriptionSVG} alt="Cycle" />
                        </Grid2>
                        <Grid2 size={10}>
                          <Box pl={1}>
                            <p
                              style={{
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontWeight: 700,
                                lineHeight: '24.55px',
                                textAlign: 'left',
                                textUnderlinePosition: 'from-font',
                                textDecorationSkipInk: 'none',
                              }}
                            >
                              {cycle.name}
                            </p>
                          </Box>
                          <Box pl={1} pt={1}>
                            <p
                              style={{
                                fontFamily: 'Montserrat',
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '14.63px',
                                textAlign: 'left',
                                textUnderlinePosition: 'from-font',
                                textDecorationSkipInk: 'none',
                              }}
                            >
                              {cycle.description}
                            </p>
                          </Box>
                        </Grid2>
                      </Grid2>
                    </Paper>
                  </Grid2>
                );
              })}

            </Grid2>
  
  
            <Grid2 container spacing={2} my={1} mt={3} mx={3} display={'flex'} justifyContent={'space-between'}>
              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '26.1px',
                  textAlign: 'left',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                }} 
              >
                   Non Cycles Related Procedure
              </p>
              <p
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '20.3px',
                  textAlign: 'left',
                  textDecorationLine: 'underline',
                  textDecorationStyle: 'solid',
                  textUnderlinePosition: 'from-font',
                  textDecorationSkipInk: 'none',
                  color:'#5A8AEA',
                  cursor:'pointer'
                }}

                onClick={() => setShowAll(!showAll)} 
              >
                {showAll ? 'Show Less' : 'View All'}
              </p>
            </Grid2>
  
            <Grid2 
              container 
              spacing={2} 
              m={2}
            >
              {displayedProcedures.map((cycle, index) => {
                const isOrderAdded = selectedProcedures.some((procedure) => procedure.id === cycle.id);
                const delay = `${index * 0.06}s`; 
                const isHovered = hoveredButton === cycle.id;
    
                return(
                  <Grid2
                    key={index}
                    size={{ xs: 12,sm:6, md:4, lg: 3, xl:2.4 }}
                    sx={{ display: 'flex', flexDirection: 'column' }} 
                  >
                    <Paper
                      elevation={3}
                      style={{ animationDelay: delay }}
                      className={`${styles.card} fadein`} 
                      sx={{
                        padding: 2,
                        textAlign: 'center',
                        borderRadius: 2,
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        display: 'flex', 
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <Grid2 container sx={{ flexGrow: 1 }}>
                        <Grid2 size={2}>
                          <img src={PriscriptionSVG} alt="Cycle" />
                        </Grid2>
                        <Grid2 size={10}>
                          <Box pl={1}>
                            <p
                              style={{
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontWeight: 700,
                                lineHeight: '24.55px',
                                textAlign: 'left',
                                textUnderlinePosition: 'from-font',
                                textDecorationSkipInk: 'none',
                              }}
                            >
                              ({cycle.code}) {cycle.name}
                            </p>
                          </Box>
                        </Grid2>
                        {/* Full-width HR */}
                        <Grid2 size={12}>
                          <hr style={{ width: '100%' }} />
                        </Grid2>
                        {/* Full-width Add Button */}
                        <Grid2 size={12} display="flex" justifyContent="flex-end">
                          <button
                            onClick={() => handleAddProcedure(cycle)}
                            onMouseEnter={() => setHoveredButton(cycle.id)}
                            onMouseLeave={() => setHoveredButton(null)}
                            style={{
                              padding: '5px 10px',
                              border: 'none',
                              borderRadius: '7px',
                              fontSize: '16px',
                              fontWeight: '700',
                              backgroundColor:isOrderAdded
                                ? '#DEEFE1'
                                : '#E0E0E0',
                              color: isOrderAdded ? '#47B35F' : '#232323',
                              cursor: isOrderAdded ? 'not-allowed' : 'pointer',
                              width: 'auto',
                            }}
                            disabled={isOrderAdded}
                          >
                            {!isOrderAdded && (
                              <img
                                src={addCircle}
                                alt="Add Icon"
                                style={{ width: '22px', height: '22px' }}
                              />
                            )}
                            {isOrderAdded ? 'Added' : ' Add'}
                          </button>
                        </Grid2>
                      </Grid2>
  
                    </Paper>
                  </Grid2>
                );
              }
             
              
              )}
            </Grid2>
         
          </div>
        )}
      </>

      <ConfirmationModal
        open={openModal}
        handleClose={handleCloseModal}
        onConfirm={handleSave}
        type={'save'}
        title='Place Order'
        contentMessage='Are you sure you want to prescribe selected procedure?'
      />
     
    </ThemeProvider>

   
  );
};
  
export default CycleList;