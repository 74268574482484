/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import OocyteSelector from './occyteSelector';
import DetailsTable from './detailsTable';
import { actionsItems, cellItems, cultureDishConfigItems, EmbryoDetail, EmbryoOocyteFilledStatus, fertilizationItems, FreezeDialogValues, gradeItems, incubatorItems, initialValuesCryo, multiNucleiItems, pgtFacilityItems, pgtOrderBiopsyCellsItems, pgtorderDetailsItems, pgtResultEmbryoSexItems, pgtResultMoistureItems, pgtResultsItems, pgtTypeItems, source_list, symmetryItems, UpdateEmbryo } from './embryoCulture.helpers';
import { Autocomplete, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid2, TextField, ThemeProvider } from '@mui/material';
import styles from './embryoCulture.module.css';
import { theme } from '../../../components/Medical_History_Forms/themeprovider';
import { Form } from 'react-router-dom';
import { Formik, FormikErrors, FormikHelpers, getIn } from 'formik';
import { convertUnderscoreToTitle, RequiredLabel } from '../../../utils/commonUtils';
import { Clinician, Role, ROLES } from '../Occyte-Grading/oocyteGrading.helper';
import { useClinic } from '../../../components/ClinicProvider';
import { useAlert } from '../../../components/alertProvider';
import { useLoader } from '../../../components/loaderProvider/loader';
import { getUsersByClinicId } from '../../../services/apiService';
import ImageUploadComponent from '../../../components/Surgery/laparoscopy/imageUploadLaparoscopy';
import { ConfirmationModal } from '../../../components/confirmationModal';
import { CombinedValidationSchema, EmbryoCultureType, EmbryoFinilizeUpdateType, EmbryoUpdateDetailsType,columns, getEmbryoCultureDetails, initialEmbryoCultureValues, initialEmbryoUpdateDetailsValues, updateEmbryoCulture, updateEmbryoSummary } from './dayThirdHelper';
import { AxiosError } from 'axios';
import GradingUnavailable from '../Occyte-Grading/oocyteGradingUnavailable';
import FreezeActionDialog from './freezeActionDialog';
import EmbryoCultureViewMode from './ViewMode';
import OverlayIconPanel from './overlay';
import EmbryoFileUpload from './EmbryoFileUpload';
interface cycleProp {
    initialCycleId: number;
  }
  
const DayThird:React.FC<cycleProp> = ({ initialCycleId }) => {
  
  const { ClinicId } = useClinic();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [file, setFile] = useState<File | null>(null); 
  const [imagePathUrl, setImagePathUrl] = useState<string | null>(null);
  const [selectedEmbryos, setSelectedEmbryos] = useState<number[]>([]);
  const [allEmbryos, setAllEmbryos] = useState<EmbryoDetail[]>([]);
  const [selectedSource, setselectedSource] = useState<string>('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rows, setRows] = useState<any[]>([]);
  const [actionStatus, setActionStatus] = useState<'DRAFT' | 'FINALIZED'>('DRAFT');
  const [embryoCulture, setEmbryoCulture] = useState<EmbryoCultureType>(initialEmbryoCultureValues);
  const [embryoDetails, setEmbryoDetails] = useState<EmbryoUpdateDetailsType>(initialEmbryoUpdateDetailsValues);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isFreezeDialogOpen, setFreezeDialogOpen] = useState(false);
  const [hitSubmitbtn, setHitSubmitbtn] = useState(false);
  const [embryosFound, setEmbryosFound] = useState<boolean>(true); 
  
  const [areAllOocytesFilled, setAreAllOocytesFilled] = useState(false);
  const [isFinalizeConfirmDialogOpen, setFinalizeConfirmDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [isPGTResultDialogOpen, isPGTResultDialogOpenDialogOpen] = useState(false);

  const handlePGTResultDialogClose = () => {
    isPGTResultDialogOpenDialogOpen(false);
  };
  // Initialize state for clinician lists
  const [clinicianLists, setClinicianLists] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    ANESTHETIST: [],
    EMBRYOLOGIST: [],
    NURSE: [],
  });
  
  
  const [isTableDeletable, setIsTableDeletable] = useState(true);
  const [freezeValues, setFreezeValues] = useState<FreezeDialogValues>(initialValuesCryo);
  
  
  const [selectedCycleId, setSelectedCycleId] = useState<number>(initialCycleId);
  const [sourceData, setSourceData] = useState<source_list[]>([]);
  const [selectedSourceId, setSelectedSourceId] = useState<number | null>(null);
  const [selectedThawId, setSelectedThawId] = useState<number | null>(null);
  const [allSourceEmbryos, setAllSourceEmbryos] = useState<UpdateEmbryo[]>([]);

  const fetchEmbryoDetails = useCallback(
    (cycle_id: number,  sourceID?: number, thawId?:number | null) => {
      showLoader();
      if (cycle_id) {
        getEmbryoCultureDetails(cycle_id, sourceID, thawId) 
          .then((data) => {

            if (data.embryo && data.embryo.length === 0) {

              setEmbryosFound(false); 
            } else {
              setEmbryosFound(true); 
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const embryoDetails = data.embryo.map((detail: any) => ({
              embryo_number: detail.embryo_number,
              isFilled: detail.action !== null,
            }))
              .sort((a: { embryo_number: number; }, b: { embryo_number: number; }) => a.embryo_number - b.embryo_number);
            setAllEmbryos(embryoDetails);
            
            const opuDate = data?.details[0].opu_date;
            // Adjust date by adding selectedDay to opu_date
            const adjustedDate = opuDate
              ? new Date(new Date(opuDate).getTime() + 3 * 24 * 60 * 60 * 1000)
                .toISOString()
                .split('T')[0]
              : new Date().toISOString().split('T')[0];

            setEmbryoCulture((prevEmbryoCulture) => ({
              ...prevEmbryoCulture,  
              ...data?.details[0], 
              opu_date: adjustedDate       
            }));
 
        

          })
          .catch((error) => {
            console.error('Error fetching Embryo Details:', error);

            // If error occurs, reset to initial/default values
            // setEmbryoCulture({});
            // setEmbryoDetails({});
          })
          .finally(() => {
            hideLoader();
          });
      }
    },
    [showLoader, hideLoader]
  );


  
  const fetchSourceList = useCallback(()=>{
    getEmbryoCultureDetails(selectedCycleId)
      .then((data) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        processEmbryoData(data,selectedSourceId, selectedThawId );
        // Check if source_list exists before updating state
        if (data && Array.isArray(data.source_list)) {
          setSourceData(data.source_list);
          // Set the first object's source as the selectedSourceId if sourceData is not empty
          if (data.source_list.length > 0) {
    
            const firstSourceId = data.source_list[0].source;
            const FirstThawId = data.source_list[0].thaw_cycle;
            setselectedSource(data.source_list[0].name);
            setSelectedSourceId(firstSourceId);
            setSelectedThawId(FirstThawId);
            // Call fetchEmbryoDetails with the first source ID
            fetchEmbryoDetails(selectedCycleId, firstSourceId, FirstThawId);
          }
          setAllSourceEmbryos(data.embryo);
    
          const finalizedItem = data.embryo?.every((item: EmbryoUpdateDetailsType) => item.status === 'FINALIZED');            
          setIsTableDeletable(!finalizedItem);
    
          const embryoDetails = data.embryo
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((detail: any) => ({
              embryo_number: detail.embryo_number,
              isFilled: detail.action !== null,
            }))
            .sort((a: { embryo_number: number; }, b: { embryo_number: number; }) => a.embryo_number - b.embryo_number);
                            
          if (embryoDetails.length >= 1) {
            const isAllFilled = embryoDetails.every((embryo: EmbryoOocyteFilledStatus) => embryo.isFilled);
                        
            if (!isAllFilled) {
              setAreAllOocytesFilled(false);
            } else {
              setAreAllOocytesFilled(true);
            }
          } else {
            setAreAllOocytesFilled(false);
          }            
        }
      })
      .catch((error) => {
        console.error('Error fetching source list:', error);
      });
    // eslint-disable-next-line
  },[]);
  
  useEffect(() => {
    fetchSourceList();
    // eslint-disable-next-line
  }, [selectedCycleId]);
    
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processEmbryoData = (data:any, selectedSourceId:number |null |undefined, selectedThawId:number |null |undefined) => {

    // if (data.details && Array.isArray(data.details)&& !selectedSourceId && !selectedThawId) {
    //   const mappedRows = data.embryo
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     .filter((item: any) => item.action !== null).sort((a: any, b: any) => a.embryo_number - b.embryo_number).map((item: any) => {
    //       const freezeContent = item.action === 'FREEZE'
    //         ? {
    //           title: 'Action Information',
    //           details: [
    //             { label: 'Cryo Preserved date', value: item.cryo_preserved_date || '-' },
    //             { label: 'Cryo Preserved By', value: item.cryo_preserved_by_name || '-' },
    //             { label: 'Cryo Purpose', value: convertUnderscoreToTitle(item.cryo_purpose) || '-' },
    //             { label: 'Media', value: convertUnderscoreToTitle(item.cryo_media) || '-' },
    //             { label: 'Screening Status', value: item.is_screened  ?'Yes':'No'},
    //           ],
    //         }
    //         : null;
          
    //       console.log('itemitemitem',item);
            
    //       return{
            
    //         id: item.id,
    //         image:item.files_url,
    //         embryoNo: item.embryo_no,
    //         pn: item?.pn || '-',
    //         pb: item?.pb || '-',
    //         cleavage: convertUnderscoreToTitle(item?.cleavage) || '-',
    //         action: item.action === 'FREEZE' && freezeContent ?( 
    //           <div style={{ display: 'flex', alignItems: 'center' }}>
    //             <OverlayIconPanel content={freezeContent} />
    //             <span style={{ marginLeft: '5px' }}>{convertUnderscoreToTitle(item.action)}</span>
    //           </div>): convertUnderscoreToTitle(item.action),
    //         freezeLocation: '-',
    //       };
    //     });

    //   setTableData(mappedRows);
    // }
    if (data.details && Array.isArray(data.details) && !selectedSourceId && !selectedThawId) {
      console.log('data.embryodata.embryodata.embryo', data.embryo);
      
      const mappedRows = data.embryo
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((item: any) => item.action !== null).sort((a: any, b: any) => a.embryo_number - b.embryo_number).map((item: any) => {
          const pgtOrderContent = item.pgt_order && 
Object.values(item.pgt_order).some(value => value !== null && value !== undefined)
            ? {
              title: 'PGT Order Details',
              details: [
                { label: 'Date', value: item.pgt_order.pgt_order_date || '-' },
                {
                  label: 'Time',
                  value: item.pgt_order.pgt_order_time
                    ? new Date(`1970-01-01T${item.pgt_order.pgt_order_time}`).toLocaleString('en-US', {
                      hour: 'numeric',
                      minute: '2-digit',
                      hour12: true,
                    })
                    : '-',
                },
                { label: '# Cell Biopsy', value: convertUnderscoreToTitle(item.pgt_order.pgt_order_biopsy_cells) || '-' },
                { label: 'Facility', value: convertUnderscoreToTitle(item.pgt_order.pgt_facility) || '-' },
                { label: 'PGT Type', value: convertUnderscoreToTitle(item.pgt_order.pgt_type) || '-' },
              ],
            }
            : null;


          const freezeContent = item.action === 'FREEZE'
            ? {
              title: 'Action Information',
              details: [
                { label: 'Cryo Preserved date', value: item.cryo_preserved_date || '-' },
                { label: 'Cryo Preserved By', value: item.cryo_preserved_by_name || '-' },
                { label: 'Cryo Purpose', value: convertUnderscoreToTitle(item.cryo_purpose) || '-' },
                { label: 'Media', value: convertUnderscoreToTitle(item.cryo_media) || '-' },
                { label: 'Screening Status', value: item.is_screened  ?'Yes':'No'},
              ],
            }
            : null;

          const pgtResultContent = item.pgt_results &&
            Object.entries(item.pgt_results).some(([key, value]) => 
              value !== null && 
              value !== undefined && 
              (key !== 'pgt_result_notes' || value !== '')
            )
            ?  {
              title: 'PGT Result',
              details: [
                { label: 'Embryo Sex', value: convertUnderscoreToTitle(item.pgt_results.pgt_result_embryo_sex) || '-' },
                { label: 'PGT Result', value: convertUnderscoreToTitle(item.pgt_results.pgt_results) || '-' },
                { label: 'Mitosure', value: convertUnderscoreToTitle(item.pgt_results.pgt_result_moisture) || '-' },
                { label: 'Notes', value: convertUnderscoreToTitle(item.pgt_results.pgt_result_notes) || '-' },
              ],
            }
            : null;
          
    
          console.log(pgtOrderContent,pgtResultContent);
          
          return {
            id: item.id,
            embryoNo: item.embryo_no,
            image:item.files_url,
            spermSource: item.sperm_source || '-',
            inseminationTechnique: item.insemination_technique || '-',
            action: item.action === 'FREEZE' && freezeContent ?( 
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <OverlayIconPanel content={freezeContent} />
                <span style={{ marginLeft: '5px' }}>{convertUnderscoreToTitle(item.action)}</span>
              </div>): convertUnderscoreToTitle(item.action),
            freezeLocation: item.location || '-',
            grade: item.grade || '-',
            symmetry: convertUnderscoreToTitle(item.symmetry) || '-',
            no_of_Cells: item.number_of_cells || '-',
            multinuclei: convertUnderscoreToTitle(item.multi_nuclei) || '-',
            pgtOrderDetails: pgtOrderContent ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <OverlayIconPanel content={pgtOrderContent} />
                <span style={{ marginLeft: '5px' }}>Yes</span>
              </div>
            ) : (
              'No'
            ),

            pgtResult: pgtResultContent ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <OverlayIconPanel content={pgtResultContent} />
                <span style={{ marginLeft: '5px' }}>Yes</span>
              </div>
            ) : (
              'No'
            )
          };
        });
    
      setRows(mappedRows);
    }
  };

  // Handle dropdown change using SelectChangeEvent type
  const handleSourceChange = (event: string) => {
    const selectedSOurceName = event;
    const selectedSourceItem = sourceData.find(item => item.name === selectedSOurceName) || null;
    // Log the selected source item
    console.log('Selected Source Item:', selectedSourceItem);
    setselectedSource(selectedSOurceName); // Update selectedSourceId as well
      
    // Use a default value if cycle is undefined
    const cycle_ID = selectedSourceItem?.cycle ?? 0; 
    setSelectedCycleId(cycle_ID);
      
    setSelectedSourceId(selectedSourceItem?.source ?? 0);
    setSelectedThawId(selectedSourceItem?.thaw_cycle ?? null);
      
    if (selectedSourceItem) {
      fetchEmbryoDetails(cycle_ID, selectedSourceItem.source, selectedSourceItem.thaw_cycle); 
    } 
  };
        
  const handleSelectionChange = (newSelectedOocytes: number[], newDropdownValue: string) => {    
    setSelectedEmbryos(newSelectedOocytes);
    if (selectedSource && selectedSource !== newDropdownValue) {      
      setselectedSource(newDropdownValue);
      handleSourceChange(newDropdownValue);
    };
  };

  // useEffect(() => {
  //   fetchEmbryoDetails(selectedCycleId);
  // }, [fetchEmbryoDetails, selectedCycleId]);  
  
  // Wrap fetchCliniciansByRole in useCallback
  const fetchCliniciansByRole = useCallback((role: Role) => {
    showLoader();
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {
        setClinicianLists(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {
         
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]); // Add dependencies here
  
  // Fetch clinicians for each role on component mount or when ClinicId changes
  useEffect(() => {
    ROLES.forEach(fetchCliniciansByRole);
  }, [fetchCliniciansByRole]); // Use the memoize

  useEffect(()=>{ 
    console.log(selectedEmbryos);
    console.log(selectedSource);
  },[selectedEmbryos, selectedSource]);


  const sanitizeEmbryoCulture = (values: EmbryoCultureType): EmbryoCultureType => {
    const sanitizedValues = { ...values };
    if (sanitizedValues.time && sanitizedValues.time.trim().length === 5) {
      sanitizedValues.time = `${sanitizedValues.time}:00`;
    }
  
    sanitizedValues.day = 3; 
    sanitizedValues.cycle = selectedCycleId; 
    return sanitizedValues;
  };
  
  const sanitizeEmbryoDetails = (values: EmbryoUpdateDetailsType): EmbryoUpdateDetailsType => {
    const sanitizedValues = { ...values };
    sanitizedValues.cycle = selectedCycleId; 
    sanitizedValues.status = actionStatus; 
      
    sanitizedValues.cryo_preserved_date = freezeValues.cryo_preserved_date || sanitizedValues.cryo_preserved_date;
    sanitizedValues.cryo_preserved_by = freezeValues.cryo_preserved_by || sanitizedValues.cryo_preserved_by;
    sanitizedValues.cryo_purpose = freezeValues.cryo_purpose || sanitizedValues.cryo_purpose;
    sanitizedValues.cryo_media = freezeValues.cryo_media || sanitizedValues.cryo_media;
    sanitizedValues.is_screened = freezeValues.is_screened || sanitizedValues.is_screened;
    
    sanitizedValues.source = selectedSourceId;
    sanitizedValues.thaw_cycle = selectedThawId; 
  
    return sanitizedValues;
  };

  const handleSubmit = async (
    values: { embryoCulture: EmbryoCultureType; embryoDetails: EmbryoUpdateDetailsType },
    { setSubmitting, setErrors, resetForm }: FormikHelpers<{ embryoCulture: EmbryoCultureType; embryoDetails: EmbryoUpdateDetailsType }>
  ) => {
    showLoader();
     
    const sanitizedEmbryoCulture = sanitizeEmbryoCulture(values.embryoCulture);
    const sanitizedEmbryoDetails = sanitizeEmbryoDetails(values.embryoDetails); 
     
    Promise.all([
      updateEmbryoCulture(values.embryoCulture.id ?? 0, sanitizedEmbryoCulture),
      updateEmbryoSummary(selectedCycleId, sanitizedEmbryoDetails),
    ])
      .then(([updatedEmbryoCulture, updatedEmbryoDetails]) => {
        console.log('EmbryoCulture updated successfully:', updatedEmbryoCulture);
        console.log('EmbryoSummary updated successfully:', updatedEmbryoDetails);
     
        addAlert('Embryo details saved successfully', 'success');
     
        // Reset form with sanitized values
        resetForm({
          values: {
            embryoCulture: sanitizedEmbryoCulture,
            embryoDetails: sanitizedEmbryoDetails,
          },
        });
     
        fetchEmbryoDetails(selectedCycleId,  selectedSourceId ?? undefined, selectedThawId); 
        fetchSourceList(); 

      })
      .catch((error: unknown) => {
        // Handle errors
        if (error instanceof AxiosError) {
          if (error.response?.data?.type === 'validation_error') {
            const apiErrors = error.response.data.errors;
     
            const formikErrors: FormikErrors<{
                 embryoCulture: EmbryoCultureType;
                 embryoDetails: EmbryoUpdateDetailsType;
               }> = {
                 embryoCulture: {} as FormikErrors<EmbryoCultureType>,
                 embryoDetails: {} as FormikErrors<EmbryoUpdateDetailsType>,
               };
     
            apiErrors.forEach((err: { attr: string; detail: string }) => {
              if (err.attr.startsWith('embryoCulture')) {
                const fieldKey = err.attr.replace('embryoCulture.', '');
                   formikErrors.embryoCulture![fieldKey as keyof EmbryoCultureType] = err.detail;
              } else if (err.attr.startsWith('embryoDetails')) {
                const fieldKey = err.attr.replace('embryoDetails.', '');
                   formikErrors.embryoDetails![fieldKey as keyof EmbryoUpdateDetailsType] = err.detail;
              }
            });
     
            setErrors(formikErrors);
          } else {
            console.error('Unexpected error occurred:', error);
          }
        } else {
          console.error('Error occurred while saving embryo details:', error);
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
      });
  };
  
  
  const handleDelete = (id: number | string) => {
    console.log(`Deleting row with ID: ${id}`);  // console will remove after demo
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    // fetchEmbryoDetails(selectedCycleId); 
    fetchSourceList();

  };


  const handleFreezeDialogSave = (formValues: FreezeDialogValues) => {
    console.log('Received Values from Freeze Dialog:', formValues);
    setFreezeValues(formValues); 
  
    setFreezeDialogOpen(false);
  };
  
  const saveFinalize = () => {
    const finalizePromises = sourceData.map((source) => {
      // Filter embryos that match the current source
      const filteredEmbryos = allSourceEmbryos
        .filter((embryo) => embryo.source === source.source && embryo.thaw_cycle === source.thaw_cycle)
        .map((embryo) => embryo.embryo_number);

      console.log(filteredEmbryos);
      const payload: EmbryoFinilizeUpdateType = {
        embryos: filteredEmbryos,  
        cycle: selectedCycleId as number,
        day: 3, 
        status: 'FINALIZED',
        source:source.source,
        thaw_cycle:source.thaw_cycle
      };
      return updateEmbryoSummary(selectedCycleId, payload)
        .then(() => {
          addAlert(`Embryo details for source ${source.name} saved successfully`, 'success');
        })
        .catch((error) => {
          console.error(`Error finalizing embryo summary for source ${source.name}:`, error);
        });
    }); 
    Promise.all(finalizePromises).then(() => {
      fetchSourceList();
    }); 

  };


  if (!isTableDeletable) {
    return (
      <div >
        <EmbryoCultureViewMode data={embryoCulture} tableData={rows} isFinalized ={isTableDeletable} tablecolumns= {columns} day={3}/>
      </div>
    );
  };

  return (    
    <ThemeProvider theme={theme}>
      {!embryosFound ? (
      // If no embryos are found, show 
        <GradingUnavailable  message="No embryos available for the selected day." />
      ) : (
        <Formik
          initialValues={{ embryoCulture, embryoDetails }} 
          validationSchema={CombinedValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            setFieldValue,
            submitForm,
            validateForm,
            setFieldTouched,
            errors,
            touched,
            handleBlur,
            handleChange,
          }) => {
            console.log(values);

            
            return (
              <Form onSubmit={submitForm}>
                <Grid2 container spacing={2} marginTop={2}>
                  <Grid2 size={{xs:12, sm:3, md:3}}>
                    <TextField
                      name="date"
                      type="date"
                      label={RequiredLabel('Date', true)}
                      value={getIn(values, 'embryoCulture.opu_date') || ''}                       
                      disabled
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      defaultValue={new Date().toISOString().split('T')[0]} 
      
                    />
                  </Grid2>
                  <Grid2 size={{xs:12, sm:3, md:3}}>
                    <TextField
                      name="embryoCulture.time"
                      type="time"
                      label={RequiredLabel('Time', true)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={getIn(values, 'embryoCulture.time') || ''} 
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        !!getIn(touched, 'embryoCulture.time') &&
                            !!getIn(errors, 'embryoCulture.time')
                      }
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="embryologist"
                      options={clinicianLists['EMBRYOLOGIST'] || []}
                      getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                      value={clinicianLists['EMBRYOLOGIST'].find(
                        (doctor) => doctor.id === values.embryoCulture?.embryologist
                      ) || null}
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.embryologist', value?.id || ''); // Update Formik's value for 'embryoCulture.embryologist'
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Embryologist', true)} // Your label function
                          error={
                            !!getIn(touched, 'embryoCulture.embryologist') &&
                                !!getIn(errors, 'embryoCulture.embryologist')
                          }
                        />
                      )}
                    />
                  </Grid2>
                    
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="witness"
                      options={clinicianLists['EMBRYOLOGIST'] || []}
                      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                      value={clinicianLists['EMBRYOLOGIST'].find(
                        (doctor) => doctor.id === values.embryoCulture?.witness
                      ) || null}
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.witness', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Witness', true)}
                          error={
                            !!getIn(touched, 'embryoCulture.witness') &&
                                !!getIn(errors, 'embryoCulture.witness')
                          }
                        />
                      )}
                    />
                  </Grid2>
      
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={incubatorItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        incubatorItems.find(
                          (option) => option.value === getIn(values, 'embryoCulture.incubator')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.incubator', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Incubator', false)}
                          error={
                            !!getIn(touched, 'embryoCulture.incubator') &&
                                !!getIn(errors, 'embryoCulture.incubator')
                          }
                        />
                      )}
                    />
                  </Grid2>

                
                
      
                  <Grid2 size={{ xs: 12, sm: 3, md: 3 }}>
                    <TextField
                      name="remarks"
                      label={RequiredLabel('Remark', false)}
                      fullWidth
                      multiline
                      rows={1}
                      placeholder="Enter remark"
                      value={values.embryoCulture?.remarks || ''}
                      onChange={(e) => {
                        setFieldValue('embryoCulture.remarks', e.target.value || '');
                      }}
                      onBlur={handleBlur}
                      error={
                        !!getIn(touched, 'embryoCulture.remarks') &&
                            !!getIn(errors, 'embryoCulture.remarks')
                      }
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={cultureDishConfigItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        cultureDishConfigItems.find(
                          (option) => option.value === getIn(values, 'embryoCulture.culture_dish_config')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.culture_dish_config', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Culture Dish Configuration', false)}
                          error={
                            !!getIn(touched, 'embryoCulture.culture_dish_config') &&
                            !!getIn(errors, 'embryoCulture.culture_dish_config')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={getIn(values, 'embryoCulture.is_assisted_hatching') || false}
                          onChange={(e) =>
                            setFieldValue('embryoCulture.is_assisted_hatching', e.target.checked)
                          }
                        />
                      }
                      label="Assisted Hatching"
                    />
                  </Grid2>
      
                </Grid2>   
      
      
                <div>
                  <OocyteSelector
                    title="Embryo No."
                    sourceList={sourceData}
                    selectedsource={selectedSource}
                    totalOocytes={allEmbryos}
                    onSelectionChange={handleSelectionChange}
                    hitSubmitbtn={hitSubmitbtn}
                  />
                </div>
                <Grid2 container spacing={2} marginTop={2}>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={gradeItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        gradeItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.grade')
                        ) || null 
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.grade', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Grade', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.grade') &&
                                !!getIn(errors, 'embryoDetails.grade')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={symmetryItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        symmetryItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.symmetry')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.symmetry', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Symmetry', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.symmetry') &&
                                !!getIn(errors, 'embryoDetails.symmetry')
                          }
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={cellItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        cellItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.number_of_cells')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.number_of_cells', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('#Cells', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.number_of_cells') &&
                                          !!getIn(errors, 'embryoDetails.number_of_cells')
                          }
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={multiNucleiItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        multiNucleiItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.multi_nuclei')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.multi_nuclei', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Multinuclei', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.multi_nuclei') &&
                                !!getIn(errors, 'embryoDetails.multi_nuclei')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={pgtorderDetailsItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        pgtorderDetailsItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.pgt_order_details')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.pgt_order_details', value?.value || '');
                        if (value?.value === 'YES') {
                          setDialogOpen(true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('PGT Order Details', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.pgt_order_details') &&
                                !!getIn(errors, 'embryoDetails.pgt_order_details')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth 
                    sx={{
                      '& .MuiDialog-paper': {
                        borderRadius: '20px',
                      },
                 
                    }}>
                    <DialogTitle>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px', fontWeight:600, fontFamily:'Montserrat', fontSize:'20px' }}> PGT Order Details</span>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          {selectedEmbryos.map((num) => (
                            <div
                              key={num}
                              style={{
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: '#47B35F',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '12px',
                                fontWeight: 400,
                              }}
                            >
                              {num}
                            </div>
                          ))}
                        </div>
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <Grid2 container spacing={2} marginTop={2}>
                        <Grid2 size={{xs:12, sm:12, md:6}}>
                          <TextField
                            name="embryoDetails.pgt_order_date"
                            label={RequiredLabel('Date', true)}
                            fullWidth
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={values.embryoDetails.pgt_order_date }  // Use the value from Formik state or default to today's date
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!getIn(touched, 'pgt_order_date') && !!getIn(errors, 'pgt_order_date')}  // Check for errors in the Formik state
                            helperText={getIn(touched, 'pgt_order_date') && getIn(errors, 'pgt_order_date')}
                            defaultValue={new Date().toISOString().split('T')[0]}       

                          />
                        </Grid2>

                        <Grid2 size={{xs:12, sm:12, md:6}}>
                          <TextField
                            name="embryoDetails.pgt_order_time"
                            type="time"
                            label={RequiredLabel('Time', true)}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={getIn(values, 'embryoDetails.pgt_order_time') || ''} 
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!getIn(touched, 'embryoDetails.pgt_order_time') &&
                            !!getIn(errors, 'embryoDetails.pgt_order_time')
                            }
                          />
                        </Grid2>

                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtOrderBiopsyCellsItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtOrderBiopsyCellsItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_order_biopsy_cells')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_order_biopsy_cells', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('# Cell Biopsy', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_order_biopsy_cells') &&
                                !!getIn(errors, 'embryoDetails.pgt_order_biopsy_cells')
                                }
                              />
                            )}
                          />
                        </Grid2>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtTypeItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtTypeItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_type')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_type', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('PGT Type', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_type') &&
                                !!getIn(errors, 'embryoDetails.pgt_type')
                                }
                              />
                            )}
                          />
                        </Grid2>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtFacilityItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtFacilityItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_facility')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_facility', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('Facility', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_facility') &&
                                !!getIn(errors, 'embryoDetails.pgt_facility')
                                }
                              />
                            )}
                          />
                        </Grid2>
                      </Grid2>
                    </DialogContent>
                    <DialogActions>
                      <button onClick={handleDialogClose} className={styles.resetButton}>
                Close
                      </button>
                      <button onClick={handleDialogClose} className={`${styles.customButton}`}>
                Save
                      </button>
                    </DialogActions>
                  </Dialog>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={pgtorderDetailsItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        pgtorderDetailsItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.pgt_result')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.pgt_result', value?.value || '');
                        if (value?.value === 'YES') {
                          isPGTResultDialogOpenDialogOpen(true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('PGT Result', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.pgt_result') &&
                                !!getIn(errors, 'embryoDetails.pgt_result')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  <Dialog open={isPGTResultDialogOpen} onClose={handlePGTResultDialogClose} maxWidth="md" fullWidth 
                    sx={{
                      '& .MuiDialog-paper': {
                        borderRadius: '20px',
                      },
                 
                    }}>
                    <DialogTitle>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px', fontWeight:600, fontFamily:'Montserrat', fontSize:'20px' }}> PGT Result</span>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          {selectedEmbryos.map((num) => (
                            <div
                              key={num}
                              style={{
                                width: '24px',
                                height: '24px',
                                borderRadius: '50%',
                                backgroundColor: '#47B35F',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '12px',
                                fontWeight: 400,
                              }}
                            >
                              {num}
                            </div>
                          ))}
                        </div>
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <Grid2 container spacing={2} marginTop={2}>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtResultEmbryoSexItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtResultEmbryoSexItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_result_embryo_sex')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_result_embryo_sex', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('Embryo Sex', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_result_embryo_sex') &&
                                !!getIn(errors, 'embryoDetails.pgt_result_embryo_sex')
                                }
                              />
                            )}
                          />
                        </Grid2>

                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtResultsItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtResultsItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_results')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_results', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('Result', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_results') &&
                                !!getIn(errors, 'embryoDetails.pgt_results')
                                }
                              />
                            )}
                          />
                        </Grid2>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <Autocomplete
                            options={pgtResultMoistureItems}
                            getOptionLabel={(option) => option.label}
                            value={
                              pgtResultMoistureItems.find(
                                (option) => option.value === getIn(values, 'embryoDetails.pgt_result_moisture')
                              ) || null
                            }
                            onChange={(_, value) => {
                              setFieldValue('embryoDetails.pgt_result_moisture', value?.value || '');
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={RequiredLabel('Mitosure', false)}
                                error={
                                  !!getIn(touched, 'embryoDetails.pgt_result_moisture') &&
                                !!getIn(errors, 'embryoDetails.pgt_result_moisture')
                                }
                              />
                            )}
                          />
                        </Grid2>
                        <Grid2 size={{ xs:12, sm:12, md:6}} >
                          <TextField
                            name="embryoDetails.pgt_result_notes"
                            label={RequiredLabel('Notes', false)}
                            fullWidth
                            multiline
                            rows={1}
                            value={values.embryoDetails?.pgt_result_notes || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!getIn(touched, 'embryoDetails.pgt_result_notes') &&
                            !!getIn(errors, 'embryoDetails.pgt_result_notes')
                            }
                          />
                        </Grid2>
                      </Grid2>
                    </DialogContent>
                    <DialogActions>
                      <button onClick={handlePGTResultDialogClose} className={styles.resetButton}>
                Close
                      </button>
                      <button onClick={handlePGTResultDialogClose} className={`${styles.customButton}`}>
                Save
                      </button>
                    </DialogActions>
                  </Dialog>

                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={actionsItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        actionsItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.action')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.action', value?.value || '');
                        if (value?.value === 'FREEZE') {
                          setFreezeDialogOpen(true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Action', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.action') &&
                                          !!getIn(errors, 'embryoDetails.action')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                    <EmbryoFileUpload selectedEmbryos={selectedEmbryos} onFileUpload={setFile} imagePathUrl={imagePathUrl} cycle_id={selectedCycleId || null} day={3}/>
                  </Grid2>
                          
                  {/* Freeze Action Dialog */}
                  <FreezeActionDialog
                    open={isFreezeDialogOpen}
                    onClose={() => {
                      setFieldValue('embryoDetails.action', ''); 
                      setFreezeDialogOpen(false);  
                    }}
                    values={freezeValues}
                    onSave={handleFreezeDialogSave}                    
                    selectedEmbryos={selectedEmbryos} 
                  />
                  
                  <Grid2 size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                    <ImageUploadComponent onFileUpload={setFile}       imagePathUrl={imagePathUrl} />
                  </Grid2>
      
                </Grid2>
      
      
                <div >
                  <p style={{fontFamily:'Montserrat', fontSize:'20px', fontWeight:700, padding:'1rem'}}>Details</p>
                  <DetailsTable columns={columns} rows={rows} isDeletable={isTableDeletable}  onDelete={handleDelete}/>
                </div>
                <div>
                  <Box sx={{display:'flex', justifyContent:'space-between'}}>
                    <Box sx={{display:'flex', justifyContent:'flex-start', marginTop:'1rem'}}>
                      <button
                        type="button"
                        className={styles.resetButton}
                        // onClick={() => setOpenResetConfirmDialog(true)} 
                      >
                      Cancel
                      </button>
                    </Box>
                    <Box className={`${styles.customButtonDiv}`}  > 
                      {!areAllOocytesFilled ? (
                        <button
                          type="button"
                          className={styles.resetButton}
                          style={{width:'10rem',
                            cursor : areAllOocytesFilled ? 'pointer':'not-allowed'
                          }}
                          disabled={!areAllOocytesFilled}
                          onClick={async () => {
                    
                            // Ensure setFieldValue completes before proceeding
                            await setFieldValue('embryoDetails.embryos', selectedEmbryos);
                    
                            setActionStatus('FINALIZED');

                            setHitSubmitbtn(true);
                            console.log('Embryo Details:', embryoDetails);
                    
                            validateForm().then((formErrors) => {
                              // Mark fields with validation errors as touched
                              Object.entries(formErrors).forEach(([namespace, fields]) => {
                                if (fields && typeof fields === 'object') {
                                  Object.keys(fields).forEach((field) => {
                                    setFieldTouched(`${namespace}.${field}`, true, true);
                                  });
                                }
                              });
                    
                              if (Object.keys(formErrors).length === 0) {
                                setOpenConfirmDialog(true);
                              } else {
                                console.log('Validation errors:', formErrors);
                              }
                            });
                          }}
                        >
                      Save & Finalize
                        </button>
                       
                      ) : (
                        <button
                          type="button"
                          style={{ width: '10rem' }}
                          className={styles.resetButton}                          
                          onClick={() => setFinalizeConfirmDialogOpen(true)}
                        >
                          <span style={{ fontWeight: 'bold' }}>
                            {'Save & Finalized'}
                          </span>
                        </button>
                      )}

                      <button
                        type="button"
                        onClick={async () => {
                          console.log('Current Form Values:');
                          console.log('Selected Oocytes:', selectedEmbryos);
                    
                          // Ensure setFieldValue completes before proceeding
                          await setFieldValue('embryoDetails.embryos', selectedEmbryos);
                    
                          setActionStatus('DRAFT');
                          setHitSubmitbtn(true);
                          console.log('Embryo Details:', embryoDetails);
                    
                          validateForm().then((formErrors) => {
                          // Mark fields with validation errors as touched
                            Object.entries(formErrors).forEach(([namespace, fields]) => {
                              if (fields && typeof fields === 'object') {
                                Object.keys(fields).forEach((field) => {
                                  setFieldTouched(`${namespace}.${field}`, true, true);
                                });
                              }
                            });
                    
                            console.log('Validation errors:', formErrors);
                            if (Object.keys(formErrors).length === 0) {
                              setOpenConfirmDialog(true);
                            } else {
                              console.log('Validation errors:2222', formErrors);
                            }
                          });
                        }}
                    
                        className={`${styles.customButton}`}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Save
                        </span>
                      </button>                 
                    </Box>
                  </Box>
                </div>
                  
                <ConfirmationModal
                  open={openConfirmDialog}
                  handleClose={() => setOpenConfirmDialog(false)}
                  onConfirm={() => {
                    setOpenConfirmDialog(false);
                    submitForm(); 
                  }} 
                  type="save"
                  title="Embryo Culture"
                  contentMessage={
                    actionStatus === 'FINALIZED'
                      ? 'Are you sure you want to finalize day3 details?'
                      : 'Are you sure you want to save day3 details?'
                  }
                />

                <ConfirmationModal
                  open={isFinalizeConfirmDialogOpen}
                  handleClose={() => setFinalizeConfirmDialogOpen(false)}
                  onConfirm={() => {
                    setFinalizeConfirmDialogOpen(false);
                    saveFinalize();
                  }} 
                  type="save"
                  title="Embryo Culture"
                  contentMessage={ 'Are you sure you want to finalize day 3 details?'
                  }
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </ThemeProvider>
  );
};

export default DayThird;
