import * as Yup from 'yup';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../services/httpService';
export type FrequencyEnum = '0-0-1'| '0-1-1'| '1-1-1'| '1-0-0'| '1-1-0'| '1-0-1'| '0-1-0'| '1-1-1-1'| '1-1-1-1-1'| '2-2-2'| '2-0-2'| '1/2-0-1/2'| '0-2-2'| '2-2-0'| '1-1-1-1-1-1'| '1-2-1'| '2-1-2'| '0-0-2';
export type InstructionsEnum =  'NOT_SPECIFIC'| 'FASTING'| 'BEFORE_BF'| 'BEFORE_LUNCH'| 'BEFORE_DINNER'| 'BEFORE_FOOD'| 'WITH_FOOD'| 'AFTER_FOOD'| 'AFTER_BF'| 'AFTER_LUNCH'| 'AFTER_DINNER'| 'HALF_HOUR_BEFORE_MEAL'| 'INTRAMUSCULAR_DELTOID'| 'DEEP_INTRAMUSCULAR_GLUTEAL'| 'SUBCUTANEOUS'  | 'INTRAMUSCULAR_THIGH'| 'INTRAVENOUS_SLOWLY'| 'INTRAVENOUS_DILUTED_100CC_NS'| 'INTRAVENOUS_DILUTED'| 'NEW_INSTRUCTION';
export type drugRouteEnum = 'ORAL' | 'IV'| 'IM'| 'SC'| 'NA'| 'TOPICAL'| 'VAGINAL'| 'NASAL'| 'EYE'| 'RECTAL'| 'ORAL/VAGINAL'| 'IM/SC'| 'IV/SC'| 'IM/IV/SC'| 'ENDOTRACHEAL'| 'NASAL/ORAL';
export type BlankEnum = '';
export type NullEnum = 'NULL';

export const FrequencyMenuItems: { value: FrequencyEnum; label: string }[] = [
  { value: '0-0-1', label: '0-0-1' },
  { value: '0-1-1', label: '0-1-1' },
  { value: '1-1-1', label: '1-1-1' },
  { value: '1-0-0', label: '1-0-0' },
  { value: '1-1-0', label: '1-1-0' },
  { value: '1-0-1', label: '1-0-1' },
  { value: '0-1-0', label: '0-1-0' },
  { value: '1-1-1-1', label: '1-1-1-1' },
  { value: '1-1-1-1-1', label: '1-1-1-1-1' },
  { value: '2-2-2', label: '2-2-2' },
  { value: '2-0-2', label: '2-0-2' },
  { value: '1/2-0-1/2', label: '1/2-0-1/2' },
  { value: '0-2-2', label: '0-2-2' },
  { value: '2-2-0', label: '2-2-0' },
  { value: '1-1-1-1-1-1', label: '1-1-1-1-1-1' },
  { value: '1-2-1', label: '1-2-1' },
  { value: '2-1-2', label: '2-1-2' },
  { value: '0-0-2', label: '0-0-2' },
];
  

export const InstructionsMenuItems: { value: InstructionsEnum; label: string }[] = [
  { value: 'NOT_SPECIFIC', label: 'Not specific' },
  { value: 'FASTING', label: 'Fasting' },
  { value: 'BEFORE_BF', label: 'Before B/F' },
  { value: 'BEFORE_LUNCH', label: 'Before Lunch' },
  { value: 'BEFORE_DINNER', label: 'Before Dinner' },
  { value: 'BEFORE_FOOD', label: 'Before Food' },
  { value: 'WITH_FOOD', label: 'With Food' },
  { value: 'AFTER_FOOD', label: 'After Food' },
  { value: 'AFTER_BF', label: 'After B/F' },
  { value: 'AFTER_LUNCH', label: 'After Lunch' },
  { value: 'AFTER_DINNER', label: 'After Dinner' },
  { value: 'HALF_HOUR_BEFORE_MEAL', label: '½ hour before meal' },
  { value: 'INTRAMUSCULAR_DELTOID', label: 'Intramuscular on deltoid muscle' },
  { value: 'DEEP_INTRAMUSCULAR_GLUTEAL', label: 'Deep intramuscular on gluteal muscle' },
  { value: 'SUBCUTANEOUS', label: 'Subcutaneous' },
  { value: 'INTRAMUSCULAR_THIGH', label: 'Intramuscular on anterolateral aspect of thigh' },
  { value: 'INTRAVENOUS_SLOWLY', label: 'Intravenous slowly' },
  { value: 'INTRAVENOUS_DILUTED_100CC_NS', label: 'Intravenous slowly diluted with 100 cc NS' },
  { value: 'INTRAVENOUS_DILUTED', label: 'Intravenous slowly diluted' },
  { value: 'NEW_INSTRUCTION', label: 'New Instruction' },
];

export const DrugRouteMenuItems: { value: drugRouteEnum; label: string }[] = [
  { value: 'ORAL', label: 'Oral' },
  { value: 'IV', label: 'IV' },
  { value: 'IM', label: 'IM' },
  { value: 'SC', label: 'SC' },
  { value: 'NA', label: 'NA' },
  { value: 'TOPICAL', label: 'Topical Application' },
  { value: 'VAGINAL', label: 'Vaginal' },
  { value: 'NASAL', label: 'Nasal' },
  { value: 'EYE', label: 'Eye Drops' },
  { value: 'RECTAL', label: 'Rectal' },
  { value: 'ORAL/VAGINAL', label: 'Oral/Vaginal' },
  { value: 'IM/SC', label: 'IM/SC' },
  { value: 'IV/SC', label: 'IV/SC' },
  { value: 'IM/IV/SC', label: 'IM/IV/SC' },
  { value: 'ENDOTRACHEAL', label: 'Endotracheal' },
  { value: 'NASAL/ORAL', label: 'Nasal/Oral' },
];
  
  
export interface Drug {
    id: number; // Read-only, required
    name: string; // Required
    brand_name?: string; // Optional
    strength?: string; // Optional
    stock_uom_code: string; // Required
    route_code: string; // Required
    molecule:string;
  }

export interface Prescription {
    id: number; // Read-only, required
    drug: Drug; // Nested object
    
    frequency: FrequencyEnum; // Enum
    instructions?: InstructionsEnum | null; // Nullable enum
    no_of_days: number; // Required, integer
    quantity: number; // Required, integer
    notes?: string | null; // Optional, nullable
    user?: number | null; // Optional, nullable
    user_first_name: string; // Required
    patient?: number | null; // Optional, nullable
    drug_route?: string | null; // Optional, nullable
    drug_route_code: string; // Required
    default_drug_route_code: string; // Required
    created_on:string|null
  }

export interface PostPatientDrugs {
    drug?: number |null; // Assuming this refers to a drug ID
    frequency: FrequencyEnum | BlankEnum |null;
    instructions?: InstructionsEnum | null; // Nullable field
    no_of_days?: number |null; // Minimum: 0, Maximum: 2147483647
    quantity: number |null; // Minimum: 0, Maximum: 2147483647
    notes?: string | null; // Nullable field
    user?: number | null; // Nullable field
    patient?: number | null; // Nullable field
    drug_route: string | null;
  }
export interface PatientDrugsCreate extends PostPatientDrugs{
    id?:number | null
    drug_name?: string |null;
    drug_brandName?: string |null;
    drug_strength? : string |null;
    drug_UOM? : string |null;
  }
  
  
export const initialPatientDrugsCreateValues: PatientDrugsCreate = {
  id: null,
  drug: null, // Assuming the drug ID is a required integer
  drug_name: null,
  drug_brandName: null,
  drug_strength: null,
  drug_UOM: null,
  frequency: '', // Defaults to an empty string (required)

  instructions: null, // Nullable field, can start as null
  no_of_days: null, // Default to 0 (minimum value)
  quantity: null, // Default to 0 (minimum value)
  notes: '', // Nullable field, default to an empty string
  user: null, // Nullable field, can start as null
  patient: null, // Nullable field, can start as null
  drug_route: null, // Required string, default empty
};

export interface PaginatedDrugsList {
    meta: {
      limit: number;
      next: string | null;
      offset: number;
      previous: string | null;
      total_count: number;
    };
    objects: Prescription[];
  }

  

export const PatientDrugsCreateValidationSchema = Yup.object().shape({
  drug: Yup.array()
    .of(
      Yup.object().shape({
        drug: Yup.number().required('Drug is required'),
        
        drug_brandName: Yup.string().nullable(),
        drug_name: Yup.string().nullable(),
        drug_strength: Yup.string().nullable(),
        drug_UOM: Yup.string().nullable(),
        frequency: Yup.string()
          .oneOf(
            ['0-0-1', '0-1-1', '1-1-1', '1-0-0', '1-1-0', '1-0-1', '0-1-0', '1-1-1-1', '1-1-1-1-1', '2-2-2', '2-0-2', '1/2-0-1/2', '0-2-2', '2-2-0', '1-1-1-1-1-1', '1-2-1', '2-1-2', '0-0-2'],
            'Invalid frequency'
          )
          .required('Frequency is required'),
        instructions: Yup.string()
          .oneOf(
            [
              'NOT_SPECIFIC',
              'FASTING',
              'BEFORE_BF',
              'BEFORE_LUNCH',
              'BEFORE_DINNER',
              'BEFORE_FOOD',
              'WITH_FOOD',
              'AFTER_FOOD',
              'AFTER_BF',
              'AFTER_LUNCH',
              'AFTER_DINNER',
              'HALF_HOUR_BEFORE_MEAL',
              'INTRAMUSCULAR_DELTOID',
              'DEEP_INTRAMUSCULAR_GLUTEAL',
              'SUBCUTANEOUS',
              'INTRAMUSCULAR_THIGH',
              'INTRAVENOUS_SLOWLY',
              'INTRAVENOUS_DILUTED_100CC_NS',
              'INTRAVENOUS_DILUTED',
              'NEW_INSTRUCTION',
              '',
            ],
            'Invalid instruction'
          )
          .nullable(),
        no_of_days: Yup.number()
          .required('Number of days is required')
          .min(0, 'Number of days cannot be less than 0')
          .max(2147483647, 'Number of days exceeds maximum allowed value'),
        quantity: Yup.number()
          .required('Quantity is required')
          .min(0, 'Quantity cannot be less than 0')
          .max(2147483647, 'Quantity exceeds maximum allowed value'),
        user: Yup.number()
          .required()
          .integer('User must be an integer'),
        notes: Yup.string()
          .nullable()
          .max(500, 'Notes must not exceed 500 characters'),
        patient: Yup.number()
          .required()
          .integer('Patient must be an integer'),
        drug_route: Yup.string()
          .required('Drug route is required')
          .max(255, 'Drug route must not exceed 255 characters'),
      })
    )
    .required('At least one drug is required'),
  
});
 
export function getPutDrugsURL(query:string):string{
  return `/patient-drugs/${query}`;
}

export function getPatientDrugs(query:string):Promise<PaginatedDrugsList>{
  return getRequest(getPutDrugsURL(query));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getdrug(query:string):Promise<any>{
  return getRequest(`/item/${query}`);
}

export function postdrug(data:PostPatientDrugs):Promise<PostPatientDrugs>{
  return postRequest(getPutDrugsURL(''),data);
}
export function putdrug(query:string,data:PostPatientDrugs):Promise<PostPatientDrugs>{
  return putRequest(getPutDrugsURL(query),data);
}
export function deletePatientDrugs(query:string):Promise<PaginatedDrugsList>{
  return deleteRequest(getPutDrugsURL(query));
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRoute():Promise<any>{
  return getRequest('/route/?is_active=true');
}

// Define the type for a route
export interface RouteData {
  id: number;
  code: string;
  description: string;
  is_active: boolean;
}
