/* eslint-disable @typescript-eslint/no-explicit-any */
import { getRequest } from '../../../services/httpService';

export interface EmbryoTransfer {
  id: number;
  cycle: number;
  transfer_method: string;
  date: string;
  start_time: string;
  end_time: string;
  et_dish_id: number;
  embryologist: number;
  witness_embryologist: number;
  nurse: number;
  performed_under_anesthesia: boolean;
  anesthesia: string;
  anesthetist: number;
  endometrium_thickness: string;
  endometrium_pattern: string;
  is_ultrasound: boolean;
  ultrasound_method: string;
  transfer_depth: string;
  difficulty_level: string;
  catheter: string;
  catheter_tube: number;
  catheter_remarks: string;
  no_of_attempts: number;
  distance_from_fundus: string;
  embryo_retained: number;
  is_tenaculum_used: boolean;
  is_stylet_used: boolean;
  is_embryo_glue_used: boolean;
  complications: string;
  notes: string;
  pregnancy_date: string;
  is_cycle_closed: boolean;
  cycle_close_reason: string;
  nurse_name: string;
  embryologist_name: string;
  witness_embryologist_name: string;
  anesthetist_name: string;
  cycle_start_date: string;
  oocyte_fertilized_method: string;
  embryo_cryo_preservation_method: string;
  fresh_embryo_transfer_method: string;
  frozen_embryo_transfer_method: string;
}

export interface EmbryoTransferDetail {
  id: number;
  cycle: number;
  day: number;
  grade: string;
  action: string;
  cleavage: string | null;
  embryo_number: number;
  fragmentation_percentage: number | null;
  insemination_technique: string | null;
  multi_nuclei: string | null;
  number_of_cells: number | null;
  pb: string | null;
  pn: string | null;
  sperm_source: string | null;
  symmetry: string | null;
  stage: string;
  files: any; 
  location: string | null;
  is_screened: boolean;
  status: string;
  transfer_files_url: string | null;
  pgtResult: string | null;
  betaHCG: string | null; 
  usg: string | null; 
  pregnancyOutcome: string | null; 
}

export function getEmbryoTransferOutcomeUrl(Id: string): string {
  return `/embryo-transfer/cycle/${Id}`; 
}
  
export function getEmbryoTransferOutcome(Id: string): Promise<EmbryoTransfer> {
  return getRequest(getEmbryoTransferOutcomeUrl(Id), { responseType: 'json' });
}


export function getEmbryoTransferDetailsUrl(Id: string): string {
  return `/embryo/cycle/${Id}/?action=TRANSFER/`; 
}

export function getEmbryoTransferDetails(Id: string): Promise<EmbryoTransferDetail[]> {
  return getRequest(getEmbryoTransferDetailsUrl(Id), { responseType: 'json' });
}