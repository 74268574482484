/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import OocyteSelector from './occyteSelector';
import DetailsTable from './detailsTable';
import { action945Items, cultureDishConfigItems, EmbryoDetail, EmbryoFinilizeUpdateType, EmbryoOocyteFilledStatus, fertilizationItems, FreezeDialogValues, incubatorItems, initialValuesCryo, inseminationTechniqueItems, pgtorderDetailsItems, source_list, spermSourceList, UpdateEmbryo } from './embryoCulture.helpers';
import { Autocomplete, Box, Dialog, FormControl, Grid2, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, ThemeProvider } from '@mui/material';
import styles from './embryoCulture.module.css';
import { theme } from '../../../components/Medical_History_Forms/themeprovider';
import { Form } from 'react-router-dom';
import { Formik, FormikErrors, FormikHelpers, getIn } from 'formik';
import { convertUnderscoreToTitle, RequiredLabel } from '../../../utils/commonUtils';
import { Clinician, Role, ROLES } from '../Occyte-Grading/oocyteGrading.helper';
import { useClinic } from '../../../components/ClinicProvider';
import { useAlert } from '../../../components/alertProvider';
import { useLoader } from '../../../components/loaderProvider/loader';
import { getUsersByClinicId } from '../../../services/apiService';
import ImageUploadComponent from '../../../components/Surgery/laparoscopy/imageUploadLaparoscopy';
import { ConfirmationModal } from '../../../components/confirmationModal';
import { CombinedValidationSchema, EmbryoCultureType, EmbryoUpdateDetailsType,columns, getEmbryoCultureDetails, initialEmbryoCultureValues, initialEmbryoUpdateDetailsValues, updateEmbryoCulture, updateEmbryoSummary } from './dayZeroHelper';
import { AxiosError } from 'axios';
import GradingUnavailable from '../Occyte-Grading/oocyteGradingUnavailable';
import SemenSampleComponent from './SemenSampleComponent';
import EmbryoCultureViewMode from './ViewMode';
import OverlayIconPanel from './overlay';
import FreezeActionDialog from './freezeActionDialog';
import EmbryoFileUpload from './EmbryoFileUpload';

interface cycleProp {
    initialCycleId: number;
  }
  
const DayZero:React.FC<cycleProp> = ({ initialCycleId }) => {
   
  const [selectedCycleId, setSelectedCycleId] = useState<number>(initialCycleId);
  const { ClinicId } = useClinic();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [file, setFile] = useState<File | null>(null); 
  const [imagePathUrl, setImagePathUrl] = useState<string | null>(null);
  const [selectedEmbryos, setSelectedEmbryos] = useState<number[]>([]);
  const [allEmbryos, setAllEmbryos] = useState<EmbryoDetail[]>([]);
  const [allSourceEmbryos, setAllSourceEmbryos] = useState<UpdateEmbryo[]>([]);
  const [selectedSource, setselectedSource] = useState<string>('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [rows, setRows] = useState<any[]>([]);
  const [actionStatus, setActionStatus] = useState<'DRAFT' | 'FINALIZED'>('DRAFT');
  const [embryoCulture, setEmbryoCulture] = useState<EmbryoCultureType>(initialEmbryoCultureValues);
  const [embryoDetails, setEmbryoDetails] = useState<EmbryoUpdateDetailsType>(initialEmbryoUpdateDetailsValues);
  const [hitSubmitbtn, setHitSubmitbtn] = useState(false);
  const [embryosFound, setEmbryosFound] = useState<boolean>(true); 
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSpermId, setSelectedSpermId] = useState<number | null>(null);
  const [isTableDeletable, setIsTableDeletable] = useState(true);
  
  const [freezeValues, setFreezeValues] = useState<FreezeDialogValues>(initialValuesCryo);
  const [isFreezeDialogOpen, setFreezeDialogOpen] = useState(false);
  
  const [areAllOocytesFilled, setAreAllOocytesFilled] = useState(false);
  const [isFinalizeConfirmDialogOpen, setFinalizeConfirmDialogOpen] = useState(false);
  
  // Initialize state for clinician lists
  const [clinicianLists, setClinicianLists] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    ANESTHETIST: [],
    EMBRYOLOGIST: [],
    NURSE: [],
  });

  const [sourceData, setSourceData] = useState<source_list[]>([]);
  const [selectedSourceId, setSelectedSourceId] = useState<number | null>(null);
  const [selectedThawId, setSelectedThawId] = useState<number | null>(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  const fetchEmbryoDetails = useCallback(
    (embryo_id: number,  sourceID?: number, thawId?:number | null) => {
      setSelectedEmbryos([]);
      showLoader();
      if (embryo_id) {
        getEmbryoCultureDetails(embryo_id, sourceID, thawId)  
          .then((data) => {
            if (data.embryo && data.embryo.length === 0) {
              setEmbryosFound(false); 
            } else {
              setEmbryosFound(true); 
            }
            const embryoDetails = data.embryo
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((detail: any) => ({
                embryo_number: detail.embryo_number,
                isFilled: detail.action !== null,
              }))
              .sort((a: { embryo_number: number; }, b: { embryo_number: number; }) => a.embryo_number - b.embryo_number);
            setAllEmbryos(embryoDetails);
            setEmbryoCulture((prevEmbryoCulture) => ({
              ...prevEmbryoCulture,  
              ...data?.details[0],      
            }));        
          })
          .catch((error) => {
            console.error('Error fetching Embryo Details:', error);
          })
          .finally(() => {
            hideLoader();

          });
      }
    },
    [showLoader, hideLoader]
  );

  const fetchSourceList = useCallback(()=>{
    getEmbryoCultureDetails(selectedCycleId)
      .then((data) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
        processEmbryoData(data,selectedSourceId, selectedThawId );
        // Check if source_list exists before updating state
        if (data && Array.isArray(data.source_list)) {
          setSourceData(data.source_list);
          // Set the first object's source as the selectedSourceId if sourceData is not empty
          if (data.source_list.length > 0) {

            const firstSourceId = data.source_list[0].source;
            const FirstThawId = data.source_list[0].thaw_cycle;
            setselectedSource(data.source_list[0].name);
            setSelectedSourceId(firstSourceId);
            setSelectedThawId(FirstThawId);
            // Call fetchEmbryoDetails with the first source ID
            fetchEmbryoDetails(selectedCycleId, firstSourceId, FirstThawId);
          }
          setAllSourceEmbryos(data.embryo);

          const finalizedItem = data.embryo?.every((item: EmbryoUpdateDetailsType) => item.status === 'FINALIZED');            
          setIsTableDeletable(!finalizedItem);

          const embryoDetails = data.embryo
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((detail: any) => ({
              embryo_number: detail.embryo_number,
              isFilled: detail.action !== null,
            }))
            .sort((a: { embryo_number: number; }, b: { embryo_number: number; }) => a.embryo_number - b.embryo_number);
                        
          if (embryoDetails.length >= 1) {
            const isAllFilled = embryoDetails.every((embryo: EmbryoOocyteFilledStatus) => embryo.isFilled);
                    
            if (!isAllFilled) {
              setAreAllOocytesFilled(false);
            } else {
              setAreAllOocytesFilled(true);
            }
          } else {
            setAreAllOocytesFilled(false);
          }            
        }
      })
      .catch((error) => {
        console.error('Error fetching source list:', error);
      });
  // eslint-disable-next-line
  },[]);

  useEffect(() => {
    fetchSourceList();
    // eslint-disable-next-line
  }, [selectedCycleId,]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processEmbryoData = (data:any, selectedSourceId:number |null |undefined, selectedThawId:number |null |undefined) => {
    if (data.details && Array.isArray(data.details) && !selectedSourceId && !selectedThawId) {
      console.log('entering in details table');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mappedRows = data.embryo.filter((item: any) => item.action !== null).sort((a: any, b: any) => a.embryo_number - b.embryo_number).map((item: any) => {
        const spermSourceContent = item.sperm_source_details
          ? {
            title: 'Sperm Source Information',
            details: [
              { label: 'Cycle No.', value: item.sperm_source_details.cycle || '-' },
              { label: 'Sample No.', value: item.sperm_source_details.sample_number || '-' },
              { label: 'Type of Sperm', value: convertUnderscoreToTitle(item.sperm_source_details.sperm_type) || '-' },
              { label: 'Insemination Volume', value: item.sperm_source_details.post_volume_ml || '-' },
              { label: 'Sperm Concentration', value: item.sperm_source_details.post_sperm_concentration || '-' },
              { label: 'Total Sperm Count', value: item.sperm_source_details.post_total_sperm_count || '-' },
            ],
          }
          : null;
  
        const freezeContent = item.action === 'FREEZE'
          ? {
            title: 'Action Information',
            details: [
              { label: 'Cryo Preserved Date', value: item.cryo_preserved_date || '-' },
              { label: 'Cryo Preserved By', value: item.cryo_preserved_by_name || '-' },
              { label: 'Cryo Purpose', value: convertUnderscoreToTitle(item.cryo_purpose) || '-' },
              { label: 'Media', value: convertUnderscoreToTitle(item.cryo_media) || '-' },
              { label: 'Screening Status', value: item.is_screened ? 'Yes' : 'No' },
            ],
          }
          : null;
  
        return {
          id: item.id,
          embryoNo: item.embryo_no,
          image: item.files_url,
          spermSource: item.sperm_source_details && spermSourceContent ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OverlayIconPanel content={spermSourceContent} />
              <span style={{ marginLeft: '5px' }}>{item.sperm_source_details.source_details === 'self' ? 'partner' : item.sperm_source_details.source_details}</span>
            </div>
          ) : '-',
          inseminationTechnique: item.insemination_technique,
          action: item.action === 'FREEZE' && freezeContent ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OverlayIconPanel content={freezeContent} />
              <span style={{ marginLeft: '5px' }}>{convertUnderscoreToTitle(item.action)}</span>
            </div>
          ) : convertUnderscoreToTitle(item.action),
          freezeLocation: '-',
          status: item.insemination_technique,
          spermSourceInfo: spermSourceContent ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <OverlayIconPanel content={spermSourceContent} />
              <span style={{ marginLeft: '5px' }}>Info</span>
            </div>
          ) : '-',
        };
      });
  
      setRows(mappedRows);
    }
  };

  // Handle dropdown change using SelectChangeEvent type
  const handleSourceChange = (event: string) => {
    const selectedSOurceName = event;
    const selectedSourceItem = sourceData.find(item => item.name === selectedSOurceName) || null;
    // Log the selected source item
    console.log('Selected Source Item:', selectedSourceItem);
    setselectedSource(selectedSOurceName); // Update selectedSourceId as well

    // Use a default value if cycle is undefined
    const cycle_ID = selectedSourceItem?.cycle ?? 0; 
    setSelectedCycleId(cycle_ID);

    setSelectedSourceId(selectedSourceItem?.source ?? 0);
    setSelectedThawId(selectedSourceItem?.thaw_cycle ?? null);

    if (selectedSourceItem) {
      fetchEmbryoDetails(cycle_ID, selectedSourceItem.source, selectedSourceItem.thaw_cycle); 
    } 
  };
  
  const handleSelectionChange = (newSelectedOocytes: number[], newDropdownValue: string) => {    
    setSelectedEmbryos(newSelectedOocytes);
    if (selectedSource && selectedSource !== newDropdownValue) {      
      setselectedSource(newDropdownValue);
      handleSourceChange(newDropdownValue);
    };
  };

  // useEffect(() => {
  //   fetchEmbryoDetails(selectedCycleId);
  // }, [fetchEmbryoDetails, selectedCycleId]);  
  
  // Wrap fetchCliniciansByRole in useCallback
  const fetchCliniciansByRole = useCallback((role: Role) => {
    showLoader();
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {
        setClinicianLists(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {
         
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]); // Add dependencies here
  
  // Fetch clinicians for each role on component mount or when ClinicId changes
  useEffect(() => {
    ROLES.forEach(fetchCliniciansByRole);
  }, [fetchCliniciansByRole]); // Use the memoize
  


  const sanitizeEmbryoCulture = (values: EmbryoCultureType): EmbryoCultureType => {
    const sanitizedValues = { ...values };
  
    if (sanitizedValues.time && sanitizedValues.time.trim().length === 5) {
      sanitizedValues.time = `${sanitizedValues.time}:00`;
    }
  
    sanitizedValues.day = 0; 
    sanitizedValues.cycle = selectedCycleId; 
  
    return sanitizedValues;
  };
  
  const sanitizeEmbryoDetails = (values: EmbryoUpdateDetailsType): EmbryoUpdateDetailsType => {
    const sanitizedValues = { ...values };
    sanitizedValues.cycle = selectedCycleId; 
    sanitizedValues.status = actionStatus;     
    sanitizedValues.cryo_preserved_date = freezeValues.cryo_preserved_date || sanitizedValues.cryo_preserved_date;
    sanitizedValues.cryo_preserved_by = freezeValues.cryo_preserved_by || sanitizedValues.cryo_preserved_by;
    sanitizedValues.cryo_purpose = freezeValues.cryo_purpose || sanitizedValues.cryo_purpose;
    sanitizedValues.cryo_media = freezeValues.cryo_media || sanitizedValues.cryo_media;
    sanitizedValues.is_screened = freezeValues.is_screened || sanitizedValues.is_screened;
    sanitizedValues.source = selectedSourceId;
    sanitizedValues.thaw_cycle = selectedThawId;    

    return sanitizedValues;
  };

  const handleSubmit = async (
    values: { embryoCulture: EmbryoCultureType; embryoDetails: EmbryoUpdateDetailsType },
    { setSubmitting, setErrors, resetForm }: FormikHelpers<{ embryoCulture: EmbryoCultureType; embryoDetails: EmbryoUpdateDetailsType }>
  ) => {
    showLoader();
     
    const sanitizedEmbryoCulture = sanitizeEmbryoCulture(values.embryoCulture);
    const sanitizedEmbryoDetails = sanitizeEmbryoDetails(values.embryoDetails); 
             
    console.log('selectedCycleId:', selectedCycleId);
    console.log('selectedSourceId (processed):', selectedSourceId ?? undefined);
    console.log('selectedThawId:', selectedThawId);

    Promise.all([
      updateEmbryoCulture(values.embryoCulture.id ?? 0, sanitizedEmbryoCulture),
      updateEmbryoSummary(selectedCycleId, sanitizedEmbryoDetails),
    ])
      .then(([updatedEmbryoCulture, updatedEmbryoDetails]) => {
        addAlert('Embryo details saved successfully', 'success');
     
        // Reset form with sanitized values
        resetForm({
          values: {
            embryoCulture: sanitizedEmbryoCulture,
            embryoDetails: initialEmbryoUpdateDetailsValues,
          },
        });
     
        // fetchEmbryoDetails(selectedCycleId ?? 0); 

        fetchEmbryoDetails(selectedCycleId,  selectedSourceId ?? undefined, selectedThawId);
        fetchSourceList(); 
        console.log('Selected Embryos Cleared:', selectedEmbryos);
        setSelectedEmbryos([]);
      })
      .catch((error: unknown) => {
        // Handle errors
        if (error instanceof AxiosError) {
          if (error.response?.data?.type === 'validation_error') {
            const apiErrors = error.response.data.errors;
     
            const formikErrors: FormikErrors<{
                 embryoCulture: EmbryoCultureType;
                 embryoDetails: EmbryoUpdateDetailsType;
               }> = {
                 embryoCulture: {} as FormikErrors<EmbryoCultureType>,
                 embryoDetails: {} as FormikErrors<EmbryoUpdateDetailsType>,
               };

            apiErrors.forEach((err: { attr: string | null; detail: string }) => {
              if (err.attr && err.attr.startsWith('embryoCulture')) {
                const fieldKey = err.attr.replace('embryoCulture.', '');
                  formikErrors.embryoCulture![fieldKey as keyof EmbryoCultureType] = err.detail;
              } else if (err.attr && err.attr.startsWith('embryoDetails')) {
                const fieldKey = err.attr.replace('embryoDetails.', '');
                  formikErrors.embryoDetails![fieldKey as keyof EmbryoUpdateDetailsType] = err.detail;
              } else {
                console.error('Unhandled validation error:', err.detail);
                
                addAlert(err.detail, 'error');
              }
            });
     
            setErrors(formikErrors);
          } else {
            console.error('Unexpected error occurred:', error);
          }
        } else {
          console.error('Error occurred while saving embryo details:', error);
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
      });
  };
  
  const handleDelete = (id: number | string) => {
    setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    // fetchEmbryoDetails(selectedCycleId,  selectedSourceId ?? undefined, selectedThawId); 
    fetchSourceList();
  };


  const saveFinalize = () => {
    
    const finalizePromises = sourceData.map((source) => {
      // Filter embryos that match the current source
      const filteredEmbryos = allSourceEmbryos
        .filter((embryo) => embryo.source === source.source && embryo.thaw_cycle === source.thaw_cycle)
        .map((embryo) => embryo.embryo_number);

      console.log(filteredEmbryos);
      const payload: EmbryoFinilizeUpdateType = {
        embryos: filteredEmbryos,  
        cycle: selectedCycleId as number,
        day: 0, 
        status: 'FINALIZED',
        source:source.source,
        thaw_cycle:source.thaw_cycle
      };
      return updateEmbryoSummary(selectedCycleId, payload)
        .then(() => {
          addAlert(`Embryo details for source ${source.name} saved successfully`, 'success');
        })
        .catch((error) => {
          console.error(`Error finalizing embryo summary for source ${source.name}:`, error);
        });
    }); 
    Promise.all(finalizePromises).then(() => {
      fetchSourceList();
    }); 

  };

  if (!isTableDeletable) {
    return (
      <div >
        <EmbryoCultureViewMode data={embryoCulture} tableData={rows} isFinalized ={isTableDeletable} tablecolumns= {columns} day={0}/>
      </div>
    );
  };

  
  const handleFreezeDialogSave = (formValues: FreezeDialogValues) => {
    setFreezeValues(formValues); 
    setFreezeDialogOpen(false);
  };
    

  return (    
    <ThemeProvider theme={theme}>
      {!embryosFound ? (
      // If no embryos are found, show 
        <GradingUnavailable  message="No embryos available for the selected day." />
      ) : (
        <Formik
          initialValues={{ embryoCulture, embryoDetails }} 
          validationSchema={CombinedValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            setFieldValue,
            submitForm,
            validateForm,
            errors,
            touched,
            handleBlur,
            setFieldTouched,
            handleChange,
          }) => {
            return (
              <Form onSubmit={submitForm}>
                <Grid2 container spacing={2} marginTop={2}>
                  <Grid2 size={{xs:12, sm:3, md:3}}>
                    <TextField
                      name="date"
                      type="date"
                      label={RequiredLabel('Date', true)}                      
                      value={getIn(values, 'embryoCulture.opu_date') || ''}
                      disabled
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      defaultValue={new Date().toISOString().split('T')[0]}       
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <TextField
                      name="embryoCulture.time"
                      type="time"
                      label="Time"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={getIn(values, 'embryoCulture.time') || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        !!getIn(touched, 'embryoCulture.time') &&
                      !!getIn(errors, 'embryoCulture.time')
                      }
                    />
                  </Grid2>


                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="embryologist"
                      options={clinicianLists['EMBRYOLOGIST'] || []}
                      getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                      value={clinicianLists['EMBRYOLOGIST'].find(
                        (doctor) => doctor.id === values.embryoCulture?.embryologist
                      ) || null}
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.embryologist', value?.id || ''); // Update Formik's value for 'embryoCulture.embryologist'
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Embryologist', true)} // Your label function
                          error={
                            !!getIn(touched, 'embryoCulture.embryologist') &&
                          !!getIn(errors, 'embryoCulture.embryologist')
                          }
                        />
                      )}
                    />
                  </Grid2>
                    
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="witness"
                      options={clinicianLists['EMBRYOLOGIST'] || []}
                      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                      value={clinicianLists['EMBRYOLOGIST'].find(
                        (doctor) => doctor.id === values.embryoCulture?.witness
                      ) || null}
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.witness', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Witness', true)}
                          error={
                            !!getIn(touched, 'embryoCulture.witness') &&
                          !!getIn(errors, 'embryoCulture.witness')
                          }
                          helperText={
                            getIn(touched, 'embryoCulture.witness') && getIn(errors, 'embryoCulture.witness')
                              ? getIn(errors, 'embryoCulture.witness') === 'Witness Embryologist name is required'
                                ? '': getIn(errors, 'embryoCulture.witness') : ''
                          }
                        />
                      )}
                    />
                  </Grid2>
      
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={incubatorItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        incubatorItems.find(
                          (option) => option.value === getIn(values, 'embryoCulture.incubator')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.incubator', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Incubator', false)}
                          error={
                            !!getIn(touched, 'embryoCulture.incubator') &&
                          !!getIn(errors, 'embryoCulture.incubator')
                          }
                        />
                      )}
                    />
                  </Grid2>
                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={fertilizationItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        fertilizationItems.find(
                          (option) => option.value === getIn(values, 'embryoCulture.fertilization')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.fertilization', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Fertilization', false)}
                          error={
                            !!getIn(touched, 'embryoCulture.fertilization') &&
                                !!getIn(errors, 'embryoCulture.fertilization')
                          }
                        />
                      )}
                    />
                  </Grid2>
      
                  <Grid2 size={{ xs: 12, sm: 3, md: 3 }}>
                    <TextField
                      name="remarks"
                      label={RequiredLabel('Remark', false)}
                      fullWidth
                      multiline
                      rows={1}
                      placeholder="Enter remark"
                      value={values.embryoCulture?.remarks || ''}
                      onChange={(e) => {
                        setFieldValue('embryoCulture.remarks', e.target.value || '');
                      }}
                      onBlur={handleBlur}
                      error={
                        !!getIn(touched, 'embryoCulture.remarks') &&
                      !!getIn(errors, 'embryoCulture.remarks')
                      }
                    />
                  </Grid2>
                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={cultureDishConfigItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        cultureDishConfigItems.find(
                          (option) => option.value === getIn(values, 'embryoCulture.culture_dish_config')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.culture_dish_config', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Culture Dish Configuration', false)}
                          error={
                            !!getIn(touched, 'embryoCulture.culture_dish_config') &&
                          !!getIn(errors, 'embryoCulture.culture_dish_config')
                          }
                        />
                      )}
                    />
                  </Grid2>              
                </Grid2>   
      
                <div>
                  <OocyteSelector
                    title="Occyte No."
                    sourceList={sourceData}
                    selectedsource={selectedSource}
                    totalOocytes={allEmbryos}
                    onSelectionChange={handleSelectionChange}
                    hitSubmitbtn={hitSubmitbtn}
                  />
                </div>
                
                <Grid2 container spacing={2} marginTop={2}>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={spermSourceList}
                      getOptionLabel={(option) => option.label}
                      value={
                        spermSourceList.find(
                          (option) => option.value === getIn(values, 'embryoDetails.source_select')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.source_select', value?.value || '');
                        if (value?.value === 'YES' || ['PARTNER', 'DONOR'].includes(value?.value ?? '')) {
                          setOpenDialog(true);                        
                        } else {
                          setOpenDialog(false);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Sperm Source', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.source_select') &&
                            !!getIn(errors, 'embryoDetails.source_select')
                          }
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={inseminationTechniqueItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        inseminationTechniqueItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.insemination_technique')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.insemination_technique', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Insemination Technique', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.insemination_technique') &&
                                !!getIn(errors, 'embryoDetails.insemination_technique')
                          }
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={action945Items}
                      getOptionLabel={(option) => option.label}
                      value={
                        action945Items.find(
                          (option) => option.value === getIn(values, 'embryoDetails.action')
                        ) || null
                      }                                     
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.action', value?.value || '');
                        if (value?.value === 'FREEZE') {
                          setFreezeDialogOpen(true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Action', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.action') &&
                            !!getIn(errors, 'embryoDetails.action')
                          }
                        />
                      )}
                    />
                  </Grid2>
                    
                    
                  {/* Freeze Action Dialog */}
                  <FreezeActionDialog
                    open={isFreezeDialogOpen}
                    onClose={() => {
                      setFieldValue('embryoDetails.action', ''); 
                      setFreezeDialogOpen(false);  
                    }}
                    values={freezeValues}
                    onSave={handleFreezeDialogSave}                    
                    selectedEmbryos={selectedEmbryos} 
                  />
                  
                  <Grid2 size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                    <EmbryoFileUpload selectedEmbryos={selectedEmbryos} onFileUpload={setFile} imagePathUrl={imagePathUrl} cycle_id={selectedCycleId || null} day={0}/>
                  </Grid2>
      
                </Grid2>
      
                <div >
                  <p style={{fontFamily:'Montserrat', fontSize:'20px', fontWeight:700, padding:'1rem'}}>Details</p>
                  <DetailsTable columns={columns} rows={rows} isDeletable={isTableDeletable}  onDelete={handleDelete}/>
                </div>
                <div>
                  <Box sx={{display:'flex', justifyContent:'space-between'}}>
                    <Box sx={{display:'flex', justifyContent:'flex-start', marginTop:'1rem'}}>
                      <button
                        type="button"
                        className={styles.resetButton}
                        // onClick={() => setOpenResetConfirmDialog(true)} 
                      >
                      Cancel
                      </button>
                    </Box>
                    <Box className={`${styles.customButtonDiv}`}  >    

                      {!areAllOocytesFilled ? (            
                        <button
                          type="button"
                          className={styles.resetButton}
                          style={{width:'10rem',
                            cursor : areAllOocytesFilled ? 'pointer':'not-allowed'
                          }}
                          disabled={!areAllOocytesFilled}
                       
                          onClick={async () => {
                            // Ensure setFieldValue completes before proceeding
                            await setFieldValue('embryoDetails.embryos', selectedEmbryos);
                    
                            setActionStatus('FINALIZED');

                            setHitSubmitbtn(true);
                    
                            validateForm().then((formErrors) => {
                              // Mark fields with validation errors as touched
                              Object.entries(formErrors).forEach(([namespace, fields]) => {
                                if (fields && typeof fields === 'object') {
                                  Object.keys(fields).forEach((field) => {
                                    setFieldTouched(`${namespace}.${field}`, true, true);
                                  });
                                }
                              });
                    
                              if (Object.keys(formErrors).length === 0) {
                                setOpenConfirmDialog(true);
                              } else {
                                console.log('Validation errors:', formErrors);
                              }
                            });
                          }}
                        >
                        Save & Finalize
                        </button>
                      ) : (
                        <button
                          type="button"
                          style={{ width: '10rem' }}
                          className={styles.resetButton}                          
                          onClick={() => setFinalizeConfirmDialogOpen(true)}
                        >
                          <span style={{ fontWeight: 'bold' }}>
                            {'Save & Finalized'}
                          </span>
                        </button>
                      )}

                      <button
                        type="button"
                        onClick={async () => {
                    
                          // Ensure setFieldValue completes before proceeding
                          await setFieldValue('embryoDetails.embryos', selectedEmbryos);
                    
                          setActionStatus('DRAFT');
                          setHitSubmitbtn(true);
                    
                          validateForm().then((formErrors) => {
                          // Mark fields with validation errors as touched
                            Object.entries(formErrors).forEach(([namespace, fields]) => {
                              if (fields && typeof fields === 'object') {
                                Object.keys(fields).forEach((field) => {
                                  setFieldTouched(`${namespace}.${field}`, true, true);
                                });
                              }
                            });
                    
                            if (Object.keys(formErrors).length === 0) {
                              setOpenConfirmDialog(true);
                            } else {
                              console.log('Validation errors:', formErrors);
                            }
                          });
                        }}
                    
                        className={`${styles.customButton}`}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Save
                        </span>
                      </button>                 
                    </Box>
                  </Box>
                </div>
                  
                <ConfirmationModal
                  open={openConfirmDialog}
                  handleClose={() => setOpenConfirmDialog(false)}
                  onConfirm={() => {
                    setOpenConfirmDialog(false);
                    submitForm(); 
                  }} 
                  type="save"
                  title="Embryo Culture"
                  contentMessage="Are you sure you want to save Day 0?"
                />

                <Dialog open={openDialog}
                  onClose={handleCloseDialog}
                  PaperProps={{
                    style: {
                      maxWidth: '1000px',
                      minWidth: '700px',
                      borderRadius: '25px',
                    },
                  }}
                >
                  <SemenSampleComponent
                    dialogTitle='Processed Semen Sample'
                    selectedSpermId={selectedSpermId} 
                    onClose={(data) => {
                      console.log('Selected Sperm ID :', data?.spermId);
                      setSelectedSpermId(data?.spermId || null); 
                      setFieldValue('embryoDetails.sperm_source', data?.spermId);
                      handleCloseDialog();
                    }}
                  />
                </Dialog>
      
                <ConfirmationModal
                  open={isFinalizeConfirmDialogOpen}
                  handleClose={() => setFinalizeConfirmDialogOpen(false)}
                  onConfirm={() => {
                    setFinalizeConfirmDialogOpen(false);
                    saveFinalize();
                  }} 
                  type="save"
                  title="Embryo Culture"
                  contentMessage={ 'Are you sure you want to finalize day 0 details?'
                  }
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </ThemeProvider>
  );
};

export default DayZero; 
