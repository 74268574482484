/* eslint-disable no-console */

import React, { useEffect, useState } from 'react';
import styles from './EmbryoTransferOutcome.module.css';
import { ReactComponent as EmbryoTransferProcedureImg } from '../../../assets/SVG/EmbryoTransferProcedure.svg';
import Grid from '@mui/material/Grid';
import { EmbryoTransfer, EmbryoTransferDetail, getEmbryoTransferDetails, getEmbryoTransferOutcome } from './EmbryoTransferOutcomeHelper';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
  interface EmbryoTransferOutcomeProps {
    cycle: string;
  }
  
const EmbryoTransferOutcome: React.FC<EmbryoTransferOutcomeProps> = ({ cycle }) => {
  const [OocyteRetrieval, setOocyteRetrievalData] = useState<EmbryoTransfer | null>(null);
  const [embryoTransferDetails, setEmbryoTransferDetails] = useState<EmbryoTransferDetail[] | null>(null);
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null); 
  const [oocyteNumber, setOocyteNumber] = useState<string | number | null>(null);
  
  useEffect(() => {
    if (cycle) {
      getEmbryoTransferOutcome(cycle)
        .then((data) => {
          setOocyteRetrievalData(data);
        })
        .catch((error) => {
          console.error('Error fetching Oocyte Retrieval data:', error);
        });
    }
  }, [cycle]);




  useEffect(() => {
    getEmbryoTransferDetails(cycle)
      .then((response) => {
        setEmbryoTransferDetails(response); 
      })
      .catch((error) => {
        console.error('Error fetching Embryo Transfer Details:', error); 
      });
  }, [cycle]); 

  const openImagePreview = (src: string, oocyte: string | number) => {
    setImageSrc(src);
    setOocyteNumber(oocyte);
    setOpen(true);
  };

  const closeImagePreview = () => {
    setOpen(false);
    setImageSrc(null);
    setOocyteNumber(null);
  };



  return (
    <div className={styles.container}>
      <Grid container spacing={3} alignItems="flex-start">
        {/* Image Section */}
        <Grid item xs={12} md={4} className={styles.embryoProcedure}>
          <EmbryoTransferProcedureImg style={{ width: '20rem', height: '13.75rem' }} />
        </Grid>

        {/* Text Data Section */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Date of Transfer</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.date || '-'}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Time of Transfer</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.start_time || '-'}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Embryologist</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.witness_embryologist_name || '-'}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Transfer Method</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.transfer_method || '-'}</p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Endometrium Thickness (mm)</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.endometrium_thickness || '-'}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Endometrium Pattern</strong>
                <p className={styles.customTextStyle}>
                  <p className={styles.customTextStyle}>{OocyteRetrieval?.endometrium_pattern || '-'}</p>
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Fresh Transfer</strong>
                <p className={styles.customTextStyle}>
                  <p className={styles.customTextStyle}>{OocyteRetrieval?.fresh_embryo_transfer_method || '-'}</p>
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Frozen Transfer</strong>
                <p className={styles.customTextStyle}>
                  <p className={styles.customTextStyle}>{OocyteRetrieval?.frozen_embryo_transfer_method || '-'}</p>
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Catheter</strong>
                <p className={styles.customTextStyle}>
                  <p className={styles.customTextStyle}>{OocyteRetrieval?.catheter || '-'}</p>
                </p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Transfer Depth</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.transfer_depth || '-'}</p>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Ultrasound Method</strong>
                <p className={styles.customTextStyle}>{OocyteRetrieval?.ultrasound_method || '-'}</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TableContainer className='mt-4'>
        <span className={`${styles.Embryotext} mt-4`}>Embryo Transfer Details</span>
        <Table className='mt-4'>
          <TableHead>
            <TableRow>
              <TableCell className={styles.headerTextTable}><strong>Embryo No.</strong></TableCell>
              <TableCell className={styles.headerTextTable}><strong>Insemination Technique</strong></TableCell>
              <TableCell className={styles.headerTextTable}><strong>Cell Stage</strong></TableCell>
              <TableCell className={styles.headerTextTable}><strong>Day</strong></TableCell>
              <TableCell className={styles.headerTextTable}><strong>Grade</strong></TableCell>
              <TableCell className={styles.headerTextTable}><strong>Images</strong></TableCell>
              <TableCell className={styles.headerTextTable}><strong>PGT Result</strong></TableCell>
              <TableCell className={styles.headerTextTable}><strong>+Beta HCG</strong></TableCell>
              <TableCell className={styles.headerTextTable}><strong>USG</strong></TableCell>
              <TableCell className={styles.headerTextTable}><strong>Pregnancy Outcome</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {embryoTransferDetails && embryoTransferDetails.length > 0 ? (
              embryoTransferDetails.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={styles.headerTextTable}>{row.embryo_number}</TableCell>
                  <TableCell className={styles.headerTextTable}>{row.insemination_technique || '-'}</TableCell>
                  <TableCell className={styles.headerTextTable}>{row.stage || '-'}</TableCell>
                  <TableCell className={styles.headerTextTable}>{row.day}</TableCell>
                  <TableCell className={styles.headerTextTable}>{row.grade}</TableCell>
                  <TableCell className={styles.headerTextTable}>
                    <img
                      src={row.transfer_files_url as string}
                      alt=""
                      style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                      onClick={() => {
                        if (row.transfer_files_url ) {
                          openImagePreview(row.transfer_files_url as string, row.embryo_number);
                        }
                      }}
                      onError={(e) => {
                        (e.target as HTMLImageElement).style.display = 'none';
                        (e.target as HTMLImageElement).parentElement?.appendChild(
                          document.createElement('span')
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell className={styles.headerTextTable}>{row.pgtResult || '-'}</TableCell>
                  <TableCell className={styles.headerTextTable}>{row.betaHCG || '-'}</TableCell>
                  <TableCell className={styles.headerTextTable}>{row.usg || '-'}</TableCell>
                  <TableCell className={styles.headerTextTable}>{row.pregnancyOutcome || '-'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} className={styles.headerTextTable}>
                No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={closeImagePreview}
        maxWidth={false}
        sx={{
          width: '600px', 
          height: '600px', 
          margin: 'auto',
          '& .MuiPaper-root': { 
            width: '600px',
            height: '600px', 
            overflow: 'hidden', 
          },
        }}
      >
        <DialogTitle sx={{ position: 'relative' }}>
    Embryo {oocyteNumber}
          <IconButton
            onClick={closeImagePreview}
            color="primary"
            sx={{
              position: 'absolute',
              top: 10,
              right: 4,
              padding: '8px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - 56px)', 
            overflow: 'auto', 
          }}
        >
          {imageSrc && (
            <img
              src={imageSrc}
              alt="Large Preview"
              style={{
                maxWidth: '100%', 
                maxHeight: '100%',
                objectFit: 'contain',
                display: 'block', 
              }}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: '16px', 
            justifyContent: 'center', 
          }}
        >
          <Button onClick={closeImagePreview} color="primary" sx={{ textTransform: 'capitalize' }}>
      Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmbryoTransferOutcome;
