import * as Yup from 'yup';
import { Action945Enum, BlankEnum, CultureDishConfigEnum, FertilizationEnum, IncubatorEnum, InseminationTechniqueEnum, NullEnum, StatusDa2Enum } from './embryoCulture.helpers';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getRequest, postRequest, putRequest } from '../../../services/httpService';

// Define the initial embryo culture and update details types
export interface EmbryoCultureType {
    time: string; 
    embryologist?: number | null; 
    witness?: number | null; 
    culture_dish_config?: CultureDishConfigEnum | BlankEnum | NullEnum | null; 
    incubator?: IncubatorEnum | BlankEnum | NullEnum | null
    fertilization?: FertilizationEnum | BlankEnum | NullEnum | null;
    remarks?: string | null; 
    day: number; 
    cycle: number;
    id?: number | null | undefined;
    opu_date?:string | null;
  }
  
export interface EmbryoUpdateDetailsType {    
    cycle: number;
    embryos: number[] | null;
    action: Action945Enum | BlankEnum;
    // files: string[] | null;
    insemination_technique: InseminationTechniqueEnum | null;
    sperm_source: number[] |null;
    day: number;
    status: StatusDa2Enum |BlankEnum;
    
    cryo_preserved_date: string | null;
    cryo_preserved_by: number | null;
    cryo_purpose: string | null;
    cryo_media: string | null;
    is_screened: boolean;
    source?: number | null;
    thaw_cycle?: number | null;
    source_select: string | null;

  }
export const initialEmbryoCultureValues: EmbryoCultureType = {
  time: '', 
  embryologist: null, 
  witness: null, 
  culture_dish_config: null, 
  incubator: null, 
  fertilization: null, 
  remarks: null, 
  day: 0, 
  cycle:0,
  opu_date:''
};
  
export const initialEmbryoUpdateDetailsValues: EmbryoUpdateDetailsType = {
  cycle:0, 
  embryos: [], 
  action: '',
  // files: null, 
  insemination_technique: null,
  sperm_source: null, 
  day: 0, 
  status: 'DRAFT',
  
  cryo_preserved_date: null,
  cryo_preserved_by: null,
  cryo_purpose: null,
  cryo_media: null,
  is_screened: false,
  source_select: null
};
  


// Validation Schema for single embryo culture
export const EmbryoCultureValidationSchema = Yup.object().shape({

  embryologist: Yup.number()
    .required('Embryologist name is required'),
  witness: Yup.number()
    .required('Witness Embryologist name is required')
    .test('not-same', 'The witness embryologist cannot be the same as the embryologist.', function (value) {
      const { embryologist } = this.parent; // Accessing the sibling field
      return value !== embryologist; // Validate that they are not the same
    }),
  
  cycle: Yup.number().integer(),
  remarks: Yup.string().nullable(),
  culture_dish_config: Yup.mixed<CultureDishConfigEnum | BlankEnum | NullEnum>()
    .oneOf([
      'NORMAL_SINGLE_CULTURE', 'EMBRYO_SLIDE_TIMELAPSE_SINGLE_CULTURE', 
      'CULTURE_PRO_TIMELAPSE_SINGLE_CULTURE', 'EMBRYO_SLIDE_IC8_DISH_TIMELAPSE_SINGLE_CULTURE', 
      '38_SPECIAL_GPS_SINGLE_CULTURE', 'EMBRYO_GPS_SINGLE_CULTURE', 
      'MINI_GPS_GPS_SINGLE_CULTURE', '4_WELL_GPS_SINGLE_CULTURE', 
      'U_DROP_GPS_SINGLE_CULTURE', 'EMBRYO_CORRAL_GPS_SINGLE_CULTURE', 
      'NORMAL_GROUP_CULTURE', 'MICROWELL_GROUP_CULTURE', '', 'NULL'
    ])
    .nullable(),
  day: Yup.number().min(0).max(30).integer(),
  time: Yup.string().required(),
  incubator: Yup.mixed<IncubatorEnum | BlankEnum | NullEnum>()
    .oneOf(['HERA_CELL', 'MINC_K_SYSTEMS', 'MEDGYN', 'OOSAFE', 'VITROLIFE', 'IVF_TECH', '', 'NULL'])
    .nullable(),
  fertilization:Yup.mixed<FertilizationEnum | BlankEnum | NullEnum>()
    .oneOf(['NORMAL', 'ABNORMAL', 'UNFERTILIZED', '', 'NULL'])
    .nullable(),
});
    
// Validation Schema for single embryo update details
export const EmbryoValidationSchema = Yup.object().shape({
  embryos: Yup.array()
    .min(1, 'Embryo number is required')
    .required('Embryo number is required'), 
  action: Yup.mixed<Action945Enum | BlankEnum>()
    .oneOf(['CULTURE', 'FREEZE', 'DISCARD', ''], 'Invalid action')
    .required(),
  source_select: Yup.string().required(),
  status: Yup.mixed<StatusDa2Enum>()
    .oneOf(['DRAFT', 'FINALIZED'], 'Invalid status').nullable(),
});
    
// Combined Validation Schema for single objects
export const CombinedValidationSchema = Yup.object().shape({
  embryoCulture: EmbryoCultureValidationSchema,
  embryoDetails: EmbryoValidationSchema,
});


export function updateEmbryoCulture(id: number, data: EmbryoCultureType): Promise<EmbryoCultureType> {
  const url =`embryo-culture/${id}/details`;

  return putRequest(url, data, { responseType: 'json' });
}


export interface EmbryoFinilizeUpdateType {
  embryos: number[];             
  cycle: number;  
  day: number; 
  status: StatusDa2Enum | BlankEnum; 
}

export function updateEmbryoSummary(id: number, data: EmbryoUpdateDetailsType | EmbryoFinilizeUpdateType): Promise<EmbryoUpdateDetailsType | EmbryoFinilizeUpdateType> {
  const url = `/embryo/cycle/${id}/`;
  return putRequest(url, data, { responseType: 'json' });
}
// export function getEmbryoCultureDetails(id: number): Promise<any> {
//   const url =`cycle/${id}/embryos/?day=0`;
//   return getRequest(url, { responseType: 'json' });
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEmbryoCultureDetails(id: number, source?: number, thawId?:number | null): Promise<any> {
  let url = `cycle/${id}/embryos/?day=0`;

  // Only append the source parameter if it is provided
  if (source !== undefined) {
    url += `&source=${source}`;
  }

  if (thawId !== undefined && thawId !== null) {
    url += `&thaw_cycle=${thawId}`;
  }
  return getRequest(url, { responseType: 'json' });
}

export const columns = [
  { label: 'Embryo No.', field: 'embryoNo', editable: false },
  { label: 'Image', field: 'image', editable: false },
  { label: 'Sperm Source', field: 'spermSource', editable: true },
  { label: 'Insemination Technique', field: 'inseminationTechnique', editable: true },
  { label: 'Action', field: 'action', editable: false }, // Action column is special
  { label: 'Freeze Location', field: 'freezeLocation', editable: true },
];



export function deleteEmbryo(id: number ): Promise<void> {
  const url = `/embryo/${id}/deactivate/`;
  return putRequest(url, { responseType: 'json' });
}