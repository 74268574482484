/* eslint-disable no-console */
import React, { useState } from 'react';
import { getDateInDDMMYYYY } from '../../utils/commonUtils';
import { updateRenewalDate } from './cryoStorage.helpers';
import EditIcon from '@mui/icons-material/EditOutlined';  

interface EditableDateCellProps {
  cryo_date?: string;
  itemId: number;
  type: string;
  onDateChange: (newDate: string) => void;
}

interface RenewalDatePayload {
  id: number;
  renewal_date: string;
  type: string;
}

const EditableDateCell: React.FC<EditableDateCellProps> = ({ cryo_date, itemId, type, onDateChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDate, setEditedDate] = useState<string>(cryo_date || '');

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;
    setEditedDate(newDate);
    setIsEditing(false);

    const payload: RenewalDatePayload = {
      id: itemId,
      renewal_date: newDate,
      type,
    };

    updateRenewalDate(payload)
      .then(() => {
        onDateChange(newDate);
      })
      .catch((error) => {
        console.error('Failed to update renewal date:', error);
      });
  };

  return (
    <div style={{ textAlign: 'left' }}>
      {isEditing ? (
        <input
          type="date"
          value={editedDate}
          onChange={handleDateChange}
          onBlur={() => setIsEditing(false)}
          autoFocus
          style={{ cursor: 'pointer', border: 'none' }}
        />
      ) : (
        <div onClick={() => setIsEditing(true)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          {cryo_date ? getDateInDDMMYYYY(cryo_date) : getDateInDDMMYYYY(new Date())}         
          <EditIcon sx={{ fontSize: 'small', ml: 1, cursor: 'pointer', color: '#3A80E2' }} /> 
        </div>
      )}
    </div>
  );
};

export default EditableDateCell;
