import { postRequest } from '../../../services/httpService';
import { ColumnConfig, GroupConfig } from '../../../types';
export type IndicationEccEnum = 'TUBAL_FACTOR'| 'OVULATORY_DYSFUNCTION'| 'DIMINISHED_OVARIAN_RESERVE'| 'ENDOMETRIOSIS'| 'UTERINE_FACTOR'| 'MALE_FACTOR'| 'UNEXPLAINED_INFERTILITY'| 'OTHER_FACTOR'| 'MULTIPLE_FACTORS'| 'ABNORMAL_SPERM_PARAMETERS'| 'AZOOSPERMIA_OBSTRUCTIVE'| 'AZOOSPERMIA_NON_OBSTRUCTIVE'| 'OLIGOSPERMIA_SEVERE'| 'OLIGOSPERMIA_MODERATE'| 'LOW_MOTILITY'| 'LOW_MORPHOLOGY'| 'VERY_SEVERE_MALE_FACTOR'| 'OTHER_MALE_FACTOR';
export type BlankEnum = '';
export type NullEnum = 'NULL';

export interface DonorForm {
    avatar:string
    donorName: string;
    contact: string;
    id: number;
    eyeColor: string;
    hairColor: string;
    skinColor: string;
    bloodGroup: string;
  }


export  type FormValues = {
    indication: string;
    notes: string;
    occytechecked: { self: boolean; donor: boolean };
    spermchecked: { self: boolean; donor: boolean };
    recipient: string;
  };

export interface FormErrors {
    indication?: string;
    notes?: string;
    occytechecked?: string;
    spermchecked?: string;
    recipient?: string;
  }

export const IndicationMenuItems: { value: IndicationEccEnum; label: string }[] = [
  { value: 'TUBAL_FACTOR', label: 'Tubal Factor' },
  { value: 'OVULATORY_DYSFUNCTION', label: 'Ovulatory Dysfunction' },
  { value: 'DIMINISHED_OVARIAN_RESERVE', label: 'Diminished Ovarian Reserve' },
  { value: 'ENDOMETRIOSIS', label: 'Endometriosis' },
  { value: 'UTERINE_FACTOR', label: 'Uterine Factor' },
  { value: 'MALE_FACTOR', label: 'Male Factor' },
  { value: 'UNEXPLAINED_INFERTILITY', label: 'Unexplained Infertility' },
  { value: 'OTHER_FACTOR', label: 'Other Factor' },
  { value: 'MULTIPLE_FACTORS', label: 'Multiple Factors' },
  { value: 'ABNORMAL_SPERM_PARAMETERS', label: 'Abnormal Sperm Parameters' },
  { value: 'AZOOSPERMIA_OBSTRUCTIVE', label: 'Azoospermia Obstructive' },
  { value: 'AZOOSPERMIA_NON_OBSTRUCTIVE', label: 'Azoospermia Non Obstructive' },
  { value: 'OLIGOSPERMIA_SEVERE', label: 'Oligospermia Severe' },
  { value: 'OLIGOSPERMIA_MODERATE', label: 'Oligospermia Moderate' },
  { value: 'LOW_MOTILITY', label: 'Low Motility' },
  { value: 'LOW_MORPHOLOGY', label: 'Low Morphology' },
  { value: 'VERY_SEVERE_MALE_FACTOR', label: 'Very Severe Male Factor' },
  { value: 'OTHER_MALE_FACTOR', label: 'Other Male Factor' },
];


export interface PatientProcedureCreate {
  patient: number; 
  procedures?: number | null; 
  services?: number[]; 
  indication?: string| null; 
  doctor: number; 
  donor_info?: string | null; 
  oocyte_source_self?: boolean |null; 
  sperm_source_self?: boolean |null; 
  recipient_self?: boolean |null; 
  procedure_notes?:string
}


export const columns: ColumnConfig[] = [
//   { field: 'avatar', headerName: '', type: 'text', editable: false, hideLabel: true },
  { field: 'donorName', headerName: 'Donor Name', type: 'text', editable: false, hideLabel: true },
  { field: 'contact', headerName: 'Contact', type: 'text', editable: false, hideLabel: true },
  { field: 'id', headerName: 'ID', type: 'text', editable: false, hideLabel: true },
  { field: 'eyeColor', headerName: 'Eye Color', type: 'text', editable: false, hideLabel: true },
  { field: 'hairColor', headerName: 'Hair Color', type: 'text', editable: false, hideLabel: true },
  { field: 'skinColor', headerName: 'Skin Color', type: 'text', editable: false, hideLabel: true },
  { field: 'bloodGroup', headerName: 'Blood Group', type: 'text', editable: false, hideLabel: true },
];
  
export const groupConfig: GroupConfig[] = [
//   { fields: ['avatar'], type: 'row' },
  { fields: ['donorName'], type: 'row' },
  { fields: ['contact'], type: 'row' },
  { fields: ['id'], type: 'column', defaultHeaderGroupname: 'ID' },
  { fields: ['eyeColor'], type: 'column', defaultHeaderGroupname: 'Eye Color' },
  { fields: ['hairColor'], type: 'column', defaultHeaderGroupname: 'Hair Color' },
  { fields: ['skinColor'], type: 'column', defaultHeaderGroupname: 'Skin Color' },
  { fields: ['bloodGroup'], type: 'column', defaultHeaderGroupname: 'Blood Group' },
];
  

export function addProcedures( data:PatientProcedureCreate):Promise<PatientProcedureCreate>{
  return postRequest('/patient-procedure/', data);
}


export const cycleRelatedMandatoryProcedures=[
  {
    procedureName: '(OI) Cycle',
    services: [
      {
        serviceName: 'Ovulation Induction (OI)',
        code: '99211'
      }
    ]
  },
  {
    procedureName: 'IVF/ICSI',
    services: [
      {
        serviceName: 'Oocyte Retreival',
        code: '58970'
      },
      {
        serviceName: 'Embryo Transfer',
        code: '58974'
      },
      {
        serviceName: 'Thaw of oocytes',
        code: '89356'
      },
      {
        serviceName: 'Cryopreservation of Embryos',
        code: '89258'
      },
      {
        serviceName: 'Sperm Prep complex',
        code: '89261'
      },
      {
        serviceName: 'Sperm Prep simple',
        code: '89260'
      },
      {
        serviceName: 'Prep tranfer of Cryo Sperm',
        code: '99499-1'
      },
      {
        serviceName: 'Thaw of Cryo Sperm',
        code: '89353'
      },
      {
        serviceName: 'Storage of embryos (per year)',
        code: '89324'
      },
      {
        serviceName: 'Storage of oocytes (per year)',
        code: '89346'
      },
    ]
  },

  {
    procedureName: 'FET',
    services: [
      {
        serviceName: 'Embryo Transfer',
        code: '58974'
      },
      {
        serviceName: 'Thaw of oocytes',
        code: '89356'
      },
      {
        serviceName: 'FET (Thaw Embryos)',
        code: '89352'
      },
      {
        serviceName: 'Cryopreservation of Embryos',
        code: '89258'
      },
      {
        serviceName: 'Storage of embryos (per year)',
        code: '89324'
      },
      {
        serviceName: 'Cryo embryo',
        code: '89258'
      },
      {
        serviceName: 'Storage Embryos',
        code: '89342'
      },
      {
        serviceName: 'Sperm Prep complex',
        code: '58974'
      },
      {
        serviceName: 'Sperm Prep simple',
        code: '89260'
      },
      {
        serviceName: 'Prep tranfer of Cryo Sperm',
        code: '99499-1'
      },
      {
        serviceName: 'Thaw of Cryo Sperm',
        code: '89353'
      },
    ]
  },

  {
    procedureName: 'Reciprocal IVF Cycle',
    services: [
      {
        serviceName: 'Oocyte Retreival',
        code: '58970'
      },
      {
        serviceName: 'Embryo Transfer',
        code: '58974'
      },
      {
        serviceName: 'Sperm Prep complex',
        code: '89261'
      },
      {
        serviceName: 'Sperm Prep simple',
        code: '89260'
      },
      {
        serviceName: 'Prep tranfer of Cryo Sperm',
        code: '99499-1'
      },
      {
        serviceName: 'Thaw of Cryo Sperm',
        code: '89353'
      },
     
    ]
  },
  
  {
    procedureName: 'Cryopreservation',
    services: [
      {
        serviceName: 'Oocyte Retreival',
        code: '58970'
      },
      {
        serviceName: 'Cryopreservation of Embryos',
        code: '89258'
      },
      {
        serviceName: 'Cryopreservation of Mature oocytes',
        code: '89337'
      },
      {
        serviceName: 'Cryo embryo',
        code: '89258'
      },
      {
        serviceName: 'Rcpt Cryo Embryo(s)',
        code: '99499-2'
      },
      {
        serviceName: 'Rcpt of Oocytes',
        code: '99499-3'
      },
      {
        serviceName: 'Storage oocytes',
        code: '89346'
      },
      {
        serviceName: 'Cryo sperm - ejaculate',
        code: '89259'
      },
      {
        serviceName: 'Cryo sperm - testicular',
        code: '89335'
      },
      {
        serviceName: 'Sperm Prep complex',
        code: '89261'
      },
      {
        serviceName: 'Sperm Prep simple',
        code: '89260'
      },
      {
        serviceName: 'Receipt of Cryo Sperm',
        code: '99499-1'
      },
      {
        serviceName: 'Storage of embryos (per year)',
        code: '89324'
      },
      {
        serviceName: 'Storage of oocytes (per year)',
        code: '89346'
      },
    ]
  },

  {
    procedureName: 'Donor Stimulated Cycle',
    services: [
      {
        serviceName: 'Oocyte Retreival',
        code: '58970'
      },
    ]
  },

  {
    procedureName: 'Donor Un Stimulated Cycle',
    services: [
      {
        serviceName: 'Oocyte Retreival',
        code: '58970'
      },
    ]
  },

  {
    procedureName: 'IUI',
    services: [
      {
        serviceName: 'Artificial Insemination (IUI)',
        code: '58322'
      },
      {
        serviceName: 'Artificial Insemination Double (IUI)',
        code: '58322'
      },
      {
        serviceName: 'Receipt of Cryo Sperm Day of IUI Additional Charge',
        code: '99499-7'
      }
    ]
  }
];