/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
 
import React, { useEffect, useState } from 'react';
import styles from './EmbryoCulture.module.css';
import { ReactComponent as EmbryoCultureImg } from '../../../assets/SVG/EmbryoCulture.svg';
import Grid from '@mui/material/Grid';
import { DayData, Embryo, getEmbryoCulturel, getPGTResult, PGTEmbryoData } from './EmbryoCultureHelper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useLoader } from '../../loaderProvider/loader';

interface EmbryoCultureProps {
  cycle: string;
}

const EmbryoCulture: React.FC<EmbryoCultureProps> = ({ cycle }) => {
  const { showLoader, hideLoader } = useLoader();
  const [dayData, setDayData] = useState<DayData[]>([]);
  const [cultureCount, setCultureCount] = useState(0);
  const [freezeCount, setFreezeCount] = useState(0);
  const [discardCount, setDiscardCount] = useState(0);
  const [transferCount, setTransferCount] = useState(0);

  
  useEffect(() => {
    const fetchData = () => {
      showLoader();
      getEmbryoCulturel(cycle)
        .then((response) => {
          console.log(response);
  
          const embryoData: Embryo[] = response.embryo;
          const actionCounts = embryoData.reduce(
            (counts, item) => {
              if (item.action === 'CULTURE') counts.CULTURE++;
              if (item.action === 'FREEZE') counts.FREEZE++;
              if (item.action === 'DISCARD') counts.DISCARD++;
              if (item.action === 'TRANSFER') counts.TRANSFER++;
              return counts;
            },
            { CULTURE: 0, FREEZE: 0, DISCARD: 0, TRANSFER: 0 }
          );
  
          setCultureCount(actionCounts.CULTURE);
          setFreezeCount(actionCounts.FREEZE);
          setDiscardCount(actionCounts.DISCARD);
          setTransferCount(actionCounts.TRANSFER);
          embryoData.sort((a, b) => a.day - b.day);
          type GroupedData = {
            [day: string]: {
              [grade: string]: string[]; 
            };
          };
          const groupedData = embryoData.reduce<GroupedData>((acc, item) => {
            const dayKey = `Day ${item.day}`;
            const gradeKey = item.grade || '-';
  
            if (!acc[dayKey]) {
              acc[dayKey] = {};
            }
            if (!acc[dayKey][gradeKey]) {
              acc[dayKey][gradeKey] = [];
            }
  
            acc[dayKey][gradeKey].push(
              item.embryo_number?.toString() || 'Unknown'
            );
            return acc;
          }, {});
          const formattedDayData = Object.keys(groupedData).map((dayKey) => {
            const dayEmbryos = groupedData[dayKey];
            const items = Object.keys(dayEmbryos).map((gradeKey) => {
              const embryos = dayEmbryos[gradeKey];
              return {
                circle: embryos.length,
                count: embryos.length,
                embryos: embryos.join(', '), 
                grade: gradeKey,
                text: `Grade: ${gradeKey}, Count: ${embryos.length}, Embryo Numbers: ${embryos.join(', ')}`,
              };
            });
  
            return {
              day: dayKey,
              items,
            };
          });
  
          setDayData(formattedDayData);
        })
        .catch((err) => {
          console.error('Error fetching embryo data:', err);
        })
        .finally(() => {
          hideLoader();
        });
    };
  
    fetchData();
  }, [cycle, showLoader, hideLoader]);
  
  const [data, setData] = useState<PGTEmbryoData[]>([]); 

  useEffect(() => {
    getPGTResult(cycle)
      .then((response: PGTEmbryoData[]) => {
        console.log('PGT Result Data:', response);
        setData(response);
      })
      .catch((error) => {
        console.error('Error fetching PGT result:', error);
      });
  }, [cycle]); 
  
  return (
    <div className={styles.container}>
      <Grid container spacing={3}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <EmbryoCultureImg 
                style={{
                  width: '60%',
                  height: '10%',
                  objectFit: 'cover',
                  display: 'block', 
                  margin: '0 auto'
                }} 
              />
            </Grid>
          </Grid>

          <div className='mt-4'>
    
      
            <TableContainer sx={{ width: '80%', margin: '0 auto' }}> 
              <span className={`${styles.daytext} mt-4`}>PGT Details</span>
              <Table className='mt-4'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><strong>Embryo No.</strong></TableCell>
                    <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><strong>Sex</strong></TableCell>
                    <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><strong>Karyotype</strong></TableCell>
                    <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><strong>Result</strong></TableCell>
                    <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}><strong>Mitosure</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item,) => (
                    <TableRow key={item.id}>
                      <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{item.embryo_number}</TableCell>
                      <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{item.pgt_result_embryo_sex}</TableCell>
                      <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{item.action}</TableCell>
                      <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{item.pgt_results}</TableCell>
                      <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{item.pgt_result_moisture}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </div>
        </Grid>


        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={styles.subcontainer}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className='mt-4'>
                <div className={styles.containerBOX}>
                  <div className={styles.box}></div>
                  <span className={styles.headerTextStyle}>
                  Freezed <span className={styles.customTextStyle}>{freezeCount}</span>
                  </span>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className='mt-4'>
                <div className={styles.containerBOX}>
                  <div className={styles.boxEmbryos}></div>
                  <span className={styles.headerTextStyle}>
                  Embryos Transferred <span className={styles.customTextStyle}>{transferCount}</span>
                  </span>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className='mt-4'>
                <div className={styles.containerBOX}>
                  <div className={styles.boxCulture}></div>
                  <span className={styles.headerTextStyle}>
                  Culture <span className={styles.customTextStyle}>{cultureCount}</span>
                  </span>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className='mt-4'>
                <div className={styles.containerBOX}>
                  <div className={styles.boxDiscard}></div>
                  <span className={styles.headerTextStyle}>
                  Discard <span className={styles.customTextStyle}>{discardCount}</span>
                  </span>
                </div>
              </Grid>

            </Grid>
          </Grid>
          {dayData
            .sort((a, b) => {
              const dayA = parseInt(a.day.replace('Day ', ''), 10);
              const dayB = parseInt(b.day.replace('Day ', ''), 10);
              return dayA - dayB;
            })
            .map((day, index) => (
              day.items.length > 0 && (
                <div key={index}>
                  <hr className="mt-4" />
                  <span className={styles.daytext}>{day.day}</span>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="mt-4">
                    <Grid container direction="row" spacing={2}>
                      {day.items.map((item, itemIndex) => (
                        <Grid item xs={12} sm={3} md={6} lg={3} key={itemIndex} className="mt-4">
                          <div className={styles.containerd}>
                            <div className={styles.circle}>  {item.grade === '-' ? '-' : item.circle}</div>
                            <div className={styles.text}>
                              {item.grade}
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </div>
              )
            ))}


        </Grid>
      </Grid>
    </div>
  );
};

export default EmbryoCulture;
