/* eslint-disable no-console */
import React, {useEffect, useMemo, useState} from 'react';
import { TextField, Grid, Tooltip, IconButton } from '@mui/material';
import addCircle from '../../assets/SVG/add-black.svg';
import styles from './DiagnosisHome.module.css';
import EditableTable from '../customTableComponent';
import { ColumnConfig, GroupConfig, TableRowData } from '../../types';
import InfoIcon from '@mui/icons-material/Info';
import AddDiagnosis from './AddDiagnosis';
import { useLoader } from '../loaderProvider/loader';
import {  deleteDig, getPatientDiagnosis } from './diagnosisHelper';
import { usePatientContext } from '../SubPatinetProvider';
import deleteBtn from '../../assets/SVG/trash.svg';
import { ConfirmationModal } from '../confirmationModal';
import { useAlert } from '../alertProvider';

const columns: ColumnConfig[] = [
  { field: 'date', headerName: 'Date', type: 'text', editable: false },
  { field: 'code', headerName: 'Code', type: 'text', editable: false },
  { field: 'name', headerName: 'Name', type: 'text', editable: false },
  { field: 'type', headerName: 'Type', type: 'text', editable: false },
  { field: 'doctor', headerName: 'Doctor', type: 'text', editable: false },
  { field: 'notes', headerName: 'Notes', type: 'text', editable: false },
  { field: 'action', headerName: '', type: 'text', editable: false },

];

const groupConfig: GroupConfig[] = [
  { fields: ['date'], type: 'column' },
  { fields: ['code'], type: 'column' },
  { fields: ['name'], type: 'column' },
  { fields: ['type'], type: 'column' },
  { fields: ['doctor'], type: 'column' },
  { fields: ['notes'], type: 'column' },
  { fields: ['action'], type: 'column' },

];

const DiagnosisHome: React.FC<{ onAddClick: () => void }> = ({ onAddClick }) => {
  const [data, setData] = useState<TableRowData[]>([]);
  const [dataSize, setDataSize] = useState<number>();
  const [showAddDiagnosis, setShowAddDiagnosis] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { patientId } = usePatientContext();
  const { showLoader, hideLoader } = useLoader();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); 
  const [selectedDigId, setselecteddigId] = useState<number | null>(null);
  const [IsdiagDeleted, setIsdiagDeleted] = useState(false);
  const { addAlert } = useAlert();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAction = (id: number) => {
  };

  const handleDelete = (id: number) => {
    setselecteddigId(id);
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    showLoader();
    getPatientDiagnosis(patientId)
      .then((res) => { 
        const tableData: TableRowData[] = res.objects.map((item) => {
          const createdOnDate = item.created_on ? new Date(item.created_on) : null;
          const isDisabled = createdOnDate
            ? (new Date().getTime() - createdOnDate.getTime()) / (1000 * 60 * 60) > 24
            : false;
        
          console.log(isDisabled);
            
          return {
            id: item.id,
            date: createdOnDate
              ? new Intl.DateTimeFormat('en-GB').format(createdOnDate)
              : '-',
            code: item.diagnosis_code,
            name: item.diagnosis_name,
            type: item.diagnosis_type,
            doctor: `Dr. ${item.doctor_name}`,
            notes: item.notes
              ? item.notes.length > 30 ? (
                <span
                  style={{
                    cursor: 'pointer',
                    display: 'inline-block',
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Tooltip title={item.notes}>
                    <span>
                      <InfoIcon fontSize="small" />
                    </span>
                  </Tooltip>{' '}
                  &nbsp;
                  {item.notes.substring(0, 45)}...
                </span>
              ) : (
                <span>{item.notes}</span>
              )
              : '-',
            action: (
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem',cursor: isDisabled ? 'not-allowed' : 'pointer'}}>
                <Tooltip title={isDisabled ? 'Cannot delete past 24 hours' : 'Delete'}>
                  <span>
                    <IconButton
                      onClick={() => !isDisabled && handleDelete(item.id)}
                      disabled={isDisabled}
                      style={{ color: isDisabled ? '#B0B0B0' : '#FF0000' }}
                      size="small"
                    >
                      <img src={deleteBtn} alt="delete" />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            ),
          };
        });
        
  
        setDataSize(res.meta.total_count);
        setData(tableData);
        
      })
      .catch((error) => {
        console.error('Error fetching patient diagnosis:', error);
      })
      .finally(() => {
        hideLoader();
        setIsdiagDeleted(false); 
      });
  // eslint-disable-next-line
  }, [patientId, showAddDiagnosis, showLoader, hideLoader,IsdiagDeleted]);

  const handleSubmit =()=>{
    showLoader();

    console.log('Deleting row with ID:', selectedDigId);
    if (selectedDigId) {
      deleteDig(selectedDigId).then(()=>{
        addAlert('Diagnosis deleted Successfully', 'success');
        setIsdiagDeleted(true);
      })
        .catch((error) => {
          console.error('Error deleting selected Drug', error);
          addAlert('Error deleting selected Drug', 'error');
          
        })
        .finally(() => {
          hideLoader();
        });
    }
  };

  const filteredData = useMemo(
    () => data.filter(
      (row) =>
        row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.name.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [searchTerm, data]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnChange = (id: number, field: string, value: string) => {

  };
  


  const handleAddClick = () => {
    setSelectedData(null);
    setShowAddDiagnosis(true);
    onAddClick();
  };
  // const handleAddClickEdit = () => {
  //   setShowAddDiagnosis(true);
  //   onAddClick();
  // };
  const handleBackClick = () => {
    setShowAddDiagnosis(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };


  return (
    <div className={`${styles.home} mt-4`} style={{ display: 'flex', flexDirection: 'column' }}>
      {showAddDiagnosis ? (
        <AddDiagnosis onBackClick={handleBackClick} data={selectedData} />
      ) : (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
              <TextField
                id="outlined-basic"
                label="Search "
                value={searchTerm}
                onChange={handleSearchChange}
                variant="outlined" fullWidth />
            </Grid>
            <Grid item xs />
            <Grid item xs={12} sm="auto">

              <button onClick={handleAddClick} style={  {padding: '5px 25px', border: 'none', borderRadius: '7px', fontSize:'larger', fontWeight:'700', backgroundColor:'#F3F3F3F3', color:'#505050' }}>
                <img style={{padding:'5px', width:'40px', height:'40px'}} src={addCircle} alt='add'/>   ADD
              </button>
            </Grid>
          </Grid>

          <div className="mt-4" style={{ flexGrow: 1 }}>
            <EditableTable
              columns={columns}
              data={filteredData}
              actionType="none"
              // onAction={handleAction}
              onChange={handleOnChange}
              onRowClick={handleAction}
              groupConfig={groupConfig}
              dataSize={dataSize}
            />
          </div>
        </>
      )}

      <ConfirmationModal
        open={openConfirmDialog}
        handleClose={()=>{setOpenConfirmDialog(false);}}
        onConfirm={() => {
          handleSubmit();
        }}
        type="save"
        title="Delete Diagnosis"
        contentMessage='Are you sure you want to delete prescribed Diagnosis?'
      />
    </div>
  );
};

export default DiagnosisHome;
