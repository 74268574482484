// Purpose: Contains functions that return URLs for the API.
export function getUserUrl(name: string): string {
  return `/user/${name}`;
}
export function loginUserUrl(): string {
  return 'login/';
}
export function postNewRegistrationUrl(): string {
  return 'patients/';
}

export function getPatientApiUrl(): string {
  return 'patients/';
}
export function getPatientApiAndroUrl(): string {
  return 'patient-procedure/andrology/';
}

export function geGenderUrl(): string {
  return 'genders/';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLinkPartnerUrl(patientId: any): string {
  return `/patients/${patientId}/link-partner/`;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDelinkUrl(patientId: any): string {
  return `/patients/${patientId}/delink-partner/`;
}

export function getcountryUrl(): string {
  return 'https://countriesnow.space/api/v0.1/countries';
}

export function getStateUrl(): string {
  return 'https://countriesnow.space/api/v0.1/countries/states';
}
export function getPatientApiUrlById(id: string): string {
  return `patients/${id}`;
}

export function getMyProfileUrl(): string {
  return 'me/profile';
}

export function getAdminUrl(): string {
  return 'me/admin_token';
}

export function getUploadURL(): string {
  return 'uploads/';
}

export function getcountrycodeUrl(): string {
  return 'https://countriesnow.space/api/v0.1/countries/codes';
}


export function getDoctorsList(): string {
  return 'doctors/';
}

export function departmetnList(): string {
  return 'departments/';
}

export function getClinicsUrl(): string {
  return 'clinics/';
}

export function getClinicByIdUrl(id: string): string {
  return `clinics/${id}`;
}

export function get_appointment_reasons(): string {
  return 'appointment_reasons/';
}

export function get_users(): string {
  return 'users/';
}

export function get_users_clinic(id: string, dep: string): string {
  return `users/?clinic=${id}&limit=100&department=${dep}`;
}

export function get_users_clinic_only(id: string): string {
  return `users/?clinic=${id}&limit=1000`;
}

export function appointments(): string {
  return 'appointments/';
}

export function addSexualHistoryURL(id: number): string {
  return `patients/${id}/sexual-history/`;
}

export function getSocialHistoryURL(id: number): string {
  return `patients/${id}/social-history/`;
}

export function addSocialHistoryURL(id: number): string {
  return `patients/${id}/social-history/`;
}
export function getappointmentsUrl(): string {
  return 'appointments/';
}
export function status(): string {
  return 'status/';
}

export function getappointmentallergysUrl(patientId: number): string {
  return `/patients/${patientId}/allergy/`;
}

export function putappointmentallergysUrl(patientId: number): string {
  return `/patients/${patientId}/allergy/`;
}

export function getappointmentaddictionsUrl(patientId: number): string {
  return `/patients/${patientId}/addiction/`;
}

export function putappointmentaddictionsUrl(patientId: number): string {
  return `/patients/${patientId}/addiction/`;
}


export function getappointmentpreviousinvestigationhistoryUrl(patientId: number): string {
  return `/patients/${patientId}/previous-investigation-history/`;
}

export function putappointmentpreviousinvestigationhistoryUrl(patientId: number): string {
  return `/patients/${patientId}/previous-investigation-history/`;
}


export function getputpriorinfertilitytestingUrlUrl(patientId: number): string {
  return `/patients/${patientId}/prior-infertility-testing/`;
}

export function putpriorinfertilitytestingUrl(patientId: number): string {
  return `/patients/${patientId}/prior-infertility-testing/`;
}
export function getputpriorinfertilitytreartmentUrl(patientId: number): string {
  return `/patients/${patientId}/prior-infertility-treatment-cycle/`;
}

export function putMainMedicaHistoryURL(patientId: number): string {
  return `/patients/${patientId}/basic-clinical-profile/`;
}

export function getMainMedicalHistoryURL(patientId: number): string {
  return `/patients/${patientId}/basic-clinical-profile/`;
}
export function getfamilyhistoryUrl(patientId: number): string {
  return `/patients/${patientId}/family-history/`;
}

export function putfamilyhistoryUrl(patientId: number): string {
  return `/patients/${patientId}/family-history/`;
}
export function getvaccinationUrl(patientId: number): string {
  return `/patients/${patientId}/vaccination/`;
}

export function getpastmedicationhistoryUrl(patientId: number): string {
  return `/patients/${patientId}/past-medication-history/`;
}



export function getmedicalhistoryUrl(patientId: number): string {
  return `/patients/${patientId}/medical-history/`;
}


export function previousVisitLogs(patientId: number  | null = null): string {
  return `/visit-logs/?patient=${patientId}`;
}

export function departmentRooms(query = ''): string {
  return `/rooms${query ? `?${query}` : ''}`;
}


export function operationTheaterTables(query = ''): string {
  return `/tables${query ? `?${query}` : ''}`;
}

export function getWorkingHoursURL() {
  return '/appointments/';
}

export function getDepartmentsURL() {
  return '/departments/';
}
export function getputgeneralsystemexaminationURL(patientId: number): string {
  return `/patients/${patientId}/general-system-examination/`;
}

export function getputurogenitalexaminationURL(patientId: number): string {
  return `/patients/${patientId}/uro-genital-examination/`;
}

export function getputinfertilityassessmentURL(patientId: number): string {
  return `/patients/${patientId}/infertility-assessment/`;
}
export function getputvitalURL(patientId: number): string {
  return `/patients/${patientId}/vital/`;
}


export function postpastillnessUrl() {
  return '/past-illness/';
}
export function getpastillnessUrl(patient_id: number): string {
  return `/past-illness/?patient_id=${patient_id}`;
}

export function getputpastillnessUrl(id: number): string {
  return `/past-illness/${id}`;
}

export function getputSurgicalHistoryURL(patientId: number): string {
  return `/patients/${patientId}/surgical-history/`;
}

export function getLabTestsURL(query:string):string{
  return `/laboratory-test/${query}`;
}

export function getLabTestsCatsURL(query:string):string{
  return `/laboratory-test-category/${query}`;
}

export function addinvestigationURL(query:string):string{
  return `/investigation/${query}`;
}

export function getPutDiagnosisURL(query:string):string{
  return `/diagnosis/${query}`;
}

export function getDoctorsURL(): string {
  return '/users/?role=DOCTOR';
}

export function postDiagnosisURL():string{
  return '/patient-diagnosis/';
}

export function getDiagnosisURL(id:number):string{
  return `/patient-diagnosis/?patient_id=${id}`;
}
export function deleteDiagnosisURL(id:number):string{
  return `/patient-diagnosis/${id}`;
}
export function deleteInvestigationURL(id:number):string{
  return `/investigation/${id}`;
}


export function get_users_by_clinic(query: string): string {
  return `users/?${query}`;
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getProceduresURL(query:any):string{
  return `/patient-procedure/${query}`;
}



export function getOocyteUploadURL(): string {
  return 'oocyte/upload-images/';
}


export function getEmbryoUploadURL(): string {
  return 'embryo/upload-images/';
}
