import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Route, createRoutesFromElements, Navigate } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import NoLayout from './layouts/NoLayout';
import DefaultLoader from './components/defaultLoader';
import { CalendarPage } from './pages/calendarPage';
import CalendarLoader from './components/calendarComponent/calanderLoader';
import InvestigationDiag from './pages/Clinical_Profile/Investigation_Diagnosis/Investigation_diagnosis.index';
import Test from './pages/test';
import TreatmentPlanPage from './pages/TreatmentPlanPage';
import SurgeryPage from './pages/Surgery';
import CycleOverview from './pages/CycleOverview/CycleOverview';
import StimPage from './pages/Stim';
import TestModals from './pages/testModals';
import Andrology from './pages/Andrology/Andrology';
import { RedirectLogin } from './pages/login/index';
import EmbryoCulture from './pages/Embryology/Embryo_Culture/embryoCulture';
import OocyteCryoStrorage from './pages/cryoStorage/oocyteCryo/oocyteCryoStrorage';
import EmbryoStorage from './pages/cryoStorage/embryoCryo/EmbryoStorage';
import SpermStorage from './pages/cryoStorage/spermCryo/spermStorage';




// Lazy loading components
const Examination = lazy(() => import('./pages/Clinical_Profile/Examination/Examination'));
const Login = lazy(() => import('./pages/login/index'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const HPI = lazy(() => import('./pages/Clinical_Profile/HPI/Hpi'));
const RegistrationPage = lazy(() => import('./pages/Registration/index'));
const NewRegistrationPage = lazy(() => import('./pages/Registration/NewRegistration/index'));
const PatientPartnerRegistrationPage = lazy(() => import('./pages/Registration/NewRegistration/PatientPartnerRegistration/index'));
const BillingInformationPage = lazy(() => import('./pages/Registration/NewRegistration/BillingInformation/index'));
const PatinetView = lazy(() => import('./pages/patientView/index'));
const ClinicalPatientList = lazy(() => import('./pages/Clinical_Profile/patinetProfile.index'));
const MedicalHistory = lazy(() => import('./pages/Clinical_Profile/MedicalHistory/medicaHistory.index'));
const ViewStockPage = lazy(() => import('./pages/Stock/ViewStock'));
const AddStockPage = lazy(() => import('./pages/Stock/AddStock/index'));
const ConsumeStockPage = lazy(() => import('./pages/Stock/ConsumeStock/index'));
const StockTransferPage = lazy(() => import('./pages/StockTransfer/index'));
const IndentPage = lazy(() => import('./pages/StockTransfer/Indent/index'));
const NewIndentPage = lazy(() => import('./pages/StockTransfer/Indent/NewIndent'));
const Issued = lazy(() => import('./pages/StockTransfer/Issue/Issued/index'));
const NewIssuePage = lazy(() => import('./pages/StockTransfer/Issue/NewIssue'));
const Received = lazy(() => import('./pages/StockTransfer/Receive/Received/index'));
const IssueProspective = lazy(() => import('./pages/StockTransfer/Issue/Prospective/index'));
const ReceiveProspective = lazy(() => import('./pages/StockTransfer/Receive/Prospective/index'));
const SemenThawing = lazy(() => import('./pages/SemenThawing/SemenThawing'));
const PurchaseViewPage = lazy(()=> import ('./pages/Purchase/index'));
const RequisitionViewPage =lazy(()=>import('./pages/Purchase/Requisition/index'));
const NewRequisition =lazy(()=>import('./pages/Purchase/Requisition/New'));
const NewPurchaseOrder =lazy(()=>import('./pages/Purchase/PurchaseOrder/New'));
const PurchaseOrderPerspectiveList =lazy(()=>import('./pages/Purchase/PurchaseOrder/Prospective/index'));
const AddPurchaseOrderProspective = lazy(()=>import('./pages/Purchase/PurchaseOrder/Ordered/create'));
const PurchaseOrderOrderedList =lazy(()=>import('./pages/Purchase/PurchaseOrder/Ordered/index'));
const EmbryoPatientList =lazy(()=>import('./pages/Embryology/patientList'));
const OocyteGrading =lazy(()=>import('./pages/Embryology/Occyte-Grading/occyteGrading.index'));
const SemenAnalysisDetails =lazy(()=>import('./pages/SemenAnalysis/SemenAnalysisDetails'));
const Collectiondetails =lazy(()=>import('./components/SemanAnalysisComponent/SemanAnalysisComponent'));
const GrnViewPage = lazy(()=>import('./pages/Grn/index'));
const ProspectiveViewPage = lazy(()=>import('./pages/Grn/Prospective/index'));
const ProspectiveAddPage = lazy(()=>import('./pages/Grn/Prospective/add'));
const ProspectiveNewPage = lazy(()=>import('./pages/Grn/Prospective/new'));
const ReceivedViewPage = lazy(()=>import('./pages/Grn/Received/index'));
const ReceivedItemViewPage = lazy(()=>import('./pages/Grn/Received/view'));
const SemenFreezing = lazy(()=>import('./pages/SemenFreezing/SemenFreezing'));
const SemenProcessing =lazy(()=>import('./pages/SemenProcessing/SemenProcessing'));
const CryoThawing =lazy(()=>import('./pages/CryoThawing/CryoThawing'));
const SemenIUI =lazy(()=>import('./pages/SemenIUI/SemenIUI'));
const PatientAppointment = lazy(()=>import('./components/AppointmentTable/patientAppointment'));


// Define the routes using createRoutesFromElements
const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<Navigate to='/login' replace />} />
      {/* Public Route for Login */}
      <Route
        path='/login'
        element={
          <Suspense fallback={<DefaultLoader />}>
            <NoLayout>
              <Login />
            </NoLayout>
          </Suspense>
        }
      />

      <Route
        path='/forgot-password'
        element={
          <Suspense fallback={<DefaultLoader />}>
            <NoLayout>
              <ForgotPassword />
            </NoLayout>
          </Suspense>
        }
      />

      {/* Main Routes with Layout */}
      <Route
        path='/'
        element={<MainLayout />} // MainLayout wraps routes with header and sidebar
      >
        {/* Test Routes */}
        <Route
          path='test'
          element={
            <Suspense fallback={<DefaultLoader />}>
              <Test />
            </Suspense>
          }
          handle={{ breadcrumb: 'Test' }}
        >
          <Route
            path='emp/:id'
            element={
              <Suspense fallback={<DefaultLoader />}>
                <HPI />
              </Suspense>
            }
            handle={{ breadcrumb: ({ id }: { id: string }) => `emp ${id}` }}
          />
        </Route>

        <Route
          path='testModals'
          element={
            <Suspense fallback={<DefaultLoader />}>
              <TestModals />
            </Suspense>
          }
          handle={{ breadcrumb: ({ id }: { id: string }) => `emp ${id}` }}
        />
        {/* Admin Routes */}
        <Route
          path='admin'
          handle={{ breadcrumb: 'Admin' }}
          hasErrorBoundary
        >
          <Route index element={<Navigate to='appointments/' replace />} />

          <Route
            path='appointments/'
            element={
              <Suspense fallback={<CalendarLoader />}>
                <CalendarPage />
              </Suspense>
            }
            handle={{ breadcrumb: 'Appointments' }}
          />
          <Route
            path='appointments/patient/:id'
            element={
              <Suspense fallback={<CalendarLoader />}>
                <PatientAppointment />
              </Suspense>
            }
            handle={{ breadcrumb: 'Appointments' }}
          />
          <Route
            path='registration/'
            handle={{ breadcrumb: 'Registration' }}
          >
            <Route
              index
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <RegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path='patient/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PatinetView />
                </Suspense>
              }
              handle={{ breadcrumb: ({ id }: { id: string }) => `Patient #${id}` }}
            />
            <Route
              path='patient-list'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <RegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path='new-registration'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <NewRegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'New Registration' }}
            />
            <Route
              path='patient-partner-registration'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PatientPartnerRegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'New Registration' }}
            />
            <Route
              path='patient-partner-registration-update'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PatientPartnerRegistrationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Update Patient' }}
            />
            <Route
              path='billing-information'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <BillingInformationPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Billing Information' }}
            />
          </Route>
          {/* Inventory Routes */}
          <Route
            path = 'inventory'
            handle={{ breadcrumb: 'Inventory' }}
          >
            {/* Stock Routes */}
            <Route
              path='stock'
              handle={{ breadcrumb: 'Stock' }}
            >
              <Route
                index
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <ViewStockPage />
                  </Suspense>
                }
                handle={{ breadcrumb: 'View' }}
              />
              <Route
                path='add'
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <AddStockPage />
                  </Suspense>
                }
                handle={{ breadcrumb: 'Add' }}
              />
              <Route
                path='consume'
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <ConsumeStockPage />
                  </Suspense>
                }
                handle={{ breadcrumb: 'Material Consumption' }}
              />
            </Route>
            {/* Stock Transfer Routes */}
            <Route
              path='stock-transfer'
              handle={{ breadcrumb: 'Stock Transfer' }}
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <StockTransferPage />
                </Suspense>
              }
            >
              <Route
                index
                element={<Navigate to="indent" replace />}
              />
              <Route
                path='indent'
                handle={{ breadcrumb: 'Indent' }}
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <IndentPage />
                  </Suspense>
                }
              >
                <Route
                  path='new'
                  handle={{ breadcrumb: 'New' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <NewIndentPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="issue"
                handle={{ breadcrumb: 'Issue' }}
                element={<Navigate to="issue/prospective" replace />}
              >
                <Route
                  path="prospective"
                  handle={{ breadcrumb: 'Prospective' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <IssueProspective />
                    </Suspense>
                  }
                />
                <Route
                  path="issued"
                  handle={{ breadcrumb: 'Issued' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <Issued />
                    </Suspense>
                  }
                />
                <Route
                  path='new'
                  handle={{ breadcrumb: 'New' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <NewIssuePage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="receive"
                handle={{ breadcrumb: 'Receive' }}
                element={<Navigate to="receive/prospective" replace />}
              >
                <Route
                  path="prospective"
                  handle={{ breadcrumb: 'Prospective' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <ReceiveProspective />
                    </Suspense>
                  }
                />
                <Route
                  path="received"
                  handle={{ breadcrumb: 'Received' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <Received />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* Purchase Requisition Routes */}
            <Route
              path='purchase'
              handle={{ breadcrumb: 'Purchase' }}
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <PurchaseViewPage />
                </Suspense>
              }
            >
              <Route
                index
                element={<Navigate to='requisition' replace/>}
              />
              <Route
                path='requisition'
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <RequisitionViewPage />
                  </Suspense>
                }
                handle={{ breadcrumb: 'Requisition' }}
              >
                <Route
                  path='new'
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <NewRequisition />
                    </Suspense>
                  }
                  handle={{ breadcrumb: 'New' }}
                />
              </Route>
              <Route
                path='order'
                handle={{breadcrumb:'Purchase Order'}}
                element={<Navigate to="order/prospective" replace />}
              >
                <Route
                  path='prospective'
                  handle={{ breadcrumb: 'Prospective' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <PurchaseOrderPerspectiveList />
                    </Suspense>
                  }
                />
                <Route
                  path='create'
                  handle={{ breadcrumb: 'Create Order' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <AddPurchaseOrderProspective />
                    </Suspense>
                  }
                />
                <Route
                  path='ordered'
                  handle={{ breadcrumb: 'Ordered' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <PurchaseOrderOrderedList />
                    </Suspense>
                  }
                />
                <Route
                  path='new'
                  handle={{ breadcrumb: 'New Order' }}
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <NewPurchaseOrder />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/*GRN View Routes  */}
            <Route
              path='grn'
              handle={{ breadcrumb: 'GRN' }}
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <GrnViewPage />
                </Suspense>
              }
            >
              <Route
                index
                element={<Navigate to='prospective' replace/>}
              />
              <Route
                path='prospective'
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <ProspectiveViewPage />
                  </Suspense>
                }
                handle={{ breadcrumb: 'Prospective' }}
              >
                <Route
                  path='new'
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <ProspectiveNewPage />
                    </Suspense>
                  }
                  handle={{ breadcrumb: 'New' }}
                />
                <Route
                  path='add'
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <ProspectiveAddPage />
                    </Suspense>
                  }
                  handle={{ breadcrumb: 'Add' }}
                />
              </Route>
              <Route

                element={<Navigate to='received' replace/>}
              />
              <Route
                path='received'
                element={
                  <Suspense fallback={<DefaultLoader />}>
                    <ReceivedViewPage />
                  </Suspense>
                }
                handle={{ breadcrumb: 'Received' }}
              >
                <Route
                  path='view'
                  element={
                    <Suspense fallback={<DefaultLoader />}>
                      <ReceivedItemViewPage />
                    </Suspense>
                  }
                  handle={{ breadcrumb: 'View' }}
                />
              </Route>
            </Route>
          </Route>
        </Route >
        <Route
          path='clinical'
          handle={{ breadcrumb: 'Clinical' }}
        >
          <Route index element={<Navigate to='patients/' replace />} />
          <Route
            path='patients'
            handle={{ breadcrumb: 'Patients' }}
          >
            <Route
              index
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <ClinicalPatientList />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path='medical-history/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <MedicalHistory />
                </Suspense>
              }
              handle={{ breadcrumb: 'Medical History' }}
            />

            <Route
              path='examination/:id'
              element={
                <Suspense >
                  <Examination />
                </Suspense>
              }
              handle={{ breadcrumb: 'Examination' }}
            />

            <Route
              path='hpi/:id'
              element={
                <Suspense >
                  <HPI />
                </Suspense>
              }
              handle={{ breadcrumb: 'HPI' }}
            />
            <Route
              path='investigation/:id'
              element={
                <Suspense >
                  <InvestigationDiag />
                </Suspense>
              }
              handle={{ breadcrumb: 'Investigation' }}
            />
            <Route
              path='stimulation/:id'
              element={
                <Suspense >
                  <StimPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Stimulation' }}
            />
            <Route
              path='cycle-overview/:id'
              element={
                <Suspense >
                  <CycleOverview />
                </Suspense>
              }
              handle={{ breadcrumb: 'Cycle Overview' }}
            />

            <Route
              path='treatment-plan/:id'
              element={
                <Suspense >
                  <TreatmentPlanPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Treatment Plan' }}
            />

            <Route
              path='surgery/:id'
              element={
                <Suspense >
                  <SurgeryPage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Surgery' }}
            />
          </Route>
        </Route>
        {/* Add more routes here */}


        <Route
          path='lab'
          handle={{ breadcrumb: 'Lab' }}>
          <Route index element={<Navigate to='embryology/' replace />} />
          <Route
            path='embryology'
            handle={{ breadcrumb: 'Embryology' }}>
            <Route
              index
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <EmbryoPatientList/>
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path='occyte-grading/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <OocyteGrading />
                </Suspense>
              }
              handle={{ breadcrumb: 'Occyte Grading' }}
            />

            <Route
              path='embryo-culture/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <EmbryoCulture />
                </Suspense>
              }
              handle={{ breadcrumb: 'Embryo Culture' }}
            />

            <Route
              path='cryo-thawing/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <CryoThawing />
                </Suspense>
              }
              handle={{ breadcrumb: 'Cryo Thawing' }}
            />
          </Route>
          <Route
            path='andrology'
            handle={{ breadcrumb: 'Andrology' }}
          >
            <Route
              index
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <Andrology />
                </Suspense>
              }
              handle={{ breadcrumb: 'Patient List' }}
            />
            <Route
              path='semen-analysis/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <SemenAnalysisDetails />
                </Suspense>
              }
              handle={{ breadcrumb: 'Semen Analysis' }}
            />
            <Route
              path='semen-analysis/collection-details/:id/:analysisId'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <Collectiondetails />
                </Suspense>
              }
              handle={{ breadcrumb: 'Collection Details' }}
            />
            <Route
              path='semen-processing/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <SemenProcessing />
                </Suspense>
              }
              handle={{ breadcrumb: 'Semen Processing' }}
            />
            <Route
              path='semen-freezing/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <SemenFreezing />
                </Suspense>
              }
              handle={{ breadcrumb: 'Semen Freezing' }}
            />
            <Route
              path='semen-thawing/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <SemenThawing />
                </Suspense>
              }
              handle={{ breadcrumb: 'Semen Thawing' }}
            />
            
            <Route
              path='IUI/:id'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <SemenIUI />
                </Suspense>
              }
              handle={{ breadcrumb: 'Semen Thawing' }}
            />

            
          </Route>


          {/*  */}

          <Route
            path='cryostorage'
            handle={{ breadcrumb: 'Cryostorage' }}
          >
            <Route
              index
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <OocyteCryoStrorage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Oocyte' }}
            />
            <Route
              path='oocyte'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <OocyteCryoStrorage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Oocyte' }}
            />
            <Route
              path='embryo'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <EmbryoStorage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Embryo' }}
            />

            <Route
              path='sperm'
              element={
                <Suspense fallback={<DefaultLoader />}>
                  <SpermStorage />
                </Suspense>
              }
              handle={{ breadcrumb: 'Sperm' }}
            />
            
          </Route>
          {/*  */}
        </Route>
        <Route path="redirect_login" element={<RedirectLogin />} />


      </Route>
    </>
  )
);

export default routes;
