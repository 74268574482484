import React, {useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  Pagination,
  createTheme,
  ThemeProvider,
} from '@mui/material';
// import { Delete as DeleteIcon } from '@mui/icons-material';
import { HighlightOff as DeleteIcon } from '@mui/icons-material';
import { ColumnConfig, ActionType, GroupConfig, TableRowData } from '../../types';
import styles from './CustomTableComponent.module.css';
import { ConfirmationModal } from '../confirmationModal';
import StatusChip from './statusChip';

interface EditableTableProps {
  columns: ColumnConfig[];
  data: TableRowData[];
  actionType: ActionType;
  onAction?: (id: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (id: number, field: string, value: any) => void;
  groupConfig?: GroupConfig[];
  dataSize?: number;
  onRowClick?: (id: number) => void;
  onPageChange?: (page: number) => void;
  currentPageoffset?: number; 
  getAllRowData?: (row: TableRowData) => void; 
  noDataText?:string;
}

const EditableTable: React.FC<EditableTableProps> = ({
  columns,
  data,
  actionType,
  onChange,
  onAction,
  groupConfig,
  dataSize,
  onRowClick,
  getAllRowData,
  onPageChange ,
  currentPageoffset = 1,
  noDataText
}) => {
  
  const theme = createTheme({ 
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '10px', 
          },
        },
      },
    },
  });


  const rowsPerPage = 10; // Number of rows per page
  const [page, setPage] = useState<number>(currentPageoffset - 1);
  const [tableData, setTableData] = useState<TableRowData[]>(data);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const totalPages = dataSize ? Math.ceil(dataSize / rowsPerPage) : 0;

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    const adjustedPage = newPage - 1; // Adjust page for zero-based index
    setPage(adjustedPage);
    onPageChange?.(newPage); 

  };

    
  const handleInputChange = (
    id: number,
    field: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      )
    );
    onChange?.(id, field, value);
  };

  const renderCell = (
    row: TableRowData,
    column: ColumnConfig
  ) => {
    const value = row[column.field];

    if (!column.editable) {
      if (column.type === 'text' || column.type === 'int') {
        if (column.showLabel && !column.hideLabel) {
          return <span>{column.headerName} : {value}</span>;
        } else {
          return <div className={styles.tb_main_text}>{value}</div>;
        }
      }
      if (column.type === 'component' && React.isValidElement(value)) {
        return value;
      }
      if (column.showLabel  && !column.hideLabel) {
        return <span>{column.headerName} : {value}</span>;
      }
      else {
        return <div className={styles.tb_main_text}>{value}</div>;
      }
    }

    const renderWithLabel = (inputElement: React.ReactNode) => (
      <div className={styles.tb_main_text}>
        {inputElement}
      </div>
    );


    switch (column.type) {
    case 'text':
    case 'int':
      return renderWithLabel(
        <TextField
          sx={{ borderRadius: '12px', '& .MuiOutlinedInput-root': { borderRadius: '12px' } , }}
          variant="outlined"
          type={column.type === 'int' ? 'number' : 'text'}
          value={value}
          label={column.hideLabel ? undefined : column.headerName}
          onChange={(e) =>
            handleInputChange(row.id, column.field, e.target.value)
          }
        />
      );
    case 'select':
      return renderWithLabel(
        <FormControl>
          {/* Only render InputLabel if hideLabel is false */}
          {!column.hideLabel && (
            <InputLabel id={`select-${column.field}`} shrink={true}>
              {column.headerName}
            </InputLabel>
          )}
          <Select
            sx={{
              borderRadius: '12px',
              minWidth: '3rem',
              maxWidth:'9rem',
              '& .MuiOutlinedInput-root': { borderRadius: '12px' }
            }}
            value={value}
            onChange={(e) =>
              handleInputChange(row.id, column.field, e.target.value)
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '20rem',
                  overflowY: 'auto',
                },
              },
            }}
            label={column.hideLabel ? undefined : column.headerName} // Ensure the label is also set correctly here
          >
            {column.options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      
    case 'date':
      return renderWithLabel(
        <TextField
          className={styles.tb_main_text}
          sx={{ borderRadius: '12px', '& .MuiOutlinedInput-root': { borderRadius: '12px' } }}
          label={column.hideLabel ? undefined : column.headerName}
          variant="outlined"
          type="date"
          value={value}
          onChange={(e) =>
            handleInputChange(row.id, column.field, e.target.value)
          }
        />
      ); 
    
    case 'chip':       
      if (column.field === 'status') {
        return <StatusChip value={value} />; 
      }
      return null;
    case 'component':
      return renderWithLabel(React.isValidElement(value) ? value : null);
    default:
      return null;
    }
  };

  const renderActionCell = (rowId: number) => {
    if (actionType === 'select') {
      return <Checkbox />;
    }
    if (actionType === 'delete') {
      return (
        <TableCell sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            sx={{ color: '#CD1313', border: 'none' }}
            onClick={() => {
              setDeleteId(rowId); 
              setOpenModal(true); 
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      );
    }
    return null;
  };
  const handleConfirmDelete = () => {
    if (deleteId !== null) {
      onAction?.(deleteId); 
      setDeleteId(null); 
      setOpenModal(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false); 
    setDeleteId(null); 
  };

  const renderGroupedCells = (
    row: TableRowData,
    group: GroupConfig
  ) => {

    const groupedContent = group.fields.map((field) =>
      renderCell(row, columns.find((col) => col.field === field)!)
    );

    if (group.type === 'row') {
      return (
        <TableCell colSpan={group.fields.length} >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {groupedContent.map((content, index) => (
              <span key={index}>{content}</span>
            ))}
          </div>
        </TableCell>
      );
    }

    if (group.type === 'column') {
      return (
        <TableCell sx={{ width: 'auto', maxWidth: '150px' }}>
          <div style={{ display: 'flex', flexDirection: 'column',  justifyContent: 'space-evenly', minHeight: '50px', padding: '0px'}}>
            {groupedContent.map((content, index) => (
              <div key={index}>{content}</div>
            ))}
          </div>
        </TableCell>
      );
    }

    return null;
  };
  useEffect(() => {
    setTableData(data);
    setPage(currentPageoffset-1);
  }, [data, currentPageoffset]);



  return (
    <ThemeProvider theme={theme}>
      <div>
        <TableContainer>
          <Table className={styles.custom_table}>
            <TableHead>
              <TableRow className={styles.custom_table_head}>
                {groupConfig ? (
                  groupConfig.map((group, index) => (
                    <TableCell key={index}>
                      {group.defaultHeaderGroupname? group.defaultHeaderGroupname: group.fields.map(field => columns.find(col => col.field === field)?.headerName).join(' | ')}
                    </TableCell>
                  ))
                ) : (
                  columns.map((column) => (
                    <TableCell key={column.field}>{column.headerName}</TableCell>
                  ))
                )}                
                {actionType !== 'none' && <TableCell sx={{ width: '20px' }}></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length+1 || 1} align="center" sx={{Padding:'20px', fontSize:'1rem'}}>
                    {noDataText ? noDataText : 'No data found'}
                  </TableCell>
                </TableRow>
              ) : (
                tableData.map((row) => (
                  <TableRow
                    key={row.id}
                    className={styles.custom_table_body}
                    onClick={() => {
                      onRowClick?.(row.id);
                      if (getAllRowData) {
                        getAllRowData(row);
                      }
                    }}
                  >
                    {groupConfig ? (
                      groupConfig.map((group, index) => (
                        <React.Fragment key={index}>
                          {renderGroupedCells(row, group)}
                        </React.Fragment>
                      ))
                    ) : (
                      columns.map((column) => (
                        <TableCell key={column.field}>{renderCell(row, column)}</TableCell>
                      ))
                    )}
                    {actionType !== 'none' && (
                      <TableCell>
                        {row.status === 'Active' && renderActionCell(row.id)}
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}


            </TableBody>
          </Table>
        </TableContainer>



        {(dataSize ?? 0) > 0 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
            <p>
  Showing {(page * 10) + 1} to{' '}
              {Math.min((page + 1) * 10, dataSize ?? 0)} of {dataSize ?? 0} entries
            </p>
            <Pagination
              sx={{
                width: 'auto',
                '& .MuiPaginationItem-root': {
                  width: '40px',
                  height: '32px',
                  fontSize: '14px',
                  borderRadius: '8px', 
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: 'rgba(119, 119, 119, 1)',
                  color: 'white',
                  borderRadius: '8px',
                },
              }}
              count={totalPages} 
              onChange={handleChangePage}
              siblingCount={0}
              boundaryCount={1}
              shape="rounded"
            />
          </div>
        )}
        {/* Confirmation Modal */}
        <ConfirmationModal
          open={openModal}
          handleClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          type="delete" 
          title="Delete"
          contentMessage={'Are you sure you want to delete this information?'} 
        />
      </div>
    </ThemeProvider>
  );
};

export default EditableTable;