/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styles from './Fertilization.module.css';
import { ReactComponent as ICSIImg } from '../../../assets/SVG/ICSI.svg';
import { ReactComponent as IUIImg } from '../../../assets/SVG/IUI.svg';
import { ReactComponent as IVFImg } from '../../../assets/SVG/IVF.svg';
import Grid from '@mui/material/Grid';
import { getEmbryoCulture, OocyteProcessing } from './FertilizationHelper';

interface FertilizationProps {
  cycle: string;
}

const Fertilization: React.FC<FertilizationProps> = ({ cycle }) => {
  const [oocyteData, setOocyteData] = useState<OocyteProcessing[] | null>(null);

  useEffect(() => {
    getEmbryoCulture(cycle)
      .then((data) => {
        setOocyteData(data); 
      })
      .catch((error) => {
        console.error('Error fetching Embryo Culture Data:', error);
      });
  }, [cycle]);


  const groupOocytesByGrade = (oocytes: any[]) => {
    return oocytes.reduce((acc: { [key: string]: number }, oocyte) => {
      const grade = oocyte.grade;
      if (!acc[grade]) {
        acc[grade] = 0;
      }
      acc[grade]++;
      return acc;
    }, {});
  };

  return (
    <div className={styles.container}>
      <Grid container spacing={3}>
        {/* First column with images */}
        <Grid xs={12} sm={12} md={12} lg={12} xl={6}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <ICSIImg
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '12.5rem',
                  objectFit: 'cover'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <IUIImg
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '12.5rem',
                  objectFit: 'cover'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <IVFImg
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '12.5rem',
                  objectFit: 'cover'
                }}
              />
            </Grid>
          </Grid>
        </Grid>


        {/* Second column with data */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Total oocyte Retrieved</strong>
                {oocyteData &&
                  oocyteData.map((oocyte, index) => (
                    <p key={index} className={styles.customTextStyle}>{oocyte.oocyte_count}</p>
                  ))
                }
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Oocyte Source</strong>
                {oocyteData &&
                  oocyteData.map((oocyte, index) => (
                    <p key={index} className={styles.customTextStyle}>{'Self'}</p>
                  ))
                }
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Sperm Source</strong>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <div className={styles.textField}>
                    <strong className={styles.headerTextStyle}>Sperm Source</strong>
                    {oocyteData && oocyteData.length > 0 && oocyteData[0].oocytes.length > 0 ? (
                      <p className={styles.customTextStyle}>
                        {oocyteData[0].oocytes[0]?.sperm_source || '-'}
                      </p>
                    ) : (
                      <p className={styles.customTextStyle}>-</p>
                    )}
                  </div>
                </Grid>

              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <div className={styles.textField}>
                <strong className={styles.headerTextStyle}>Fertilization Technique</strong>
                {oocyteData && oocyteData.length > 0 && oocyteData[0].oocytes.length > 0 ? (
                  <p className={styles.customTextStyle}>
                    {oocyteData[0].oocytes[0]?.fertilization_technique || '-'}
                  </p>
                ) : (
                  <p className={styles.customTextStyle}>-</p>
                )}
              </div>
            </Grid>

          </Grid>
          <hr className='mt-4' />

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container direction="row" spacing={2}>
                {oocyteData && oocyteData.length > 0 ? (
                  oocyteData.map((data, index) => {
                    const groupedOocytes = groupOocytesByGrade(data.oocytes);
                    return (
                      <React.Fragment key={index}>
                        {Object.entries(groupedOocytes).map(([grade, count]) => (
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={3} key={grade} className="mt-4">
                            <div className={styles.containerd}>
                              <div className={styles.circle}>{count}</div>
                              <div className={styles.text}>{grade}</div>
                            </div>
                          </Grid>
                        ))}
                      </React.Fragment>
                    );
                  })
                ) : null}

              </Grid>
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container direction="row" spacing={2}>

                <Grid item xs={12} sm={12} md={6} lg={4} className='mt-4'>
                  <div className={styles.containerBOX}>
                    <div className={styles.box}></div>
                    <span className={styles.headerTextStyle}>
                      Freezed <span className={styles.customTextStyle}>
                        {oocyteData &&
                  oocyteData.map((oocyte, index) => (
                    <p key={index} className={styles.customTextStyle}>{oocyte.freezed_count}</p>
                  ))
                        }
                      </span>
                    </span>
                  </div>
                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={4} className='mt-4'>
                  <div className={styles.containerBOX}>
                    <div className={styles.boxCulture}></div>
                    <span className={styles.headerTextStyle}>
                      Culture <span className={styles.customTextStyle}>
                        {oocyteData &&
                  oocyteData.map((oocyte, index) => (
                    <p key={index} className={styles.customTextStyle}>{oocyte.maturation_count}</p>
                  ))
                        }
                      </span>
                    </span>
                  </div>
                </Grid>




                <Grid item xs={12} sm={12} md={6} lg={4} className='mt-4'>
                  <div className={styles.containerBOX}>
                    <div className={styles.boxDiscard}></div>
                    <span className={styles.headerTextStyle}>
                      Discard <span className={styles.customTextStyle}>
                        {oocyteData &&
                  oocyteData.map((oocyte, index) => (
                    <p key={index} className={styles.customTextStyle}>{oocyte.discard_count}</p>
                  ))
                        }
                      </span>
                    </span>
                  </div>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Fertilization;
