import * as Yup from 'yup';
import { Action945Enum, BlankEnum, CleavageEnum, CryoMediaEnum, CryoPurposeEnum, CultureDishConfigEnum, EmbryoFinilizeUpdateType, IncubatorEnum,  NullEnum, PbEnum, StatusDa2Enum } from './embryoCulture.helpers';
import { getRequest, putRequest } from '../../../services/httpService';

// Define the initial embryo culture and update details types
export interface EmbryoCultureType {
    time: string; 
    embryologist?: number | null; 
    witness?: number | null; 
    incubator?: string | null; 
    remarks?: string | null; 
    culture_dish_config?: string | BlankEnum | NullEnum | null; 
    day: number; 
    cycle: number;
    id?: number | null | undefined;
    opu_date?: string;
  }
  
export interface EmbryoUpdateDetailsType {    
    cycle: number;
    embryos: number[] | null;
    pn: number;
    pb: string | null;
    cleavage: string | null;
    action: string | BlankEnum;
    cryo_preserved_date: string | null;
    cryo_preserved_by: number | null;
    cryo_purpose: string | null;
    cryo_media: string | null;
    is_screened: boolean;
    day: number | null;
    status: string | null;
    source?: number | null;
    thaw_cycle?: number | null;
  }
  
// Define initial values for single object
export const initialEmbryoCultureValues: EmbryoCultureType = {
  embryologist: null,
  witness: null,
  cycle: 0,
  remarks: '',
  culture_dish_config: '',
  day: 0,
  time: '',
  incubator: '',
  opu_date:''
};
  
export const initialEmbryoUpdateValues: EmbryoUpdateDetailsType = {
  cycle: 55,
  embryos: [],
  action: '',
  pn: 0,
  pb: null,
  cleavage: null,
  day: 1,
  status: 'DRAFT',
  cryo_preserved_date: null,
  cryo_preserved_by: null,
  cryo_purpose: null,
  cryo_media: null,
  is_screened: false,
};
  
  
// Validation Schema for single embryo culture
export const EmbryoCultureValidationSchema = Yup.object().shape({

  embryologist: Yup.number()
    .required('Embryologist name is required'),
  witness: Yup.number()
    .required('Witness Embryologist name is required')
    .test('not-same', 'The witness embryologist cannot be the same as the embryologist.', function (value) {
      const { embryologist } = this.parent; // Accessing the sibling field
      return value !== embryologist; // Validate that they are not the same
    }),
  
  cycle: Yup.number().integer(),
  remarks: Yup.string().nullable(),
  culture_dish_config: Yup.mixed<CultureDishConfigEnum | BlankEnum | NullEnum>()
    .oneOf([
      'NORMAL_SINGLE_CULTURE', 'EMBRYO_SLIDE_TIMELAPSE_SINGLE_CULTURE', 
      'CULTURE_PRO_TIMELAPSE_SINGLE_CULTURE', 'EMBRYO_SLIDE_IC8_DISH_TIMELAPSE_SINGLE_CULTURE', 
      '38_SPECIAL_GPS_SINGLE_CULTURE', 'EMBRYO_GPS_SINGLE_CULTURE', 
      'MINI_GPS_GPS_SINGLE_CULTURE', '4_WELL_GPS_SINGLE_CULTURE', 
      'U_DROP_GPS_SINGLE_CULTURE', 'EMBRYO_CORRAL_GPS_SINGLE_CULTURE', 
      'NORMAL_GROUP_CULTURE', 'MICROWELL_GROUP_CULTURE', '', 'NULL'
    ])
    .nullable(),
  day: Yup.number().min(0).max(30).integer(),
  time: Yup.string().required(),
  incubator: Yup.mixed<IncubatorEnum | BlankEnum | NullEnum>()
    .oneOf(['HERA_CELL', 'MINC_K_SYSTEMS', 'MEDGYN', 'OOSAFE', 'VITROLIFE', 'IVF_TECH', '', 'NULL'])
    .nullable(),
});
  
// Validation Schema for single embryo update details
export const EmbryoValidationSchema = Yup.object().shape({
  cycle: Yup.number().positive('Cycle must be a positive number'),
  embryos: Yup.array()
    .min(1, 'Embryo number is required')
    .required('Embryo number is required'), 
  action: Yup.mixed<Action945Enum | BlankEnum>()
    .oneOf(['CULTURE', 'FREEZE', 'DISCARD', ''], 'Invalid action')
    .required(),
  pn: Yup.number()
    .positive('PN must be a positive number')
    .nullable()
    .test('is-positive-pn', 'PN must be a positive number', function (value) {
      if (value !== null && value !== undefined) {
        return value > 0; 
      }
      return true; 
    }),
  pb: Yup.mixed<PbEnum>()
    .nullable()
    .oneOf(['1 PB', '2 PB', '3 PB', '4 PB'], 'Invalid PB value'),
  cleavage: Yup.mixed<CleavageEnum>()
    .nullable()
    .oneOf(['INTACT', 'SYNGAMY', 'EARLY_CLEAVAGE'], 'Invalid cleavage value'),
  day: Yup.number().positive('Day must be a positive number'),
  status: Yup.mixed<StatusDa2Enum>()
    .oneOf(['DRAFT', 'FINALIZED'], 'Invalid status'),
  cryo_preserved_date: Yup.string().nullable(),
  cryo_preserved_by: Yup.string().nullable(),
  cryo_purpose: Yup.mixed<CryoPurposeEnum>()
    .nullable()
    .oneOf(
      [
        'PRESERVATION_OF_EXCESS_EMBRYOS', 
        'TIMED_EMBRYO_TRANSFER', 
        'FERTILITY_PRESERVATION', 
        'GENETIC_TESTING', 
        'FLEXIBLE_FAMILY_PLANNING', 
        'REDUCING_MULTIPLE_PREGNANCIES',
      ], 
      'Invalid cryo purpose'
    ),
  cryo_media: Yup.mixed<CryoMediaEnum>()
    .nullable()
    .oneOf(['KITAZATO', 'SWEMED', 'IRVINE_SCIENTIFIC'], 'Invalid cryo media'),
  is_screened: Yup.boolean(),
});
  
// Combined Validation Schema for single objects
export const CombinedValidationSchema = Yup.object().shape({
  embryoCulture: EmbryoCultureValidationSchema,
  embryoDetails: EmbryoValidationSchema,
});



// Function to update embryo details
export function updateEmbryoSummary(id: number, data: EmbryoCultureType | EmbryoFinilizeUpdateType): Promise<EmbryoCultureType | EmbryoFinilizeUpdateType> {
  const url = `/embryo/cycle/${id}/`;
  return putRequest(url, data, { responseType: 'json' });
}


// Function to get Embryo retrieval
export function getEmbryoDetails(id: number): Promise<EmbryoCultureType> {
  const url =`oocyte-details/${id}`;
  return getRequest(url, { responseType: 'json' });
}

// Function to get Embryo Details
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEmbryoCultureDetails(id: number, source?: number, thawId?:number | null): Promise<any> {
  let url = `cycle/${id}/embryos/?day=1`;

  // Only append the source parameter if it is provided
  if (source !== undefined) {
    url += `&source=${source}`;
  }

  if (thawId !== undefined && thawId !== null) {
    url += `&thaw_cycle=${thawId}`;
  }
  return getRequest(url, { responseType: 'json' });
}

// Function to update Embryo details
export function updateEmbryoDetails(id: number, data: EmbryoCultureType): Promise<EmbryoCultureType> {
  const url = `/embryo-culture/${id}/details`;
  return putRequest(url, data, { responseType: 'json' });
}

// Function to update Embryo Summary details
export function updateEmbryoSummaryDetails(cycle_id : number, data: EmbryoUpdateDetailsType): Promise<EmbryoUpdateDetailsType> {
  const url = `/embryo/cycle/${cycle_id}`;
  return putRequest(url, data, { responseType: 'json' });
}


export const columns = [
  { label: 'Embryo No.', field: 'embryoNo', editable: false },
  { label: 'Image', field: 'image', editable: false },
  { label: 'PN', field: 'pn', editable: false },
  { label: 'PB', field: 'pb', editable: false },
  { label: 'Cleavage', field: 'cleavage', editable: false },
  { label: 'Action', field: 'action', editable: false }, 
  { label: 'Freeze Location', field: 'freezeLocation', editable: true },
];

export const rows = [];