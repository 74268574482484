/* eslint-disable no-console */
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Grid2, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton} from '@mui/material';
import styles from './../oocyteCryo/cryoStorage.module.css';
import { CryoStorageDetail, getEmbryoStorage,  } from '../cryoStorage.helpers';
import StatusChip from '../../../components/customTableComponent/statusChip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EmbryoMaterialDetails from './embryoMaterialDetails';

const EmbryoStorage = () => {
  const [cryoStorageDetails, setCryoStorageDetails] = useState<CryoStorageDetail[]>([]);
   
  const [selectedMaterial, setSelectedMaterial] = useState<number | null>(null);
  const [selectedCycleID, setSelectedCycleID] = useState<number | null>(null);   

  const handleMaterialClick = (cycleID: number) => {
    setSelectedMaterial(cycleID);
    setSelectedCycleID(cycleID);
  };
  
  // Reusable function to fetch data based on patient type
  const fetchStorageData = useCallback(() => {
    getEmbryoStorage()
      .then((response) => {
        setCryoStorageDetails(response.objects); 
      })
      .catch((error) => {   
        console.error('Error fetching  storage data:', error);
        setCryoStorageDetails([]); 
      });
  }, []);

  useEffect(() => {
    fetchStorageData(); 
  }, [ fetchStorageData]); 


  // Function to close the material section
  const closeMaterialSection = () => {
    setSelectedMaterial(null);
  };
      
  return (
    <div className={styles.container}>
      <div className="container-fluid">
        <Grid2 container spacing={2} mt={1}  columns={12}>
          {/* Left Side: Radio Buttons */}
          <Grid2
            size={{ xs: 6, sm: selectedMaterial ? 5 : 8, md: selectedMaterial ? 5 : 8 }}
            className="d-flex "
          >
            <Typography variant="h6" sx={{ marginLeft: 2 }}>
             Patient List
            </Typography>
          </Grid2>

          {/* Middle: Tank Button */}
          <Grid2 size={{ xs: 3, sm: 3, md:  3 }} className="d-flex justify-content-end " >
            <Button variant="contained" color="primary" sx={{ fontWeight: 'bold',  height: '38px'}} size="small" >
                Tank 
            </Button>
          </Grid2>

          {/* Right Side: Materials Section */}
          {selectedMaterial && (
            <Grid2
              size={{ xs: 3, sm: 3, md: 3 }}
              className="d-flex justify-content-start"
              sx={{ ml: 2, display: 'flex', alignItems: 'center' }}
            >
              <div style={{ flexGrow: 1 }}>
                <h6>Materials</h6> 
              </div>
              <IconButton onClick={closeMaterialSection} color="secondary" >
                <HighlightOffIcon />
              </IconButton>
            </Grid2>            
          )}

          <Grid2 size={{ xs: selectedMaterial ? 8 : 12, sm: selectedMaterial ? 8 : 12, md: selectedMaterial ? 8 : 12 }}>
            <div style={{ width: '100%', overflowX: 'auto' }}>
              <TableContainer sx={{ border: '1px solid rgb(240, 237, 237)', borderRadius: '10px' }}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        height: '2rem',
                        background: 'rgba(250, 250, 250, 1)',
                        border: 'none',
                        '& .MuiTableCell-root': {
                          padding: '0.6rem 0.6rem',
                        },
                      }}
                    >
                      {['MRN', 'Patient Name', 'Cycle No.', 'Billed', 'Material', 'Media'].map((title, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontFamily: 'Nunito, sans-serif',
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            lineHeight: '1.45',
                            textAlign: 'left',
                            color: 'rgba(35, 35, 35, 1)',
                            padding: '0.3rem 0.6rem', // Adjust padding
                          }}
                        >
                          {title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
    
                  <TableBody>
                    {cryoStorageDetails.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Typography variant="h6" color="textSecondary">
                            No data found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      cryoStorageDetails.map((storage, index) => (
                        <TableRow key={index}>
                          <TableCell>{storage.patient_mrn || '-'}</TableCell>
                          <TableCell>{storage.patient_name }  {storage.patient_last_name || '-'}</TableCell>
                          <TableCell>{storage.cycle_number || '-'}</TableCell>
                          <TableCell>
                            <StatusChip value="Pending" height={20} />
                          </TableCell>
                          <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleMaterialClick(storage.cycle)}>
                            <span
                              className={`${styles.pointerData} ${
                                selectedMaterial === storage.cycle ? styles.highlight : ''
                              }`}
                            >
                              {storage.material_count || '-'}
                            </span>
                          </TableCell>
                          <TableCell>{storage.cryo_media || '-'}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer> 
            </div>
          </Grid2>
          
          {/* Right Side: Materials Section */}          
          {selectedMaterial && (
            <Grid2 size={{ xs: 12, sm: 4, md: 4 }} sx={{ marginTop: 0 }}>
              <EmbryoMaterialDetails selectedCycleID={selectedCycleID}   />
            </Grid2>
          )}          
        </Grid2>
      </div>
    </div>
  );
};

export default  EmbryoStorage;
