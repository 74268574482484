import React, { useEffect, useState } from 'react';
import { ThemeProvider, Box, Typography,  Divider, Dialog, DialogActions, IconButton, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { theme } from '../../Medical_History_Forms/themeprovider';
import Grid from '@mui/material/Grid2';
import { convertUnderscoreToTitle, infoTextStyle, sectionTitleStyle } from '../../../utils/commonUtils';
import { getLaparoscopy } from './laparoscopy.helper';
import { useLoader } from '../../loaderProvider/loader';
import styles from '../oocyteRetrieval/oocyteRetrival.module.css';
import { Close as CloseIcon } from '@mui/icons-material';



interface LaparoscopyViewDetailsProps {
  record: number; // Ensure this matches your actual data structure
  onBack: () => void;
  onEdit?: () => void; 

}

const LaparoscopyViewDetails: React.FC<LaparoscopyViewDetailsProps> = ({ record, onBack, onEdit }) => {
  const { showLoader, hideLoader } = useLoader();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [retrievalData, setRetrievalData] = useState<any>(null);

  const [open, setOpen] = useState(false); // State to control lightbox visibility
  const [imageSrc, setImageSrc] = useState(''); // State to store the image source


  useEffect(() => {
    if (!record) return;

    showLoader();
    getLaparoscopy(record)
      .then((data) => setRetrievalData(data))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching Oocyte Retrieval data');
        setRetrievalData([]); // Set to default on error
      })
      .finally(() => hideLoader());
  }, [record, showLoader, hideLoader]);


  // Function to handle the click on the image preview
  const handleImageClick = (src:string) => {
    setImageSrc(src);
    setOpen(true); // Open the lightbox
  };

  // Function to handle closing the lightbox
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={4} mb={3}>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Date</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.date  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Indication</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.indication  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Cycle Day</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.cycle_day  || '-'} Day
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Procedure Duration</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.procedure_duration  || '-'} (Hrs)
            </Typography>
          </Grid>

        </Grid>

 
        <Divider sx={{ borderColor: '#666', my: 2 }} />

        <Box sx={{ width: '100%'}} mt={4}>
          <Typography variant="h6" sx={{ ...infoTextStyle }}>
            Anesthesia Details
          </Typography>
        </Box>
        <Grid container spacing={4} mt={2}  mb={3} >
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Anesthesia Type</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.anesthesia_type)  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Anesthesia Details</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.anesthesia_details_remark  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Preanesthetic Check Up</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.preanesthetic_checkup  || '-'}
            </Typography>
          </Grid>
        </Grid>
        
        <Divider sx={{ borderColor: '#666', my: 2 }} />

 
        <Box sx={{ width: '100%'}} mt={4}>
          <Typography variant="h6" sx={{ ...infoTextStyle }}>
          Patency Of Falloplan Tube
          </Typography>
        </Box>
        <Grid container spacing={4} mt={1}  mb={3} >
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Right Tube</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.right_tube_status || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Left Tube</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.left_tube_status  || '-'}
            </Typography>
          </Grid>
        </Grid>        
 
        <Divider sx={{ borderColor: '#666', my: 2 }} />

        <Box sx={{ width: '100%'}} mt={4}>
          <Typography variant="h6" sx={{ ...infoTextStyle }}>
          Right Ovary
          </Typography>
        </Box>
        <Grid container spacing={4} mt={1}  mb={3} >
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Status</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.right_ovary_status || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Other Details</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.right_ovary_details  || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ borderColor: '#666', my: 2 }} />
 
        <Box sx={{ width: '100%'}} mt={4}>
          <Typography variant="h6" sx={{ ...infoTextStyle }}>
          Left Ovary
          </Typography>
        </Box>
        <Grid container spacing={4} mt={1}  mb={3} >
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Status</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.left_ovary_status || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Other Details</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.left_ovary_details  || '-'}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ borderColor: '#666', my: 2 }} />

        <Box sx={{ width: '100%'}} mt={4}>
          <Typography variant="h6" sx={{ ...infoTextStyle }}>
          Other Details
          </Typography>
        </Box>
        <Grid container spacing={4} mt={1}  mb={3} >
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Site</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.adhesion_site) || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Adheshion</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.adhesion_grade)  || '-'}
            </Typography>
          </Grid>


          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Remark</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.remark  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}> Endometriosis Grade</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.endometriosis_grade)  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Uterus</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.uterus_status)  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Size</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.uterus_size)  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Shape</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.uterus_shape)  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Position</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.uterus_position)  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Mobility</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.uterus_mobility)  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Addociated Procedures</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.associated_procedures)  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Remark</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.eb_hpe_remark  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Done By</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.done_by_name  || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Associated Dr.</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.associated_doctor_name  || '-'}
            </Typography>
          </Grid>

          <Tooltip title="Click to preview" arrow>
            <div
              onClick={() => handleImageClick(retrievalData?.image_path_url || '')}
              style={{ cursor: 'pointer', position: 'relative' }}
            >

              {retrievalData?.image_path_url && (
                <img
                  src={retrievalData?.image_path_url || 'default-icon.png'}
                  alt="file"
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: '50%',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Add transition for smooth effect
                  }}

                  onMouseOver={(e) => {
                    const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                    target.style.transform = 'scale(1.1)';
                    target.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)';
                  }}
                  onMouseOut={(e) => {
                    const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                    target.style.transform = 'scale(1)';
                    target.style.boxShadow = 'none';
                  }}
                />
              )}
            </div>
          </Tooltip>


          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            sx={{
              width: '55%', 
              margin: 'auto', // Center the dialog
            }}
          >
            <DialogTitle sx={{ position: 'relative' }}>
        Image Preview
              {/* Close Icon at the Right Corner of the Dialog Title */}
              <IconButton
                onClick={handleClose}
                color="primary"
                sx={{
                  position: 'absolute',
                  top: 10,
                  right: 4,
                  padding: '8px',
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src={imageSrc}
                alt="Large Preview"
                style={{
                  maxWidth: '100%', // Ensure image width doesn't exceed the dialog width
                  maxHeight: '100%', // Ensure image height doesn't exceed the dialog height
                  objectFit: 'contain', // Maintain aspect ratio
                }}
              />
            </DialogContent>
            <DialogActions>
              <IconButton onClick={handleClose} color="primary">
          Close
              </IconButton>
            </DialogActions>
          </Dialog>    

        </Grid>

        <Box className={styles.customButtonDiv}>
          <button
            type="button"
            className={styles.resetButton}
            onClick={onBack}
          >
Back to List View   
          </button>

          <button
            type="button"
            className={styles.customButton}
            onClick={onEdit}
          >
            <span style={{ fontWeight: 'bold' }}>
      Edit
            </span>
          </button>

        </Box>

      </Box>
    </ThemeProvider>
  );
};

export default LaparoscopyViewDetails;
