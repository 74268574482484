/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import styles from './header_Profile.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { getPatientDetails } from '../../services/apiService';
import { useLoader } from '../loaderProvider/loader';
import  defaultFemale from '../../assets/SVG/Default Profile - Female.svg';
import  defaultMale from '../../assets/SVG/Default Profile - Male.svg';
import ImageWithSkeleton from '../customImageComponent';
import { toTitleCase } from '../../utils/commonUtils';
import { patientIdObservable, setPatientDetails, setPatientId } from '../../services/sharedService';
import { useLocation, useParams } from 'react-router-dom';
import { usePatientContext } from '../SubPatinetProvider';
import { Patientv2 } from '../../types';
import { usePatientContext as useMainPatientContext } from '../patinetProvider';


export default function HeaderProfile({ onData }:any){
  const { id } = useParams<{ id: string }>(); 
  const [selectedImg, setSelectedImg] = useState<number | null>(null);
  const [AllPatients, setAllPatient] = useState<any>(null);
  const [selectedPatient, setselectedPatient] = useState<Patientv2 | null>(null);
  const [isStimLoad, setIsStimLoad] = useState<boolean>(false);
  const { showLoader, hideLoader } = useLoader();
  const [age, setAge] = useState<number | null>(null);
  const [StoredpatientId, setstoredPatientId] = useState<any>(null);
  const { patientId, addSubPatient } = usePatientContext();
  const [expanded, setExpanded] = useState<boolean>(false);
  const {addPatient } = useMainPatientContext();
  const location = useLocation();

  const calculateAge = (dateOfBirth: string) => {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1; 
    }
    return age;
  };
  

  const changeBorderColor = useCallback((patient: any) => {
    setSelectedImg(patient.id);
    setselectedPatient(patient);
    onData(patient);
    const newAge = patient.date_of_birth ? calculateAge(patient.date_of_birth) : null;
    setAge(newAge);
    addSubPatient(patient.id);
    setPatientId(`${patient.id}`);
    setPatientDetails(patient);
  }, [onData,addSubPatient]);

  const onDataRef = useRef(onData);
  useEffect(() => {
    onDataRef.current = onData;
  }, [onData]);

  const notifyOnData = useCallback((data:any) => {
    onDataRef.current(data);
  }, []);

  useEffect(() => {
    setstoredPatientId(id);
    addSubPatient(Number(id));
    addPatient(Number(id));
  }, [id,addSubPatient, addPatient]);
  useEffect(() => {
  
    showLoader();
    
    if (StoredpatientId) {
      const fetchData = async () => {
        try {
          if (StoredpatientId) {
            const response = await getPatientDetails(StoredpatientId);
            const { partner, addresses, ...rest } = response;
    
            const patientData = [];
            patientData[0] = { ...rest }; 
            if (partner) {
              patientData[1] = partner; 
            }
 
            // If `donor` exists and is an array, add all its elements to `patientData`
            if (response.donor_details && Array.isArray(response.donor_details)) {
              response.donor_details.forEach((donorItem) => {
                patientData.push(donorItem);
              });
            }

            const genderFilter = window.location.pathname.includes('embryology')
              ? 'FEMALE'
              : window.location.pathname.includes('andrology')
                ? 'MALE'
                : null;

            const selectedPatient:any  = genderFilter
              ? patientData.find((patient) => patient.gender === genderFilter)
              : patientData[0];
  
            if (selectedPatient) {
              setAllPatient(patientData);
              setSelectedImg(selectedPatient.id);
              setselectedPatient(selectedPatient);
              setPatientId(`${selectedPatient.id}`);
              setPatientDetails(selectedPatient);
              notifyOnData(selectedPatient);
              const newAge = selectedPatient.date_of_birth
                ? calculateAge(selectedPatient.date_of_birth)
                : null;
              setAge(newAge);
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching patient details:', error);
        } finally {
          hideLoader();
        }
      };
  
      fetchData();
    }
  }, [showLoader, hideLoader, notifyOnData, StoredpatientId]); 

  const genderFilter = useMemo(() => {
    if (location.pathname.includes('embryology')) {
      return 'FEMALE';
    }
    if (location.pathname.includes('andrology')) {
      return 'MALE';
    }
    return null;
  }, [location.pathname]);
  
  return (
    <div onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)} className={styles.container}>
      <div className={'w-100'}>
        {AllPatients && Array.isArray(AllPatients) &&
      <div className={expanded? styles.main: styles.mainClosed}>
        <div className={styles.mainPics}>
          {AllPatients.map((img: any, index: number) => {
            const isClickable = genderFilter === null || img.gender === genderFilter;

            return (
              <div className={styles.pics} key={index} style={{cursor: isClickable ? 'pointer' : 'not-allowed',}}>
                <div
                  className={
                    selectedImg === img.id
                      ? styles.main_pc_dv
                      : styles.main_pc_dvvv
                  }
                  style={{
                    cursor: isClickable ? 'pointer' : 'not-allowed',
                    opacity: isClickable ? 1 : 0.6,
                  }}
                >
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <ImageWithSkeleton
                      src={img.photo_url}
                      fallbackSrc={img.gender === 'MALE' ? defaultMale : defaultFemale}
                      alt="pc"
                      height={expanded ? '6rem' : '4rem'}
                      width={expanded ? '6rem' : '4rem'}
                      
                      borderRadius="50%"
                      hideOverflow={false}
                      containerStyle={{
                        marginTop: expanded ? '-3rem' : '0',
                        transition: 'all 0.3s ease-in-out',

                      }}
                      headerImgStyle={{
                        cursor: isClickable ? 'pointer' : 'not-allowed',
                      }}
                      // Only allow clicks for valid patients
                      onClick={isClickable ? () => changeBorderColor(img) : undefined}
                    />
                    {img.type && (
                      <div
                        style={{
                          position: 'absolute',
                          top: expanded ? '-95%' : '0%',
                          right: expanded ? '-1%' : '-15%',
                          width: expanded ? '1.8rem' : '2.1rem',
                          height: expanded ? '1.8rem' : '2.1rem',
                          borderRadius: '50%',
                          border: '2px solid white',
                          backgroundColor:
                              img.type === 'SPERM_DONOR'
                                ? '#4EAEDE'
                                : img.type === 'OOCYTE_DONOR'
                                  ? '#F1AEE2'
                                  : img.type === 'SURROGATE'
                                    ? '#F1AEE2'
                                    : 'transparent',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '0.8rem',
                          fontWeight: 'bold',
                          color: 'white',
                          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        {img.type === 'SPERM_DONOR' && 'SD'}
                        {img.type === 'OOCYTE_DONOR' && 'OD'}
                        {img.type === 'SURROGATE' && 'SD'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {expanded ? <div className={styles.primary}>
          <div className={styles.name}>
            <span>Patient Name</span>
            <label>
              {selectedPatient?.prefix ? `${toTitleCase(selectedPatient.prefix)}. ` : ''}
              {selectedPatient?.first_name ? `${toTitleCase(selectedPatient.first_name)} ` : ''}
              {selectedPatient?.last_name ? toTitleCase(selectedPatient.last_name) : ''}
            </label>
          </div>
          <div className={styles.primary_item}>
            <span>Age</span>
            <label>{age !== null ? age : '-'} Years</label> 
          </div>
          <div className={styles.primary_item}>
            <span>Sex Assigned at Birth</span>
            <label>{toTitleCase(selectedPatient && selectedPatient.gender)}</label>
          </div>
          <div className={styles.primary_item}>
            <span>MRN</span>
            <label>{(selectedPatient && selectedPatient.medical_record_no) || '-'}</label>
          </div>
          <div className={styles.primary_item}>
            <span>Reason of Visit</span>
            <label>{selectedPatient && selectedPatient.additional_info?.reason_for_visit?.replaceAll('_', ' ')}</label>
          </div>
          <div className={styles.primary_item}>
            <span>Last Updated On</span>
            <label>{(selectedPatient && selectedPatient.updated_on) ? new Date(selectedPatient.updated_on).toLocaleDateString('en-GB') : '--'}</label>
          </div>
        </div>: <div className={styles.primaryClosed}>
          <div className={styles.name}>
            <label>
              {selectedPatient?.prefix ? `${toTitleCase(selectedPatient.prefix)}. ` : ''}
              {selectedPatient?.first_name ? `${toTitleCase(selectedPatient.first_name)} ` : ''}
              {selectedPatient?.last_name ? toTitleCase(selectedPatient.last_name) : ''}
            </label>
          </div>
        </div>}
      </div>
        }
      </div>

    </div>

  );
};
