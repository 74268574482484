/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, } from 'react';
import styles from './SemenProcessing.module.css';
import Grid from '@mui/material/Grid';
import { ReactComponent as SemenProcess } from '../../../assets/SVG/semanProcess.svg';
import { ReactComponent as SpermCountRefrenceImage } from '../../../assets/SVG/spermCountRefrence.svg';
import { ReactComponent as SpermMotalityRefImg } from '../../../assets/SVG/SpermMotalityRef.svg';
import { ReactComponent as AbnormalImg } from '../../../assets/SVG/abnormal.svg';
import { ReactComponent as NormalImg } from '../../../assets/SVG/normalFlag.svg';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { getSemenProcessing } from './SemenProcessingHelper';

interface SemenProcessingProps {
  cycle: string;
}

const SemenProcessing: React.FC<SemenProcessingProps> = ({ cycle }) => {
  const [selectedSpermSource, setSelectedSpermSource] = React.useState('partner');
  const [sourceDetails, setSourceDetails] = React.useState(true);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    getSemenProcessing(cycle)
      .then((data) => {
        const filteredData = data.filter((item) => item.source_details === sourceDetails);
        setFilteredData(filteredData);
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      });
  }, [cycle, sourceDetails]);

  const handleSpermSourceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSource = event.target.value;
    setSelectedSpermSource(newSource);
    setSourceDetails(newSource === 'partner');
  };


  return (
    <div className={styles.container}>
      <Grid container spacing={3} justifyContent="start" alignItems="center">
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="spermSource"
              name="spermSource"
              row
              value={selectedSpermSource}
              onChange={handleSpermSourceChange}
            >
              <FormControlLabel
                value="partner"
                control={<Radio sx={{ '&.Mui-checked': { color: '#E17E61' } }} />}
                label="Partner"
                className={styles.radioLabel}
              />
              <FormControlLabel
                value="spermDonor"
                control={<Radio sx={{ '&.Mui-checked': { color: '#E17E61' } }} />}
                label="Sperm Donor"
                className={styles.radioLabel}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="start" alignItems="center" className="mt-4" >
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Date:</strong>
            <p className={styles.customTextStyle}>   {filteredData.length > 0 ? filteredData[0].date : '-'}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Andrologist Name:</strong>
            <p className={styles.customTextStyle}>   {filteredData.length > 0 ? filteredData[0].done_by_name : '-'}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Type of Sperm:</strong>
            <p className={styles.customTextStyle}>   {filteredData.length > 0 ? filteredData[0].sperm_type : '-'}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Semen Preparation Method:</strong>
            <p className={styles.customTextStyle}>{filteredData.length > 0 ? filteredData[0].semen_collection_method : '-'}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <div className={styles.textField}>
            <strong className={styles.headerTextStyle}>Sample Collected At :</strong>
            <p className={styles.customTextStyle}>{filteredData.length > 0 ? filteredData[0].sample_collected_at : '-'}</p>
          </div>
        </Grid>
      </Grid>
      <hr className="mt-4" />
      {/* New row with Andrologist Name */}
      <Grid container spacing={3} justifyContent="start" alignItems="center" className="mt-4">
        {/* First Grid item: Sperm Count */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className={`${styles.boxContainer}`}>
            <Grid container spacing={2} alignItems="center">
              {/* Sperm Count Section */}
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className={styles.textField}>
                  <strong className={styles.headerTextStyleimg}>Sperm Count</strong>
                  <div className={`${styles.containerprofile} mt-4`}>
                    <span className={styles.semenProcessContainer}>
                      <SemenProcess width="10rem" height="10rem" />
                    </span>
                    <div className={styles.textContainer}>
                      <p className={styles.name}>
                        {filteredData.length > 0 ? filteredData[0].source_name : '-'}
                        {filteredData.length > 0 && filteredData[0].post_total_sperm_count && (
                          <>
                            {filteredData[0].post_total_sperm_count >= 33 && filteredData[0].post_total_sperm_count <= 46 ? (
                              <NormalImg style={{ fill: '#47B35F' }} />
                            ) : (
                              <AbnormalImg style={{ fill: '#CD1313' }} />
                            )}
                          </>
                        )}
                      </p>

                      <p className={`${styles.hello} mt-2`}>
                Your Total Sperm count has been identified <br />
                as {filteredData.length > 0 && filteredData[0].post_total_sperm_count >= 33 && filteredData[0].post_total_sperm_count <= 46 ? 'normal' : 'abnormal'}, with a value of{' '}
                        {filteredData.length > 0 ? filteredData[0].post_total_sperm_count : '-'} million/ejaculate.
                      </p>
                      <p
                        className={styles.funnyText}
                        style={{
                          color:
                    filteredData.length > 0 && filteredData[0].post_total_sperm_count >= 33 && filteredData[0].post_total_sperm_count <= 46
                      ? '#47B35F'
                      : '#CD1313',
                        }}
                      >
                        {filteredData.length > 0 ? filteredData[0].post_total_sperm_count : '-'} million/ejaculate
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>

              {/* Reference Section */}
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className={styles.textField}>
                  <div className={`${styles.containerprofile} mt-4`}>
                    <span className={styles.semenProcessContainer}>
                      <SpermCountRefrenceImage width="10rem" height="10rem" />
                    </span>
                    <div className={styles.textContainer}>
                      <p className={styles.name}>Reference</p>
                      <p className={`${styles.hello} mt-2`}>
                The normal reference range typically falls within the range of 33 to 46 million/ejaculate.
                      </p>
                      <p className={styles.funnyTextrefrence}>33 to 46 million/ejaculate</p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>


        {/* Second Grid item: Sperm Total Motility */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <div className={`${styles.boxContainer}`}>
            <Grid container spacing={2} alignItems="center">
              {/* Sperm Total Motility Section */}
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className={styles.textField}>
                  <strong className={styles.headerTextStyleimg}>Sperm Total Motility</strong>
                  <div className={`${styles.containerprofile} mt-4`}>
                    <span className={styles.semenProcessContainer}>
                      <SemenProcess width="10rem" height="10rem" />
                    </span>
                    <div className={styles.textContainer}>
                      <p className={styles.name}>
                        {filteredData.length > 0 ? filteredData[0].source_name : '-'}
                        {filteredData.length > 0 && filteredData[0].post_total_motility_count && (
                          <>
                            {filteredData[0].post_total_motility_count > 40 ? (
                              <NormalImg style={{ fill: '#47B35F' }} />
                            ) : (
                              <AbnormalImg style={{ fill: '#CD1313' }} />
                            )}
                          </>
                        )}
                      </p>
                      <p className={`${styles.hello} mt-2`}>
                        Your Total Sperm count has been identified <br />
                        as {filteredData.length > 0 && filteredData[0].post_total_motility_count > 40 ? 'normal' : 'abnormal'}, with a value of{' '}
                        {filteredData.length > 0 ? filteredData[0].post_total_motility_count : '-'} million/ejaculate.
                      </p>
                      <p
                        className={`${styles.funnyText} mt-2`}
                        style={{
                          color: filteredData.length > 0 && filteredData[0].post_total_motility_count > 40 ? '#47B35F' : '#CD1313',
                        }}
                      >
                        {filteredData.length > 0 ? filteredData[0].post_total_motility_count : '-'} million/ejaculate
                      </p>
                    </div>
                  </div>
                </div>

              </Grid>

              {/* Reference Section */}
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className={styles.textField}>
                  <div className={`${styles.containerprofile} mt-4`}>
                    <span className={styles.semenProcessContainer}>
                      <SpermMotalityRefImg width="10rem" height="10rem" />

                    </span>
                    <div className={styles.textContainer}>
                      <p className={styles.name}>Reference</p>
                      <p className={`${styles.hello} mt-2`}>
                        The normal Sperm total motility typically should be greater than 40%.
                      </p>
                      <p className={styles.funnyTextrefrence}>Greater than 40%</p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SemenProcessing;
