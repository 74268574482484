import { getRequest } from '../../services/httpService';


interface OocyteSource {
  first_name: string;
  last_name: string;
  medical_record_no: string;
  photo_url: string;
}

interface SpermSource {
  first_name: string;
  last_name: string;
  medical_record_no: string;
  photo_url: string;
}

interface Recipient {
  first_name: string;
  last_name: string;
  medical_record_no: string;
  photo_url: string;
}

interface DonorInfo {
  oocyte_source: OocyteSource[];
  sperm_source: SpermSource[];
  recipient: Recipient | null;
  surrogate: string | null;
}

interface CycleProcedure {
  id: number;
  services: number[];
  procedures: string;
  procedures_type: string;
  procedure_notes: string | null;
  created_on: string;
  donor_info: DonorInfo | null;
  indication: string;
}

export interface CycleDetailsType {
  id: number;
  cycle_number: string;
  cycle_name: string;
  procedure: CycleProcedure;
  medication_protocol: number;
  cycle_provider: number;
  provider_name: string;
  cycle_coordinator: number;
  coordinator_name: string;
  cycle_creator: number;
  cycle_start_date: string;
  cycle_end_date: string;
  lmp_date: string;
  cycle_creator_name: string;
  cycle_warning: string;
  remarks: string | null;
  status: string;
  patient: number;
  patient_name: string;
  stimulation_chart: number | null; 

  opu_status: boolean;
  semen_processing_status: boolean;
  stim_status: boolean;
  embryo_trasfer_status: boolean;
  fertilization_status: boolean;
  embryo_culture_status: boolean;
}


export function getcycleListUrl(Id: string): string {
  return `/cycle/${Id}/`; 
}


export function getcycleList(Id: string): Promise<CycleDetailsType> {
  return getRequest(getcycleListUrl(Id), { responseType: 'json' });
}
