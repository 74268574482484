import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { CryoStorageDetail, getEmbryoMaterialsDetails,  } from '../cryoStorage.helpers';
import { getDateInDDMMYYYY } from '../../../utils/commonUtils';
import EditableDateCell from '../editableDateCell';

interface MaterialDetailsProps {
    selectedCycleID: number | null;
  }
  
const EmbryoMaterialDetails: React.FC<MaterialDetailsProps> = ({ selectedCycleID }) => {
  const [selectedCycleDetails, setSelectedCycleDetails] = useState<CryoStorageDetail[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  
  
  const fetchData = (cycleID: number) => {
    setLoading(true);
    setError(null);
    getEmbryoMaterialsDetails(cycleID)
      .then((data) => {
        setSelectedCycleDetails(data || null);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching material details:', err);
        setError('Failed to fetch material details');
      })
      .finally(() => setLoading(false));
  };
  
  useEffect(() => {
    if (selectedCycleID !== null) {
      fetchData(selectedCycleID);
    }
  }, [selectedCycleID]);
  
  const handleDateChange = (newDate: string) => {
    // eslint-disable-next-line no-console
    console.log('New Date:', newDate);
    fetchData(selectedCycleID!); 
  };
  

  if (loading) {
    return <p>Loading...</p>;
  }
  
  if (error) {
    return <p>{error}</p>;
  }
  
  return (
    <div >
      <TableContainer sx={{ border: '1px solid rgb(240, 237, 237)', borderRadius: '10px' ,  overflowX: 'auto',}} >
        <Table >
          <TableHead>
            <TableRow
              sx={{
                height: '2rem',
                background: 'rgba(250, 250, 250, 1)',
                border: 'none',
                '& .MuiTableCell-root': {
                  padding: '0.6rem 0.6rem',
                  minWidth: '50%',
                },
              }}
            >
              {['Embryo No.', 'Grade',  'Freezing Details', 'Age', 'Renewal Date', 'Location'].map((title, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: '1.45',
                    textAlign: 'left',
                    color: 'rgba(35, 35, 35, 1)',
                    padding: '0.3rem 0.6rem',
                    verticalAlign: 'top'
                  }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedCycleDetails ? (
              selectedCycleDetails.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="h6" color="textSecondary">
                        No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                selectedCycleDetails.map((storage, index) => (
                  <TableRow key={index}>
                    <TableCell>{storage.embryo_number || '-'}</TableCell>
                    <TableCell>{storage.grade || '-'}</TableCell>
                    <TableCell>
                      <div> No : {storage.id}</div>
                      <div>{storage.created_on ? getDateInDDMMYYYY(storage.created_on) : '-'}</div>
                    </TableCell>                    
                    <TableCell style={{ whiteSpace: 'nowrap' }}>Day 0</TableCell>                                       
                    <TableCell>
                      <EditableDateCell cryo_date={storage.renewal_date} itemId={storage.id} type='oocyte' onDateChange={handleDateChange} />
                    </TableCell>                    
                    <TableCell>{storage.location || '-'}</TableCell>
                  </TableRow>
                ))
              )
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography variant="h6" color="textSecondary">
                        Loading...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer> 
    </div>
  );
};

export default EmbryoMaterialDetails;
