import { getRequest } from '../../../services/httpService';


// SemenProcessingHelper.ts
export interface SemenProcessing {
    id: number;
    cycle: number;
    sperm_type: string | null;
    sample_number: string | null;
    cryo_number: string | null;
    post_volume_ml: number | null;
    post_sperm_concentration: number | null;
    post_rapid_progressive_percent: number | null;
    post_slow_progressive_percent: number | null;
    post_non_progressive_percent: number | null;
    post_wbc_million_per_ml: number | null;
    post_pus_cells: number | null;
    post_rbc: number | null;
    post_epithelial_cells: number | null;
    disposition_type: string | null;
    post_total_sperm_count: number | null;
    post_all_progressive: number | null;
    post_total_motility: number | null;
    post_total_motility_count: number | null;
    post_immotile_percent: number | null;
    source_details: boolean;
    source_name: string;
    sample_collected_at: string | null;
    done_by_name?: string;
    witness_name?: string;
  }
  
  

export function getSemenProcessingUrl(Id: string): string {
  return `/semen-processing/cycle/${Id}/`; 
}
export function getSemenProcessing(Id: string): Promise<SemenProcessing[]> {
  return getRequest(getSemenProcessingUrl(Id), { responseType: 'json' });
}