/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { billing, CryopreservedData, getCryopreserved, updatedLabels,} from './CryopreservedComponentHelper';
import { ReactComponent as PendingCryo } from '../../assets/SVG/pendingCryo.svg';
import { ReactComponent as CryobackgroundImg } from '../../assets/SVG/Cryobackgroundimg.svg';
import { ReactComponent as CRYO } from '../../assets/SVG/Cryo.svg';
import { ReactComponent as GOBLET } from '../../assets/SVG/Goblet.svg';
import { ReactComponent as INFO } from '../../assets/SVG/MaterialInfoCircle.svg';
import { ReactComponent as ADD } from '../../assets/SVG/plusinformation.svg';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from './CryoThawingComponent.module.css';
import { ReactComponent as More } from '../../assets/SVG/close-circle-greay.svg';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import PlanInformation from './PlanInformation';
import { useLoader } from '../loaderProvider/loader';
import { usePatientContext } from '../SubPatinetProvider';
interface CryopreservedComponentProps {
  label: string;
}
const CryopreservedComponent: React.FC<CryopreservedComponentProps> = ({ label }) => {
  const [storedLabel, setStoredLabel] = useState(label);
  const [Label, setlabel] = useState('Oocyte');
  const [materialAnchorEl, setMaterialAnchorEl] = React.useState<HTMLElement | SVGSVGElement | null>(null);
  const [billingAnchorEl, setBillingAnchorEl] = React.useState<HTMLTableCellElement | null>(null);
  const [selectedPlanInfo, setSelectedPlanInfo] = React.useState<string | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); 
  const [data, setData] = useState<CryopreservedData[]>([]);
  const [dynamicLabels, setDynamicLabels] = useState<any[]>([]);
  const { showLoader, hideLoader } = useLoader();
  const { patientId } = usePatientContext();
  useEffect(() => {
    showLoader();
  
    getCryopreserved(patientId, storedLabel)
      .then((fetchedData) => {
        setData(Array.isArray(fetchedData) ? fetchedData : []);
        setDataLoaded(true);
        setErrorMessage(null);
      })
      .catch((error) => {
        if (error && error.errors && error.errors.length > 0) {
          const errorDetail = error.errors[0].detail;
          setErrorMessage(errorDetail);
          setDataLoaded(false);
        } else {
          // eslint-disable-next-line no-console
          console.error('Error fetching cryopreserved data:', error);
          setErrorMessage('An unknown error occurred while fetching the data.');
          setDataLoaded(false);
        }
      })
      .finally(() => {
        hideLoader();
      });
  
  }, [storedLabel, patientId, showLoader, hideLoader]);
  


  const handleMaterialClick = (event: React.MouseEvent<SVGSVGElement>, item: any) => {
    const labels = updatedLabels(item);
    setDynamicLabels(labels);
    setMaterialAnchorEl(event.currentTarget as SVGSVGElement); 
  };
  
  

  const handleBillingClick = (event: React.MouseEvent<HTMLTableCellElement>, content: string) => {
    setBillingAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setMaterialAnchorEl(null);
    setBillingAnchorEl(null);
  };

  const openMaterialPopover = Boolean(materialAnchorEl);
  const openBillingPopover = Boolean(billingAnchorEl);
  const materialPopoverId = openMaterialPopover ? 'simple-material-popover' : undefined;
  const billingPopoverId = openBillingPopover ? 'simple-billing-popover' : undefined;
  const handleOpenDialog = (item: any) => {
    if (item.oocyte_number) {
      setlabel('Oocyte');
    } else if (item.embryo_number) {
      setlabel('Embryo');
    }
  
    // Open the dialog
    setSelectedPlanInfo(String(item.id || ''));
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPlanInfo(null);
  };

  return (
    <div className={styles.oocyteContainer}>
      {errorMessage ? (
        <div className={styles.centeredContainer}>
          <CryobackgroundImg />
        </div>
      ) : dataLoaded ? (
        <table className={styles.fullWidthTable}>
          <thead>
            <tr>
              <th>Oocyte ID</th>
              <th>Cycle No</th>
              <th>Cryo ID</th>
              <th>Cryo Date</th>
              <th>Cryopreserved By</th>
              <th>Material</th>
              <th>Location</th>
              <th>Billing</th>
              <th>Plan Information</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{item.oocyte_number ||item.embryo_number }</td>
                  <td>{item.cycle || '-'}</td>
                  <td>{item.id || '-'}</td>
                  <td>{item.cryo_preserved_date || '-'}</td>
                  <td>{item.cryo_preserved_by_name || '-'}</td>
                  <td style={{ cursor: 'pointer' }}>
                    <INFO style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={(e: React.MouseEvent<SVGSVGElement>) => handleMaterialClick(e, item)} />

                  </td>

                  <td>
                    {item.location
                      ? item.location.split(' ').map((word, idx) => {
                        if (word === 'Goblet') {
                          return (
                            <span key={idx}>
                      Goblet <GOBLET width="15px" height="15px" />
                            </span>
                          );
                        } else if (word === 'Cryolock') {
                          return (
                            <span key={idx}>
                      Cryolock <CRYO width="15px" height="15px" />
                            </span>
                          );
                        } else {
                          return <span key={idx}>{word} </span>;
                        }
                      })
                      : '-'}
                  </td>
                  <td
                    onClick={(e) => handleBillingClick(e, item.billing || '')}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.billing === 'Pending' ? (
                      <>
                        <INFO /> <PendingCryo />
                      </>
                    ) : (
                      item.billing || '-'
                    )}
                  </td>
                  <td
                    onClick={() => handleOpenDialog(item)}
                    style={{ cursor: 'pointer' }}
                  >

                    <ADD />
                 
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} style={{ textAlign: 'center' }}>
        No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
      
      ) : null} 


      {/* Material Popover */}
      <Popover
        id={materialPopoverId}
        open={openMaterialPopover}
        anchorEl={materialAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Typography sx={{
            flexGrow: 1,
            fontFamily: 'Montserrat',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '24.38px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#232323',
          }}>
            Material Information
          </Typography>
          <More onClick={handleClosePopover} style={{ cursor: 'pointer', marginLeft: '25px' }} />
        </Box>
        <hr className={styles.divider} />
        {dynamicLabels.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            <Typography sx={{
              flexGrow: 1,
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '19.5px',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
              color: '#737373',
            }}>
              {item.label}
            </Typography>
            <Typography sx={{
              ml: 2,
              fontFamily: 'Nunito',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '21.82px',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
              color: '#232323',
            }}>
              {item.value}
            </Typography>
          </Box>
        ))}
      </Popover>

      {/* Billing Popover */}
      <Popover
        id={billingPopoverId}
        open={openBillingPopover}
        anchorEl={billingAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Typography sx={{
            flexGrow: 1,
            fontFamily: 'Montserrat',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '24.38px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#232323',
          }}>
            Billing Information
          </Typography>
          <More onClick={handleClosePopover} style={{ cursor: 'pointer', marginLeft: '25px' }} />
        </Box>
        <hr className={styles.divider} />
        {billing.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            <Typography sx={{
              flexGrow: 1,
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '19.5px',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
              color: '#737373',
            }}>
              {item.label}
            </Typography>
            <Typography sx={{
              ml: 2,
              fontFamily: 'Nunito',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '21.82px',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none',
              color: '#232323',
            }}>
              {item.value}
            </Typography>
          </Box>
        ))}
      </Popover>

      {/* Plan Information Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '8px',
          },
        }}

      >
        <DialogContent>
          <PlanInformation handleCloseDialog={handleCloseDialog} planInfo={selectedPlanInfo ? Number(selectedPlanInfo) : null}   label={storedLabel}  apicallinglabel={Label}/>

        </DialogContent>
        <DialogActions>
          {/* Actions go here */}
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default CryopreservedComponent;
