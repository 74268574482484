/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid2, Box, Checkbox, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { RequiredLabel } from '../../../utils/commonUtils';
import { EmbryoDetail, source_list } from './embryoCulture.helpers';

interface Props {
    title: string;
    totalOocytes:EmbryoDetail[];
    onSelectionChange: (selectedOocytes: number[], dropdownValue: string) => void;
    hitSubmitbtn:boolean,
    sourceList?:source_list[],
    selectedsource?:string |null
  }
  
const OocyteSelector = ({ title, totalOocytes, onSelectionChange, hitSubmitbtn, sourceList, selectedsource }:Props) => {
  const [selectedOocytes, setSelectedOocytes] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [dropdownValue, setDropdownValue] = useState<string>('');

  const handleOocyteClick = (oocyte:number) => {
    const updatedSelection = selectedOocytes.includes(oocyte)
      ? selectedOocytes.filter((item) => item !== oocyte)
      : [...selectedOocytes, oocyte];

    setSelectedOocytes(updatedSelection);
    onSelectionChange(updatedSelection, dropdownValue);
    setSelectAll(updatedSelection.length === totalOocytes.length);
  };

  useEffect(()=>{
    setSelectedOocytes([]);
    
  },[totalOocytes]);
  useEffect(() => {
    if (selectedsource) {
      setDropdownValue(selectedsource); // Set to first item in sourceList if dropdownValue is empty
    }
  }, [selectedsource]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    const updatedSelection = newSelectAll
      ? totalOocytes
        .filter((oocyte) => !oocyte.isFilled) // Include only oocytes with isFilled === false
        .map((oocyte) => oocyte.embryo_number) // Extract embryo_number
      : [];
    
    setSelectedOocytes(updatedSelection); // Now updatedSelection is a number[]
    setSelectAll(newSelectAll);
    onSelectionChange(updatedSelection, dropdownValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDropdownChange = (event: any) => {
    // eslint-disable-next-line no-console
    console.log(event.target.value);
    const value = event.target.value;
    setDropdownValue(value);
    
    onSelectionChange(selectedOocytes, value);
  };

  // eslint-disable-next-line no-console
  useEffect(()=>{console.log(totalOocytes);
  },[totalOocytes]);

  return (
    <Grid2 container alignItems="center" sx={{ padding: 2, display: 'flex' }}>
      <Grid2 container size={4} alignItems="center" display={'flex'} justifyContent={'space-between'} spacing={2} >
        <Grid2 size={4}>
          <Typography variant="h6" sx={{ lineHeight: '30px', width: '10rem' }}>
            {title}
          </Typography>
        </Grid2>
        <Grid2 size={4}>
          <FormControl fullWidth>
            <InputLabel>Cycle No | Patient/Donor</InputLabel>
            <Select value={dropdownValue} onChange={handleDropdownChange} label="Cycle No | Patient/Donor">
              {(sourceList || []).map((item) => (
                <MenuItem key={item.source} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>

        <Grid2 size={4} sx={{ display: 'flex', alignItems: 'center'}}>
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAll}
            inputProps={{ 'aria-label': 'Select All' }}
          />
          <Typography component="span" sx={{ lineHeight: '30px' }}>
            {selectAll? 'Clear All': 'Select All'}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 size={8}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            justifyContent: 'flex-start',
          }}
        >
          {totalOocytes.map((oocyte) => (
            <Box
              key={oocyte.embryo_number}
              onClick={() => {
                if (!oocyte.isFilled) {
                  handleOocyteClick(oocyte.embryo_number);
                }
              }}
              sx={{
                width: 30,
                height: 30,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: oocyte.isFilled
                  ? '#fee6d1' 
                  : selectedOocytes.includes(oocyte.embryo_number)
                    ? '#EFAE78'
                    : 'transparent',
       
                border: '1px solid',
                borderColor: selectedOocytes.includes(oocyte.embryo_number) ? '#EFAE78' : '#BBBBBB',
                color: oocyte.isFilled ? '#AAAAAA' : selectedOocytes.includes(oocyte.embryo_number) ? '#fff' : '#232323', 
                fontSize: '0.8rem',
                cursor: oocyte.isFilled ? 'not-allowed' : 'pointer', 
                // pointerEvents: oocyte.isFilled ? 'none' : 'auto', 

              }}
            >
              {oocyte.embryo_number}
            </Box>
          ))}
        </Box>
        {!selectedOocytes.length && hitSubmitbtn && (
          <Box
            sx={{
              marginTop: 1,
              color: 'red',
              fontSize: '0.875rem',
            }}
          >
            Please select at least one embryo.
          </Box>
        )}
      </Grid2>
    </Grid2>
  );
};

export default OocyteSelector;
