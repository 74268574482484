import * as Yup from 'yup';

export type BlankEnum = '';
export type NullEnum = 'NULL';

export type FertilizationEnum = 'NORMAL' | 'ABNORMAL' | 'UNFERTILIZED';
export type CultureDishConfigEnum ='NORMAL_SINGLE_CULTURE'| 'EMBRYO_SLIDE_TIMELAPSE_SINGLE_CULTURE'| 'CULTURE_PRO_TIMELAPSE_SINGLE_CULTURE'| 'EMBRYO_SLIDE_IC8_DISH_TIMELAPSE_SINGLE_CULTURE'| '38_SPECIAL_GPS_SINGLE_CULTURE'| 'EMBRYO_GPS_SINGLE_CULTURE'| 'MINI_GPS_GPS_SINGLE_CULTURE'| '4_WELL_GPS_SINGLE_CULTURE'| 'U_DROP_GPS_SINGLE_CULTURE'| 'EMBRYO_CORRAL_GPS_SINGLE_CULTURE'| 'NORMAL_GROUP_CULTURE'| 'MICROWELL_GROUP_CULTURE';
export type IncubatorEnum ='HERA_CELL'| 'MINC_K_SYSTEMS'| 'MEDGYN'| 'OOSAFE'| 'VITROLIFE'| 'IVF_TECH';
export type GradeEnum ='1'| '2'| '3'| '4'| '1AA'| '1AB'| '1AC'| '1BA'| '1BB'| '1BC'| '1CA'| '1CB'| '1CC'| '2AA'| '2AB'| '2AC'| '2BA'| '2BB'| '2BC'| '2CA'| '2CB'| '2CC'| '3AA'| '3AB'| '3AC'| '3BA'| '3BB'| '3BC'| '3CA'| '3CB'| '3CC'| '4AA'| '4AB'| '4AC'| '4BA'| '4BB'| '4BC'| '4CA'| '4CB'| '4CC'| '5AA'| '5AB'| '5AC'| '5BA'| '5BB'| '5BC'| '5CA'| '5CB'| '5CC'| '6AA'| '6AB'| '6AC'| '6BA'| '6BB'| '6BC'| '6CA'| '6CB'| '6CC';
export type Action945Enum = 'CULTURE' | 'FREEZE' | 'DISCARD';
export type SymmetryEnum = 'SYMMETRY' | 'ASYMMETRY';
export type PgtOrderBiopsyCellsEnum = '5' | '6' | '7' | '8' | '9' | '10';
export type PgtFacilityEnum = 'PROGENESIS';
export type PgtResultMoistureEnum = | 'HIGH_MITOCHONDRIAL_SCORE'| 'MODERATE_MITOCHONDRIAL_SCORE'| 'LOW_MITOCHONDRIAL_SCORE'| 'NOT_TESTED';
export type PgtTypeEnum = 'PGT-A' | 'PGT-M' | 'PGT-SR';
export type PgtResultEmbryoSexEnum = 'MALE' | 'FEMALE';
export type PgtResultsEnum = 'EUPLOID' | 'ANEUPLOID' | 'MOSAIC' | 'INCONCLUSIVE' | 'NOT_TESTED';
export type MultiNucleiEnum = 'MONONUCLEI' | 'BINUCLEI' | 'MULTINUCLEI';
export type PbEnum = '1 PB' | '2 PB' | '3 PB' | '4 PB';
export type CleavageEnum = 'INTACT' | 'SYNGAMY' | 'EARLY_CLEAVAGE';
export type InseminationTechniqueEnum = 'IVF' | 'ICSI';
export type StatusDa2Enum = 'DRAFT' | 'FINALIZED';
export type CryoPurposeEnum = | 'PRESERVATION_OF_EXCESS_EMBRYOS'| 'TIMED_EMBRYO_TRANSFER'| 'FERTILITY_PRESERVATION'| 'GENETIC_TESTING'| 'FLEXIBLE_FAMILY_PLANNING'| 'REDUCING_MULTIPLE_PREGNANCIES';
export type CryoMediaEnum = 'KITAZATO' | 'SWEMED' | 'IRVINE_SCIENTIFIC';
export type PnEnum=  1 | 2 | 3 | 4
export type ActionsEnum = 'CULTURE' | 'FREEZE' | 'DISCARD' | 'TRANSFER';

export const PnItems: { label: string; value: PnEnum | BlankEnum | NullEnum }[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
];

export const cryoMediaItems: { label: string; value: CryoMediaEnum | BlankEnum | NullEnum }[] = [
  { label: 'Kitazato', value: 'KITAZATO' },
  { label: 'Swemed', value: 'SWEMED' },
  { label: 'Irvine Scientific', value: 'IRVINE_SCIENTIFIC' },
];
export const cryoPurposeItems: { label: string; value: CryoPurposeEnum | BlankEnum | NullEnum }[] = [
  { label: 'Preservation of Excess Embryos', value: 'PRESERVATION_OF_EXCESS_EMBRYOS' },
  { label: 'Timed Embryo Transfer', value: 'TIMED_EMBRYO_TRANSFER' },
  { label: 'Fertility Preservation', value: 'FERTILITY_PRESERVATION' },
  { label: 'Genetic Testing', value: 'GENETIC_TESTING' },
  { label: 'Flexible Family Planning', value: 'FLEXIBLE_FAMILY_PLANNING' },
  { label: 'Reducing Multiple Pregnancies', value: 'REDUCING_MULTIPLE_PREGNANCIES' },
];
export const statusDa2Items: { label: string; value: StatusDa2Enum | BlankEnum | NullEnum }[] = [
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Finalized', value: 'FINALIZED' },
];
export const inseminationTechniqueItems: { label: string; value: InseminationTechniqueEnum | BlankEnum | NullEnum }[] = [
  { label: 'IVF', value: 'IVF' },
  { label: 'ICSI', value: 'ICSI' },
];
export const cleavageItems: { label: string; value: CleavageEnum | BlankEnum | NullEnum }[] = [
  { label: 'Intact', value: 'INTACT' },
  { label: 'Syngamy', value: 'SYNGAMY' },
  { label: 'Early Cleavage', value: 'EARLY_CLEAVAGE' },
];
export const pbItems: { label: string; value: PbEnum | BlankEnum | NullEnum }[] = [
  { label: '1 PB', value: '1 PB' },
  { label: '2 PB', value: '2 PB' },
  { label: '3 PB', value: '3 PB' },
  { label: '4 PB', value: '4 PB' },
];
export const multiNucleiItems: { label: string; value: MultiNucleiEnum | BlankEnum | NullEnum }[] = [
  { label: 'Mononuclei', value: 'MONONUCLEI' },
  { label: 'Binuclei', value: 'BINUCLEI' },
  { label: 'Multinuclei', value: 'MULTINUCLEI' },
];
export const pgtResultsItems: { label: string; value: PgtResultsEnum | BlankEnum | NullEnum }[] = [
  { label: 'Euploid', value: 'EUPLOID' },
  { label: 'Aneuploid', value: 'ANEUPLOID' },
  { label: 'Mosaic', value: 'MOSAIC' },
  { label: 'Inconclusive', value: 'INCONCLUSIVE' },
  { label: 'Not Tested', value: 'NOT_TESTED' },
];
export const pgtResultEmbryoSexItems: { label: string; value: PgtResultEmbryoSexEnum | BlankEnum | NullEnum }[] = [
  { label: 'Male', value: 'MALE' },
  { label: 'Female', value: 'FEMALE' },
];
export const pgtTypeItems: { label: string; value: PgtTypeEnum | BlankEnum | NullEnum }[] = [
  { label: 'PGT-A', value: 'PGT-A' },
  { label: 'PGT-M', value: 'PGT-M' },
  { label: 'PGT-SR', value: 'PGT-SR' },
  { label: '', value: '' }, // BlankEnum
  { label: 'NULL', value: 'NULL' }, // NullEnum
];
export const pgtResultMoistureItems: { label: string; value: PgtResultMoistureEnum | BlankEnum | NullEnum }[] = [
  { label: 'High Mitochondrial Score', value: 'HIGH_MITOCHONDRIAL_SCORE' },
  { label: 'Moderate Mitochondrial Score', value: 'MODERATE_MITOCHONDRIAL_SCORE' },
  { label: 'Low Mitochondrial Score', value: 'LOW_MITOCHONDRIAL_SCORE' },
  { label: 'Not Tested', value: 'NOT_TESTED' },
];
export const pgtFacilityItems: { label: string; value: PgtFacilityEnum | BlankEnum | NullEnum }[] = [
  { label: 'Progenesis', value: 'PROGENESIS' },
];
export const pgtOrderBiopsyCellsItems: { label: string; value: PgtOrderBiopsyCellsEnum }[] = [
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
];
export const symmetryItems: { label: string; value: SymmetryEnum | BlankEnum | NullEnum }[] = [
  { label: 'Symmetry', value: 'SYMMETRY' },
  { label: 'Asymmetry', value: 'ASYMMETRY' },
];
export const action945Items: { label: string; value: Action945Enum |BlankEnum }[] = [
  // { label: 'Select Action', value: '' },
  { label: 'Culture', value: 'CULTURE' },
  { label: 'Freeze', value: 'FREEZE' },
  { label: 'Discard', value: 'DISCARD' },
];
export const gradeItems: { label: string; value: GradeEnum | BlankEnum | NullEnum }[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '1AA', value: '1AA' },
  { label: '1AB', value: '1AB' },
  { label: '1AC', value: '1AC' },
  { label: '1BA', value: '1BA' },
  { label: '1BB', value: '1BB' },
  { label: '1BC', value: '1BC' },
  { label: '1CA', value: '1CA' },
  { label: '1CB', value: '1CB' },
  { label: '1CC', value: '1CC' },
  { label: '2AA', value: '2AA' },
  { label: '2AB', value: '2AB' },
  { label: '2AC', value: '2AC' },
  { label: '2BA', value: '2BA' },
  { label: '2BB', value: '2BB' },
  { label: '2BC', value: '2BC' },
  { label: '2CA', value: '2CA' },
  { label: '2CB', value: '2CB' },
  { label: '2CC', value: '2CC' },
  { label: '3AA', value: '3AA' },
  { label: '3AB', value: '3AB' },
  { label: '3AC', value: '3AC' },
  { label: '3BA', value: '3BA' },
  { label: '3BB', value: '3BB' },
  { label: '3BC', value: '3BC' },
  { label: '3CA', value: '3CA' },
  { label: '3CB', value: '3CB' },
  { label: '3CC', value: '3CC' },
  { label: '4AA', value: '4AA' },
  { label: '4AB', value: '4AB' },
  { label: '4AC', value: '4AC' },
  { label: '4BA', value: '4BA' },
  { label: '4BB', value: '4BB' },
  { label: '4BC', value: '4BC' },
  { label: '4CA', value: '4CA' },
  { label: '4CB', value: '4CB' },
  { label: '4CC', value: '4CC' },
  { label: '5AA', value: '5AA' },
  { label: '5AB', value: '5AB' },
  { label: '5AC', value: '5AC' },
  { label: '5BA', value: '5BA' },
  { label: '5BB', value: '5BB' },
  { label: '5BC', value: '5BC' },
  { label: '5CA', value: '5CA' },
  { label: '5CB', value: '5CB' },
  { label: '5CC', value: '5CC' },
  { label: '6AA', value: '6AA' },
  { label: '6AB', value: '6AB' },
  { label: '6AC', value: '6AC' },
  { label: '6BA', value: '6BA' },
  { label: '6BB', value: '6BB' },
  { label: '6BC', value: '6BC' },
  { label: '6CA', value: '6CA' },
  { label: '6CB', value: '6CB' },
  { label: '6CC', value: '6CC' },
];
export const incubatorItems: { label: string; value: IncubatorEnum | BlankEnum | NullEnum }[] = [
  { label: 'Hera Cell', value: 'HERA_CELL' },
  { label: 'MINC/K Systems', value: 'MINC_K_SYSTEMS' },
  { label: 'MedGyn', value: 'MEDGYN' },
  { label: 'Oosafe', value: 'OOSAFE' },
  { label: 'Vitrolife', value: 'VITROLIFE' },
  { label: 'IVF Tech', value: 'IVF_TECH' },
];
export const cultureDishConfigItems: { label: string; value: CultureDishConfigEnum | BlankEnum | NullEnum }[] = [
  { label: 'Normal Single Culture', value: 'NORMAL_SINGLE_CULTURE' },
  { label: 'Embryo Slide Timelapse Single Culture', value: 'EMBRYO_SLIDE_TIMELAPSE_SINGLE_CULTURE' },
  { label: 'Culture Pro Timelapse Single Culture', value: 'CULTURE_PRO_TIMELAPSE_SINGLE_CULTURE' },
  { label: 'Embryo Slide + ic8 Dish Timelapse Single Culture', value: 'EMBRYO_SLIDE_IC8_DISH_TIMELAPSE_SINGLE_CULTURE' },
  { label: '38 Special GPS Single Culture', value: '38_SPECIAL_GPS_SINGLE_CULTURE' },
  { label: 'Embryo GPS Single Culture', value: 'EMBRYO_GPS_SINGLE_CULTURE' },
  { label: 'Mini GPS GPS Single Culture', value: 'MINI_GPS_GPS_SINGLE_CULTURE' },
  { label: '4 Well GPS Single Culture', value: '4_WELL_GPS_SINGLE_CULTURE' },
  { label: 'μ Drop GPS Single Culture', value: 'U_DROP_GPS_SINGLE_CULTURE' },
  { label: 'Embryo Corral GPS Single Culture', value: 'EMBRYO_CORRAL_GPS_SINGLE_CULTURE' },
  { label: 'Normal Group Culture', value: 'NORMAL_GROUP_CULTURE' },
  { label: 'Microwell Group Culture', value: 'MICROWELL_GROUP_CULTURE' },
];
export const fertilizationItems: { label: string; value: FertilizationEnum | BlankEnum | NullEnum }[] = [
  { label: 'Normal', value: 'NORMAL' },
  { label: 'Abnormal', value: 'ABNORMAL' },
  { label: 'Unfertilized', value: 'UNFERTILIZED' },
];
export const pgtorderDetailsItems: { label: string; value: string }[] = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
];

export type FragmentationPercentageEnum = '< 15' | '15-30' | '30-45' | '> 45';
export const fragmentationPercentageItems: { label: string; value: FragmentationPercentageEnum | '' }[] = [
  { label: '< 15', value: '< 15' },
  { label: '15-30', value: '15-30' },
  { label: '30-45', value: '30-45' },
  { label: '> 45', value: '> 45' },
];

export const actionsItems: { label: string; value: ActionsEnum |BlankEnum }[] = [
  { label: 'Culture', value: 'CULTURE' },
  { label: 'Freeze', value: 'FREEZE' },
  { label: 'Discard', value: 'DISCARD' },
  { label: 'Transfer', value: 'TRANSFER' },
];

export interface EmbryoDetail {
  embryo_number: number;
  isFilled: boolean;
}
export interface EmbryoCulture {
  description?: string; // Base serializer description
  embryologist?: number | null; // Nullable integer for embryologist
  witness?: number | null; // Nullable integer for witness
  cycle: number; // Required integer for cycle
  remarks?: string | null; // Nullable string for remarks
  fertilization?: FertilizationEnum | BlankEnum | NullEnum | null; // Nullable fertilization enum
  is_assisted_hatching: boolean; // Required boolean for assisted hatching
  culture_dish_config?: CultureDishConfigEnum | BlankEnum | NullEnum | null; // Nullable culture dish config enum
  day: number; // Required integer with minimum 0 and maximum 2147483647
  time: string; // Required time in string format ($time)
  incubator?: IncubatorEnum | BlankEnum | NullEnum | null; // Nullable incubator enum
}
export interface source_list {
  source: number;
  thaw_cycle: number;
  cycle: number;
  name: string;
}
export interface UpdateEmbryo {
  cycle: number;
  embryo_number: number;
  grade: string | null;
  fragmentation_percentage: number | null;
  number_of_cells: number | null;
  action: Action945Enum | BlankEnum;
  symmetry: string | null;
  files: string[];

  pgt_order_date: string;
  pgt_order_time: string;
  pgt_order_biopsy_cells: PgtOrderBiopsyCellsEnum | null;
  pgt_facility: PgtFacilityEnum | null;
  pgt_result_moisture: PgtResultMoistureEnum | null;
  pgt_result_notes: string;
  pgt_order_details:string
  pgt_result:string
  pgt_type: PgtTypeEnum | null;
  pgt_result_embryo_sex: PgtResultEmbryoSexEnum | null;
  pgt_results: PgtResultsEnum | null;
  multi_nuclei: MultiNucleiEnum | null;
  pn: number;
  pb: PbEnum | null;
  cleavage: CleavageEnum | null;
  insemination_technique: InseminationTechniqueEnum | null;
  sperm_source: string[];
  day: number;
  status: StatusDa2Enum;
  cryo_preserved_date: string;
  cryo_preserved_by: string;
  cryo_purpose: CryoPurposeEnum | null;
  cryo_media: CryoMediaEnum | null;
  is_screened: boolean;
  source?:number | null;
  thaw_cycle?:number | null
}

export const embryoCultureInitialValues:EmbryoCulture = {
  description: '', // Optional description can be an empty string
  embryologist: null, // Nullable, can be null
  witness: null, // Nullable, can be null
  cycle: 0, // Required integer, can be empty initially (if user will input later)
  remarks: '', // Nullable remarks can be an empty string
  fertilization: '', // Nullable, can be an empty string or 'NULL'
  is_assisted_hatching: false, // Default to false for boolean field
  culture_dish_config: '', // Nullable, can be an empty string or 'NULL'
  day: 0, // Required, can be empty initially
  time: '', // Required, can be empty initially
  incubator: '', // Nullable, can be an empty string or 'NULL'
};


export const embryoInitialValues:UpdateEmbryo = {
  cycle: 0, // Required integer, can be empty initially
  embryo_number: 0, // Required integer, can be empty initially
  grade: '', // Nullable, can be an empty string or a valid grade from the list
  fragmentation_percentage: null, // Nullable, can be null
  number_of_cells: null, // Nullable, can be null
  action: '', // Required, can be one of 'CULTURE', 'FREEZE', or 'DISCARD'
  symmetry: '', // Nullable, can be an empty string
  files: [], // At least one file is required, but initially can be an empty array
  pgt_order_date: '', // Required, can be an empty string
  pgt_order_details:'',
  pgt_result:'',
  pgt_order_time: '', // Required, can be an empty string
  pgt_order_biopsy_cells: null, // Nullable, can be null
  pgt_facility: null, // Nullable, can be null
  pgt_result_moisture: null, // Nullable, can be null
  pgt_result_notes: '', // Nullable, can be an empty string
  pgt_type: null, // Nullable, can be null
  pgt_result_embryo_sex: null, // Nullable, can be null
  pgt_results: null, // Nullable, can be null
  multi_nuclei: null, // Nullable, can be null
  pn: 0, // Required integer, can be empty initially
  pb: null, // Nullable, can be null
  cleavage: null, // Nullable, can be null
  insemination_technique: null, // Nullable, can be null
  sperm_source: [], // At least one sperm source is required, but initially can be an empty array
  day: 0, // Required, can be empty initially
  status: 'DRAFT', // Default to 'DRAFT', required
  cryo_preserved_date: '', // Required, can be empty initially
  cryo_preserved_by: '', // Required, can be empty initially
  cryo_purpose: null, // Nullable, can be null
  cryo_media: null, // Nullable, can be null
  is_screened: false, // Default to false for boolean field
};
export const EmbryoCultureValidationSchema = Yup.object().shape({
  description: Yup.string().nullable(), // Optional description
  embryologist: Yup.number().nullable().integer(), // Nullable integer for embryologist
  witness: Yup.number().nullable().integer(), // Nullable integer for witness
  cycle: Yup.number().integer(), // Required integer
  remarks: Yup.string().nullable(), // Nullable remarks
  fertilization: Yup.mixed<FertilizationEnum | BlankEnum | NullEnum>()
    .oneOf(['NORMAL', 'ABNORMAL', 'UNFERTILIZED', '', 'NULL'])
    .nullable(), // Valid values include FertilizationEnum, BlankEnum, and NullEnum
  is_assisted_hatching: Yup.boolean(), // Required boolean
  culture_dish_config: Yup.mixed<CultureDishConfigEnum | BlankEnum | NullEnum>()
    .oneOf([
      'NORMAL_SINGLE_CULTURE',
      'EMBRYO_SLIDE_TIMELAPSE_SINGLE_CULTURE',
      'CULTURE_PRO_TIMELAPSE_SINGLE_CULTURE',
      'EMBRYO_SLIDE_IC8_DISH_TIMELAPSE_SINGLE_CULTURE',
      '38_SPECIAL_GPS_SINGLE_CULTURE',
      'EMBRYO_GPS_SINGLE_CULTURE',
      'MINI_GPS_GPS_SINGLE_CULTURE',
      '4_WELL_GPS_SINGLE_CULTURE',
      'U_DROP_GPS_SINGLE_CULTURE',
      'EMBRYO_CORRAL_GPS_SINGLE_CULTURE',
      'NORMAL_GROUP_CULTURE',
      'MICROWELL_GROUP_CULTURE',
      '',
      'NULL',
    ])
    .nullable(), // Valid values include CultureDishConfigEnum, BlankEnum, and NullEnum
  day: Yup.number().min(0).max(2147483647).integer(), // Required day with range
  time: Yup.string(), // Required time
  incubator: Yup.mixed<IncubatorEnum | BlankEnum | NullEnum>()
    .oneOf(['HERA_CELL', 'MINC_K_SYSTEMS', 'MEDGYN', 'OOSAFE', 'VITROLIFE', 'IVF_TECH', '', 'NULL'])
    .nullable(), // Valid values include IncubatorEnum, BlankEnum, and NullEnum
});

export const EmbryoValidationSchema = Yup.object().shape({
  cycle: Yup.number().positive('Cycle must be a positive number'),
  embryo_number: Yup.number().positive('Embryo number must be a positive number'),
  grade: Yup.string().nullable().oneOf(['1', '2', '3', '4', '1AA', '1AB', '1AC', '1BA', '1BB', '1BC', '1CA', '1CB', '1CC', '2AA', '2AB', '2AC', '2BA', '2BB', '2BC', '2CA', '2CB', '2CC', '3AA', '3AB', '3AC', '3BA', '3BB', '3BC', '3CA', '3CB', '3CC', '4AA', '4AB', '4AC', '4BA', '4BB', '4BC', '4CA', '4CB', '4CC', '5AA', '5AB', '5AC', '5BA', '5BB', '5BC', '5CA', '5CB', '5CC', '6AA', '6AB', '6AC', '6BA', '6BB', '6BC', '6CA', '6CB', '6CC'], 'Invalid grade'),
  fragmentation_percentage: Yup.number().nullable().min(0, 'Fragmentation percentage must be greater than or equal to 0').max(100, 'Fragmentation percentage must be less than or equal to 100'),
  number_of_cells: Yup.number().nullable().positive('Number of cells must be a positive number'),
  action: Yup.mixed<Action945Enum | BlankEnum>().oneOf(['CULTURE', 'FREEZE', 'DISCARD', ''], 'Invalid action').required(),
  symmetry: Yup.string().nullable(),
  files: Yup.array().of(Yup.string()).min(1, 'At least one file is required'),
  pgt_order_date: Yup.string(),
  pgt_order_time: Yup.string(),
  pgt_order_biopsy_cells: Yup.mixed<PgtOrderBiopsyCellsEnum>().nullable().oneOf(['5', '6', '7', '8', '9', '10'], 'Invalid biopsy cell value'),
  pgt_facility: Yup.mixed<PgtFacilityEnum>().nullable().oneOf(['PROGENESIS'], 'Invalid facility'),
  pgt_result_moisture: Yup.mixed<PgtResultMoistureEnum>().nullable().oneOf(['HIGH_MITOCHONDRIAL_SCORE', 'MODERATE_MITOCHONDRIAL_SCORE', 'LOW_MITOCHONDRIAL_SCORE', 'NOT_TESTED'], 'Invalid moisture result'),
  pgt_result_notes: Yup.string().nullable(),
  pgt_type: Yup.mixed<PgtTypeEnum>().nullable().oneOf(['PGT-A', 'PGT-M', 'PGT-SR'], 'Invalid PGT type'),
  pgt_result_embryo_sex: Yup.mixed<PgtResultEmbryoSexEnum>().nullable().oneOf(['MALE', 'FEMALE'], 'Invalid embryo sex'),
  pgt_results: Yup.mixed<PgtResultsEnum>().nullable().oneOf(['EUPLOID', 'ANEUPLOID', 'MOSAIC', 'INCONCLUSIVE', 'NOT_TESTED'], 'Invalid PGT results'),
  multi_nuclei: Yup.mixed<MultiNucleiEnum>().nullable().oneOf(['MONONUCLEI', 'BINUCLEI', 'MULTINUCLEI'], 'Invalid multi nuclei value'),
  pn: Yup.number().positive('PN must be a positive number'),
  pb: Yup.mixed<PbEnum>().nullable().oneOf(['1 PB', '2 PB', '3 PB', '4 PB'], 'Invalid PB value'),
  cleavage: Yup.mixed<CleavageEnum>().nullable().oneOf(['INTACT', 'SYNGAMY', 'EARLY_CLEAVAGE'], 'Invalid cleavage value'),
  insemination_technique: Yup.mixed<InseminationTechniqueEnum>().nullable().oneOf(['IVF', 'ICSI'], 'Invalid insemination technique'),
  sperm_source: Yup.array().of(Yup.string()).min(1, 'At least one sperm source is required'),
  day: Yup.number().positive('Day must be a positive number'),
  status: Yup.mixed<StatusDa2Enum>().oneOf(['DRAFT', 'FINALIZED'], 'Invalid status'),
  cryo_preserved_date: Yup.string(),
  cryo_preserved_by: Yup.string(),
  cryo_purpose: Yup.mixed<CryoPurposeEnum>().nullable().oneOf(['PRESERVATION_OF_EXCESS_EMBRYOS', 'TIMED_EMBRYO_TRANSFER', 'FERTILITY_PRESERVATION', 'GENETIC_TESTING', 'FLEXIBLE_FAMILY_PLANNING', 'REDUCING_MULTIPLE_PREGNANCIES'], 'Invalid cryo purpose'),
  cryo_media: Yup.mixed<CryoMediaEnum>().nullable().oneOf(['KITAZATO', 'SWEMED', 'IRVINE_SCIENTIFIC'], 'Invalid cryo media'),
  is_screened: Yup.boolean(),
});

export const CombinedValidationSchema = EmbryoCultureValidationSchema.concat(EmbryoValidationSchema);


export const mockCycleData = {
  cycle_id: '123',
  cycle_number: 'CYCLE-001',
  allData: [
    { day: 0, details: { info: 'All Day data for Cycle 001' }, isFilled: true },
    { day: 1, details: { info: 'Day 1 details for Cycle 001' }, isFilled: false },
    { day: 2, details: { info: 'Day 2 details for Cycle 001' }, isFilled: false },
    { day: 3, details: { info: 'Day 3 details for Cycle 001' }, isFilled: true },
    { day: 4, details: { info: 'Day 4 details for Cycle 001' }, isFilled: false },
    { day: 5, details: { info: 'Day 5 details for Cycle 001' }, isFilled: true },
    { day: 6, details: { info: 'Day 6 details for Cycle 001' }, isFilled: false },
    { day: 7, details: { info: 'Day 7 details for Cycle 001' }, isFilled: true },
  ],
  day0: { details: { info: 'Details for Day 0' }, isFilled: true },
  day1: { details: { info: 'Details for Day 1' }, isFilled: false },
  day2: { details: { info: 'Details for Day 2' }, isFilled: false },
  day3: { details: { info: 'Details for Day 3' }, isFilled: true },
  day4: { details: { info: 'Details for Day 4' }, isFilled: false },
  day5: { details: { info: 'Details for Day 5' }, isFilled: true },
  day6: { details: { info: 'Details for Day 6' }, isFilled: false },
  day7: { details: { info: 'Details for Day 7' }, isFilled: true },
};
  
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchMockCycleDetails = (cycleId: number) => {
  // Simulate a delay as if fetching from an API
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockCycleData);
    }, 1000);
  });
};
  

export const columns = [
  { label: 'Embryo No.', field: 'embryoNo', editable: false },
  { label: 'Image', field: 'image', editable: false },
  { label: 'Sperm Source', field: 'spermSource', editable: true },
  { label: 'Insemination Technique', field: 'inseminationTechnique', editable: true },
  { label: 'Action', field: 'action', editable: false }, // Action column is special
  { label: 'Freeze Location', field: 'freezeLocation', editable: true },
];

export const rows = [
  {
    embryoNo: 'S_P',
    image: 'https://via.placeholder.com/50',
    spermSource: 'Donor',
    inseminationTechnique: 'Culture',
    action: 'Culture',
    freezeLocation: '-',
  },
  {
    embryoNo: 'S_D',
    image: 'https://via.placeholder.com/50',
    spermSource: 'Self',
    inseminationTechnique: 'Discard',
    action: 'Discard',
    freezeLocation: '-',
  },
  {
    embryoNo: 'D1_D',
    image: 'https://via.placeholder.com/50',
    spermSource: 'Partner',
    inseminationTechnique: 'Freeze',
    action: 'Freeze',
    freezeLocation: 'Tank 1 | Canister 2 | Cane 1 | Goblet | Cryolock',
  },
  {
    embryoNo: 'D2_D',
    image: 'https://816069151152-vidai-stage-apsouth1.s3.amazonaws.com/uploads/PROFILE_PHOTO/799/profile?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA34AMDBGYIFJIICQX%2F20241230%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241230T095713Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELD%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEA8NOZTybpNpoqYQHCu%2BZftqUWVnplBd%2F0xKq9bjYMDkMCIGPVDfM4sLM0ZHANJNbGWuW2up7kPA6aP%2BHBObEdG%2F9lKucECIn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMODE2MDY5MTUxMTUyIgxmMycjXBlk%2Bnz2PYIquwSWzru2YkyEF4MGXVCbI2Jg7302dGy02gNKR%2B%2FfAcBSY1vVSvnMg9tHst6b5z1ZwuqGBHhmH%2FearClg2cqpuD6lgPS3%2BlOQo32eXY4e%2Br8ImL8FQ5JMjBXGGE5g1d4Ox%2BJj6XEpEbpubPcEE1wYBVRgDCKipLYNwP82Y2hwUMxVd%2BtY2M01USF%2BrjU%2BfY%2BdYo8ktGBHhe0BtcYJ0SvFzqsdUUTpQochxIUDmKqDUlcGQLzyX8mdZn7WkTpPn0exKg%2BGtyTvxyUcez%2BIAlgVpeLwND7LN36QMpj%2FBRlv9O%2FNgb47hXWvL9VmX%2Bmcj5zMCi5uLQXyrlDCisOE8qH1siV2AT5wm8Mh0fBWWwjJgvXBUXI3vBiSs%2FacaOz6SGgpy6UyS0vmuQ52SYuErmYeVZGehfdDt6WBOQmW2biOpr4OOIJlYURVB9kSheCsX6k1ceKIQJtqtm%2FzFtNLXkcbo7v68lUwogrGY9Ble%2FfKbjvO3z%2F5zyyTIz%2BWEm8GeYhJ%2FBAX%2BqlPWZKfG5gCZRo32B404kpzT8qzhGobwQICKWmkGU2sQs4hPxu2klUp5sjZmcB8Iy48UVzQrxKHC4AS2h0IFFpR1nC6cob1LqM1KlOx49VQCIAdM0fpLHAZBuRkUNbw2OpITcm%2BBm2SfNPRsVYpYnD2vpJa%2BgsDq7YzdWEUwaI7gaCViVj1u7vVgadQG3%2BhVA%2F%2BYtzLBtuhDvyHCItF2hsZ5ZBEmBDchJiLGta4r22SAug%2BUyDs6CbLMLedybsGOqcBWvwea5mgjRyGiT05lFIEaWJjK1h7ey%2FYtO5FAvuec96HaaIWTV77jCkmHlCr6T6GG7maAXb8wBkN5OVRVZIIqKlxIeqUCcAlE%2BJJi2nl8Jw6x4RAn891erg6GAWYln2yi3m2xh29CsV7NVugfb7%2BnO8w5oI1EOqDvhOaRnqua%2B3ShAnbo%2B7reD69Kd%2B4V6wx0LhAvRvFXFOVPP2fiKx6BF0j%2FyiRGlU%3D&X-Amz-Signature=b91fc9b5d1fa6c78f424b7e27862c09c6b838423b2ab9cdaaeba472aefb15b00',
    spermSource: 'Donor',
    inseminationTechnique: 'Transfer',
    action: 'Transfer',
    freezeLocation: '-',
  },
];


export type CellsEnum = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '>8' | '9' | '10' | 'Compaction' | 'Morula' | 'Cavitating Blastocyst';

export const cellItems: { label: string; value: CellsEnum | BlankEnum | NullEnum }[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '>8', value: '>8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: 'Compaction', value: 'Compaction' },
  { label: 'Morula', value: 'Morula' },
  { label: 'Cavitating Blastocyst', value: 'Cavitating Blastocyst' },
];

export type StageEnum = 
  | 'EARLY_BLASTOCYST'
  | 'BLASTOCYST'
  | 'FULL_BLASTOCYST'
  | 'EXPANDED_BLASTOCYST'
  | 'HATCHING_BLASTOCYST'
  | 'HATCHED_BLASTOCYST'
  | 'COMPACTION'
  | 'CAVITATION'
  | 'CLEAVAGE';

export const stageItems: { label: string; value: StageEnum }[] = [
  { label: 'Early Blastocyst', value: 'EARLY_BLASTOCYST' },
  { label: 'Blastocyst', value: 'BLASTOCYST' },
  { label: 'Full Blastocyst', value: 'FULL_BLASTOCYST' },
  { label: 'Expanded Blastocyst', value: 'EXPANDED_BLASTOCYST' },
  { label: 'Hatching Blastocyst', value: 'HATCHING_BLASTOCYST' },
  { label: 'Hatched Blastocyst', value: 'HATCHED_BLASTOCYST' },
  { label: 'Compaction', value: 'COMPACTION' },
  { label: 'Cavitation', value: 'CAVITATION' },
  { label: 'Cleavage', value: 'CLEAVAGE' },
];



// Validation schema for the Freeze dialog fields
export const freezeValidationSchema = Yup.object({
  cryo_preserved_date: Yup.string()
    .nullable()
    .required('Cryo Date is required'),
  cryo_preserved_by: Yup.number()
    .nullable()
    .required('Cryo Preserved By is required')
    .min(1, 'Must be a valid ID'),
  cryo_purpose: Yup.string()
    .nullable()
    .required('Cryo Purpose is required'),
});

export interface FreezeDialogValues {
  cryo_preserved_date?: string | null;
  cryo_preserved_by?: number | null;
  cryo_purpose?: string | null;
  cryo_media?: string | null;
  is_screened?: boolean | null;
}

export const initialValuesCryo: FreezeDialogValues = {
  cryo_preserved_date: null,         
  cryo_preserved_by: null,          
  cryo_purpose: null,           
  cryo_media: null,           
  is_screened: null,   
};

export interface EmbryoOocyteFilledStatus {
  embryo_number: number;
  isFilled: boolean;
}

export interface EmbryoFinilizeUpdateType {
  embryos: number[];             
  cycle: number;  
  day: number; 
  status: StatusDa2Enum | BlankEnum; 
  source:number | null,
  thaw_cycle?:number | null,
}

export interface SourceItems {
  source: number;
  thaw_cycle: number | null;
  cycle: number;
  name: string;
}


export const spermSourceList: { label: string; value: string }[] = [
  { label: 'Partner', value: 'PARTNER' },
  { label: 'Donor', value: 'DONOR' },
];