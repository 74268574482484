import React, { useEffect, useState } from 'react';
import { ThemeProvider, Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material';
import { theme } from '../../Medical_History_Forms/themeprovider';
import Grid from '@mui/material/Grid2';
import { convertUnderscoreToTitle, infoTextStyle, sectionTitleStyle } from '../../../utils/commonUtils';
import { useLoader } from '../../loaderProvider/loader';
import styles from '../oocyteRetrieval/oocyteRetrival.module.css';
import { getSurgicalSpermRetrieval } from './spermRetrieval.helper';
import { Close as CloseIcon } from '@mui/icons-material';

interface LaparoscopyViewDetailsProps {
  record: number; 
  onBack: () => void;
  onEdit?: () => void; 
}

const SurgicalSpermRetrievalDetails: React.FC<LaparoscopyViewDetailsProps> = ({ record, onBack, onEdit }) => {
  const { showLoader, hideLoader } = useLoader();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [retrievalData, setRetrievalData] = useState<any>(null);

  const [open, setOpen] = useState(false); // State to control lightbox visibility
  const [imageSrc, setImageSrc] = useState(''); // State to store the image source

  useEffect(() => {
    if (!record) return;

    showLoader();
    getSurgicalSpermRetrieval(record)
      .then((data) => setRetrievalData(data))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching Oocyte Retrieval data');
        setRetrievalData([]); // Set to default on error
      })
      .finally(() => hideLoader());
  }, [record, showLoader, hideLoader]);

  
  // Function to handle the click on the image preview
  const handleImageClick = (src:string) => {
    setImageSrc(src);
    setOpen(true); // Open the lightbox
  };

  // Function to handle closing the lightbox
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={4} mb={3}>
           
          <Box sx={{ width: '100%'}}>
            <Typography variant="h6" sx={{ ...infoTextStyle }}>
          Details
            </Typography>
          </Box>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Date</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.date  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Time</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.time  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Indication</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.indication)  || '-'}
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Clinician</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.clinician_name  || '-'} 
            </Typography>
          </Grid>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Specimen Type</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.specimen_type) || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Method of Surgical sperm retrieval</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.method_of_surgical_sperm_retrieval || '-'}
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Surgeon</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.surgeon_name) || '-'}
            </Typography>
          </Grid>
          
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Anesthetist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { retrievalData?.anesthetist_name || '-'}
            </Typography>
          </Grid>


          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Anesthesia</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.anesthesia)  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Andrologist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { retrievalData?.andrologist_name  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Witness Andrologist</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { retrievalData?.witness_andrologist_name  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Site</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              { convertUnderscoreToTitle(retrievalData?.site)  || '-'} 
            </Typography>
          </Grid>

          <Tooltip title="Click to preview" arrow>
            <div
              onClick={() => handleImageClick(retrievalData?.image_path_url || '')}
              style={{ cursor: 'pointer', position: 'relative' }}
            >
              <img
                src={retrievalData?.image_path_url || 'default-icon.png'}
                alt="file"
                style={{
                  width: 36,
                  height: 36,
                  borderRadius: '50%',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Add transition for smooth effect
                }}

                onMouseOver={(e) => {
                  const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                  target.style.transform = 'scale(1.1)';
                  target.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)';
                }}
                onMouseOut={(e) => {
                  const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                  target.style.transform = 'scale(1)';
                  target.style.boxShadow = 'none';
                }}
              />
            </div>
          </Tooltip>


          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            sx={{
              width: '55%', 
              margin: 'auto', // Center the dialog
            }}
          >
            <DialogTitle sx={{ position: 'relative' }}>
        Image Preview
              {/* Close Icon at the Right Corner of the Dialog Title */}
              <IconButton
                onClick={handleClose}
                color="primary"
                sx={{
                  position: 'absolute',
                  top: 10,
                  right: 4,
                  padding: '8px',
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src={imageSrc}
                alt="Large Preview"
                style={{
                  maxWidth: '100%', // Ensure image width doesn't exceed the dialog width
                  maxHeight: '100%', // Ensure image height doesn't exceed the dialog height
                  objectFit: 'contain', // Maintain aspect ratio
                }}
              />
            </DialogContent>
            <DialogActions>
              <IconButton onClick={handleClose} color="primary">
          Close
              </IconButton>
            </DialogActions>
          </Dialog>    
          
        </Grid>


        <TableContainer>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  height: '2rem',
                  background: 'rgba(250, 250, 250, 1)',
                  border: 'none',
                  '& .MuiTableCell-root': {
                    padding: '0.6rem 0.6rem',
                  },
                }}
              >
                {['Site', 'Sperm Count', 'Motility', 'Remark'].map((title, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      fontFamily: 'Nunito, sans-serif',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      lineHeight: '1.45',
                      textAlign: 'left',
                      color: 'rgba(35, 35, 35, 1)',
                    }}
                  >
                    {title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={1}>
                <TableCell>Right</TableCell>
                <TableCell>{retrievalData?.right_sperm_count || '-'}</TableCell>
                <TableCell>{retrievalData?.right_motility || '-'}</TableCell>
                <TableCell>{retrievalData?.right_remark || '-'}</TableCell>
              </TableRow>
              <TableRow key={2}>
                <TableCell>Left</TableCell>
                <TableCell>{retrievalData?.left_sperm_count || '-'}</TableCell>
                <TableCell>{retrievalData?.left_motility || '-'}</TableCell>
                <TableCell>{retrievalData?.left_remark || '-'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>


        <Grid container spacing={4} mb={3} mt={2}>
          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Complications</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.complications  || '-'} 
            </Typography>
          </Grid>

          <Grid size={3} sx={{ textAlign: 'left' }}>
            <Typography variant="body1" sx={sectionTitleStyle}>Remark</Typography>
            <Typography variant="body2" sx={infoTextStyle}>
              {retrievalData?.remarks  || '-'} 
            </Typography>
          </Grid>
        </Grid>


        <Box className={styles.customButtonDiv}>
          <button
            type="button"
            className={styles.resetButton}
            onClick={onBack}
          >
   Back to List View
          </button>

          <button
            type="button"
            className={styles.customButton}
            onClick={onEdit}
          >
            <span style={{ fontWeight: 'bold' }}>
      Edit
            </span>
          </button>

        </Box>

      </Box>
    </ThemeProvider>
  );
};

export default SurgicalSpermRetrievalDetails;
