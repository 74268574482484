
import { getRequest, putRequest } from '../../services/httpService';

export interface CryoStorageDetail {
    id: number;
    cycle: number;
    oocyte_number: number;
    grade: string;
    location: string | null;
    disposition: string;
    files: string | null;
    cryo_preserved_date: string | null;
    cryo_preserved_by: number;
    cryo_purpose: string;
    cryo_media: string | null;
    is_screened: boolean;
    cycle_number: string;
    patient_name: string;
    patient_mrn: string;
    patient_type: string;
    cryo_preserved_by_name: string;
    material_count?: number | null;
    created_on?: string | null;
    age?: string | null;
    renewalDate?: string | null;
    embryo_number?: string | null;
    patient_last_name?: string | null;   
    renewal_date?: string ;        
  }
   
export interface Meta {
    limit: number;
    next: string | null;
    offset: number;
    previous: string | null;
    total_count: number;
  }
  
export interface spermCryoStorageDetail {
    id: number;
    cycle: number;
    cryo_date: string;
    location: number;
    media_volume: string;
    semen_volume: string;
    expiry_date: string | null;
    remark: string | null;
    thaw_date: string | null;
    thaw_time: string | null;
    is_survived: boolean | null;
    plan: string | null;
    doctor: string | null;
    witness: string | null;
    reason: string | null;
    thaw_remark: string | null;
    recipient: string | null;
    status: string;
    cycle_number: string;
    patient_name: string;
    patient_mrn: string;
    cryo_preserved_by_name: string;
    sample_id: number;
    type_of_sperm: string;
    volume: number;
    sperm_concentration_million_per_ml: number;
    total_sperm_count: number;
    all_progressive: number;
    total_motility: number;
    immotile_percent: number;
    non_progressive_percent: number;
    cryo_media: string | null;
    material_count?: number | null;
    renewal_date?: string ;
    embryo_number?: string | null;  
    patient_last_name?: string | null;  
    
  }
  

export interface spermCryoStorageResponse {
    meta: {
      limit: number;
      next: string | null;
      offset: number;
      previous: string | null;
      total_count: number;
    };
    objects: spermCryoStorageDetail[];
  }
  
  
  
export interface CryoStorageResponse {
    meta: {
      limit: number;
      next: string | null;
      offset: number;
      previous: string | null;
      total_count: number;
    };
    objects: CryoStorageDetail[];
  }
  
  
export function getOocyteStorage(): Promise<CryoStorageResponse> {
  const url = 'cryo-storage/oocyte/';
  return getRequest(url);
}
    
export function getDonorOocyteStorage(): Promise<CryoStorageResponse> {
  const url = 'cryo-storage/oocyte/?patient_type=OOCYTE_DONOR';
  return getRequest(url);
}
    
export function getEmbryoStorage(): Promise<CryoStorageResponse> {
  const url = 'cryo-storage/embryo/';
  return getRequest(url); 
}
    

export function getSpermStorage(): Promise<spermCryoStorageResponse> {
  const url = 'cryo-storage/';
  return getRequest(url); 
}

export function getDonorSpermStorage(): Promise<spermCryoStorageResponse> {
  const url = 'cryo-storage/?patient_type=SPERM_DONOR';
  return getRequest(url); 
}

export interface CryoStorageResponse {
    meta: {
      limit: number;
      next: string | null;
      offset: number;
      previous: string | null;
      total_count: number;
    };
    objects: CryoStorageDetail[];
  }
  

  
export function getMaterialsDetails(cycleID : number ): Promise<CryoStorageDetail[]> {
  const url = `cryo-storage/oocyte/materials/cycle/${cycleID}/`;
  return getRequest(url); 
}


export function getEmbryoMaterialsDetails(cycleID : number ): Promise<CryoStorageDetail[]> {
  const url = `cryo-storage/embryo/materials/cycle/${cycleID}/`;
  return getRequest(url); 
}


export function getSpermMaterialsDetails(cycleID : number ): Promise<spermCryoStorageDetail[]> {
  const url = `cryo-storage/semen/materials/cycle/${cycleID}/`;
  return getRequest(url); 
}



interface RenewalDatePayload {
  id: number;
  renewal_date: string;
  type: string;
}


export function updateRenewalDate(data: RenewalDatePayload): Promise<void> {
  const url = 'cryo-storage/renewal-date/';
  return putRequest(url, data, { responseType: 'json' });
}

