import { getRequest } from '../../../services/httpService';




export interface Oocyte {
    id: number;
    cycle: number;
    oocyte_number: number;
    grade: string;
    location: string | null;
    disposition:  string | null;
    fertilization_technique: string | null;
    sperm_source: string | null;
}

export interface OocyteProcessing {
    id: number;
    embryologist_name: string;
    witness_name: string;
    embryologist: number;
    witness: number;
    oocyte_count: number;
    cycle: number;
    denuded_by: number;
    denuded_start_time: string;
    denuded_end_time: string;
    denuded_technique: string;
    remarks: string | null;
    incubator: string;
    denuded_date: string;
    denuded_by_name: string;
    freezed_count: number;
    maturation_count: number;
    discard_count: number;
    oocytes: Oocyte[];
  }


  

export function getEmbryoCultureUrl(Id: string): string {
  return `/oocyte-details/cycle/${Id}`; 
}
  
export function getEmbryoCulture(Id: string): Promise<OocyteProcessing[]> {
  return getRequest(getEmbryoCultureUrl(Id), { responseType: 'json' });
}
