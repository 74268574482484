import { getRequest } from '../../../services/httpService';


export interface PgtResults {
  pgt_result_moisture: string | null;
  pgt_results: string | null;
  pgt_result_notes: string | null;
  pgt_result_embryo_sex: string | null;
}

export interface PgtOrder {
  pgt_order_date: string | null;
  pgt_order_time: string | null;
  pgt_order_biopsy_cells: number | null;
  pgt_facility: string | null;
  pgt_type: string | null;
}

export interface Embryo {
  id: number;
  cycle: number;
  day: number;
  grade: string | null;
  action: string | null;
  cleavage: string | null;
  embryo_number: number;
  fragmentation_percentage: string | null;
  insemination_technique: string | null;
  multi_nuclei: string | null;
  number_of_cells: string | null;
  pb: string | null;
  pgt_results: PgtResults;
  pn: string | null;
  sperm_source: string | null;
  symmetry: string | null;
  stage: string | null;
  files: string | null;
  location: string | null;
  cryo_preserved_date: string | null;
  cryo_preserved_by: string | null;
  cryo_purpose: string | null;
  cryo_media: string | null;
  is_screened: boolean;
  pgt_order: PgtOrder;
  status: string;
  sperm_source_details: string | null;
  files_url: string | null;
}

export interface EmbryoDetail {
  id: number;
  embryologist: string | null;
  witness: string | null;
  cycle: number;
  remarks: string | null;
  fertilization: string | null;
  is_assisted_hatching: boolean;
  culture_dish_config: string | null;
  day: number;
  time: string | null;
  opu_date: string | null;
  incubator: string | null;
}

export interface EmbryoData {
  embryo: Embryo[];
  details: EmbryoDetail[];
}

export interface DayItem {
  circle: number;
  text: string;
  grade?: string | null;
}

export interface DayData {
  day: string;
  items: DayItem[];
}
export function getEmbryoCultureUrl(Id: string): string {
  return `/cycle/${Id}/embryos/`; 
}
export function getEmbryoCulturel(Id: string): Promise<EmbryoData> {
  return getRequest(getEmbryoCultureUrl(Id), { responseType: 'json' });
}


export type PGTEmbryoData = {
  id: number;
  cycle: number;
  day: number;
  grade: string;
  action: string;
  pgt_result_moisture: string;
  embryo_number: number;
  pgt_result_notes: string;
  pgt_result_embryo_sex: string;
  pgt_results: string;
};



export function getPGTResultUrl(Id: string): string {
  return `/embryo/pgt-results/${Id}/`; 
}
export function getPGTResult(Id: string): Promise<PGTEmbryoData[]> {
  return getRequest(getPGTResultUrl(Id), { responseType: 'json' });
}