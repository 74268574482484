import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography'; // Import Typography
import { spermCryoStorageDetail } from '../cryoStorage.helpers';
import styles from './../oocyteCryo/cryoStorage.module.css';
import { capitalizeTitle, getDateInDDMMYYYY } from '../../../utils/commonUtils';


interface PopoverPanelProps {
  content: spermCryoStorageDetail; // Directly accepting the storage object
}

const PopoverPanel: React.FC<PopoverPanelProps> = ({ content }) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget); // Toggle Popover
  };

  const open = Boolean(anchorEl);

  // Mapping storage fields to labels and values
  const details = [
    { label: 'Cycle Number', value: content.cycle_number || '-' },
    { label: 'Patient Name', value: content.patient_name || '-' },
    { label: 'Patient MRN', value: content.patient_mrn || '-' },
    { label: 'Sample ID', value: content.sample_id || '-' },
    { label: 'Type of Sperm', value: capitalizeTitle(content.type_of_sperm) || '-' },
    { label: 'Volume (ml)', value: content.volume ? `${content.volume} ml` : '-' },
    { label: 'Semen Volume (ml)', value: content.semen_volume || '-' },
    { label: 'Sperm Concentration (million/ml)', value: content.sperm_concentration_million_per_ml || '-' },
    { label: 'Total Sperm Count (10⁶/Ejaculate)', value: content.total_sperm_count || '-' },
    { label: 'Material Count', value: content.material_count || '-' },
    { label: 'Status', value: capitalizeTitle(content.status) || '-' },
    { label: 'Cryo Date', value: getDateInDDMMYYYY(content.cryo_date) || '-' },
    { label: 'Expiry Date', value: content.expiry_date || '-' },
    { label: 'Remark', value: content.remark || '-' },
  ];

  return (
    <div style={{ display: 'inline-block',  }}>
      <InfoIcon
        onClick={handleClick}
        fontSize="small"
        style={{ cursor: 'pointer', color: '#EFAE78' }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
        <div style={{ padding: '16px', width: '400px' }}>
          {/* Title inside the popover */}
          <Typography
            variant="h6"
            style={{
              fontWeight: 700,
              marginBottom: '8px',
              borderBottom: '1px solid #ddd',
              paddingBottom: '4px',
              fontFamily: 'Montserrat',
            }}
          >
            Material Information
          </Typography>
          {/* Render storage details */}
          {details.map((detail, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '6px',
              }}
            >
              <span className={styles.detailLabel}>
                {detail.label}
              </span>
              <span className={styles.detailValue}>
                {detail.value}
              </span>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default PopoverPanel;
