/* eslint-disable @typescript-eslint/no-explicit-any */
import { uploadFileURLV2 } from '../../services/apiService';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../services/httpService';
import { get_users_clinic_only } from '../../services/urls';
import { FileTypes, PaginatedResponse, Patientv2, UserBasic } from '../../types';

  
export interface Cycle {
    id: number; // Required, readOnly
    procedure?: number | null; // Nullable
    medication_protocol?: number | null; // Nullable
    cycle_provider?: number | null; // Nullable
    provider_name: string; // Required, readOnly
    cycle_coordinator?: number | null; // Nullable
    coordinator_name: string; // Required, readOnly
    cycle_creator?: number | null; // Nullable
    cycle_start_date: string; // Required, ISO date format
    cycle_end_date?: string | null; // Nullable, ISO date format
    lmp_date?: string | null; // Nullable, ISO date format
    remarks?: string | null; // Nullable
    status: 'ACTIVE' | 'TERMINATED'; // Enum
    patient?: number | null; // Nullable,
    patient_name: string; // Required, readOnly
    cycle_number: string; // Required, readOnly
    cycle_name: string;
  }
  
export interface StimulationEvent {
    id: number; // Required, readOnly
    event_type: 'FA' | 'OPU' | 'ET' | 'LP'; // Enum
    date: string; // Required, ISO date format
    appointment: number; // Required
  }

export interface Drug {
    id: number;
    name: string;
    brand_name: string;
    molecule: string;
    strength: string | null;
  }
  

export interface StimulationMedication {
    id: number; // Required, readOnly
    drug: Drug; // Placeholder, array of drug objects (details not provided)
    drug_type: 'STIMULATION' | 'TRIGGER' | 'HORMONES'; // Enum
    start_date: string; // Required, ISO date format
    end_date: string; // Required, ISO date format
    quantity: number; // Required, integer
    start_time: string; // Required, ISO time format
  }
  
export interface Stim {
    id: number; // Required, readOnly
    patient: Patientv2; // Required
    cycle: Cycle; // Required
    events?: StimulationEvent[]; // Required, readOnly
    medications?: StimulationMedication[]; // Required, readOnly
  }

export interface EventAppointment {
    user: number;
    appointment_date: string; // ISO date format
    start_datetime: string; // ISO datetime format
    end_datetime: string; // ISO datetime format
    appointment_reason?: number;
    patient: number;
    partner?: number;
    room: number;
    table: number;
    remark: string;
    status: 'SCHEDULED' | 'COMPLETED' | 'CANCELLED'; // Example statuses
    type: 'APPOINTMENT' | 'FOLLOW_UP'; // Example types
    department?: number;
    patient_data?: Record<string, string>; // Flexible additional properties
    partner_data?: Record<string, string>; // Flexible additional properties
    embryologist: number;
  }
export interface Room {
    id: number;
    created_on: string; // ISO timestamp
    updated_on: string; // ISO timestamp
    deleted_on: string; // ISO timestamp
    name: string;
    tenant: number;
    department: number;
  }
  
export interface EventCycleRequest {
    event_type: 'FA' | 'OPU' | 'ET' | 'LP'; // Enum for event types
    date: string; // ISO date format
    stim_appointment: EventAppointment;
    stim_chart: number
  }

export interface Hormone {
    id: number;
    identifier: number;
    name: string;
    tag_name: string;
    icon_url: string;
    gender: 'MALE' | 'FEMALE' | 'OTHER';
  }
export interface Table {
  id: number;
  created_on: string; // ISO timestamp
  updated_on: string; // ISO timestamp
  deleted_on: string; // ISO timestamp
  name: string;
  tenant: number;
  room: number;
}

export interface OptionType {
    label: string;
    value: string;
  }

export interface Category {
    id: number;
    name: string;
    icon_url?: string; // Optional if not always present
    gender: 'FEMALE' | 'MALE' | 'OTHER'; // Assuming specific gender values
  }

export interface DrugPostRequest {
    drug: number; // ID or identifier for the drug
    drug_type: 'STIMULATION' | 'TRIGGER'; // Enum of drug types
    start_date: string; // Date in the format 'YYYY-MM-DD'
    quantity: number; // Quantity of the drug
    start_time: string; // Time in string format, e.g., 'HH:mm:ss'
    number_of_days: number; // Duration in days
    stim_chart: number
  }

export interface LaboratoryTest {
    id: number;
    identifier: string;
    name: string;
    icon_url?: string; // Optional if not always present
    categories: Category[]; // Assuming categories is an array of strings
}

export interface TestResult {
    id: number;
    patient: Patientv2;
    laboratory_test: LaboratoryTest;
    results?: number | null; // Nullable
    date: string; // Nullable date in string format
  }

export interface TestRequest {
    patient: number; // Adjust based on actual patient data schema
    laboratory_test: number;
    results?: number; // Nullable field
    date: string; // Nullable, formatted as ISO date string
    cycle?: number
  }

export interface FollicularStudyResultsList {
    id: number; // Read-only
    stimulation_chart?: number | null; // Nullable
    endometrium_thickness?: string | null; // Pattern: ^-?\d{0,3}(?:\.\d{0,2})?$
    date: string; // Date, Nullable
    time?: string | null; // Time, Nullable
    lmp_date: string; // Read-only
    ri?: string | null; // Pattern: ^-?\d{0,3}(?:\.\d{0,2})?$
    psv?: string | null; // Pattern: ^-?\d{0,3}(?:\.\d{0,2})?$
    endometrium_morphology?: EndometriumMorphologyEnum | null; // OneOf Enum, Nullable
    cyst_left_ovary?: string | null; // Pattern: ^-?\d{0,3}(?:\.\d{0,2})?$
    cyst_right_ovary?: string | null; // Pattern: ^-?\d{0,3}(?:\.\d{0,2})?$
    remark?: string | null; // Nullable
    details?: FollicularStudyDetails[] | null; // List of details, Nullable
    created_on: string; // Read-only DateTime
    cycle_day: string; // Read-only
    left_ovary_path_url: string; // Read-only
    right_ovary_path_url: string; // Read-only
  }
  
export interface FollicularStudyDetails {
    id: number; // Read-only
    left_follicle_size?: string | null; // Pattern: ^-?\d{0,3}(?:\.\d{0,2})?$, Nullable
    left_no_of_follicles?: string | null; // MaxLength: 50, Nullable
    right_follicle_size?: string | null; // Pattern: ^-?\d{0,3}(?:\.\d{0,2})?$, Nullable
    right_no_of_follicles?: string | null; // MaxLength: 50, Nullable
  }
  
export enum EndometriumMorphologyEnum {
    HYPERECHOEIC = 'HYPERECHOEIC', // Hyperechoeic
    ISOECHOEIC = 'ISOECHOEIC', // Isoechoeic
    TRIPLE_LAYER = 'TRIPLE_LAYER', // Triple Layer
    III_DEFINED = 'III DEFINED', // III Defined
    DOUBLE_LAYER = 'DOUBLE_LAYER', // Double Layer
  }

  interface FollicleDetail {
    left_follicle_size: string;
    left_no_of_follicles: string;
    right_follicle_size: string;
    right_no_of_follicles: string;
  }
  
export interface RequestBodyFS {
    stimulation_chart: string;
    endometrium_thickness: string;
    date: string;
    time: string;
    ri: string;
    psv: string;
    endometrium_morphology: string;
    cyst_left_ovary: string;
    cyst_right_ovary: string;
    remark: string;
    details: FollicleDetail[];
  }
export interface UploadFileResponse {
    presigned_url: string; // The URL for the uploaded file
    key: string;           // The storage key for the uploaded file
    expires_in: number;    // Time in seconds until the URL expires
}

export interface FileFollicularReq {
  file_name: string;
  source: FileTypes;
  ref_id: number;
  stim_id: number;
  date: string
}

export interface DonorInfo {
  recipient: {
    id: number;
    type: string;
    last_name: string;
    photo_url: string;
    first_name: string;
    medical_record_no: string;
  };
  sperm_source: Array<{
    id: number;
    type: string;
    last_name: string;
    photo_url: string;
    first_name: string;
    medical_record_no: string;
  }>;
  oocyte_source: Array<{
    id: number;
    type: string;
    last_name: string;
    photo_url: string | null;
    first_name: string;
    medical_record_no: string | null;
  }>;
}

export interface Service {
  id: number;
  name: string;
  code: string;
  description: string;
  service_type: string;
}

export interface Procedure {
  id: number;
  services: number[];
  procedures: string;
  procedures_type: string;
  procedure_notes: string | null;
  created_on: string; // ISO 8601 datetime
  donor_info: DonorInfo;
  indication: string;
}

export interface CycleData {
  id: number;
  procedure: Procedure | null;
  medication_protocol: string | null;
  cycle_start_date: string; // ISO 8601 date
  lmp_date: string; // ISO 8601 date
  status: string;
  cycle_number: string;
  services: Service[];
  stimulation_chart: number;
}

export interface Appointment {
  id: number;
  user: number;
  appointment_date: string; // ISO 8601 date
  start_datetime: string; // ISO 8601 datetime
  end_datetime: string; // ISO 8601 datetime
  appointment_reason: string | null;
  patient: number;
  partner: number;
  room: number;
  table: number;
  remark: string;
  status: string;
  type: string;
  department: string | null;
  embryologist: number;
}

export interface EventData {
  id: number;
  event_type: 'FA' | 'OPU' | 'ET' | 'LP'; // Enum for event types
  date: string; // ISO 8601 date
  appointment: Appointment;
}

export interface Drug {
  id: number;
  name: string;
  brand_name: string;
  molecule: string;
  strength: string | null;
  stock_uom_code: string;
  route_code: string;
}

export interface DrugSchema {
  id: number;
  drug: Drug;
  drug_type: 'STIMULATION' | 'OTHER'; // Adjust enum values as per your use case
  start_date: string; // ISO 8601 date string
  end_date: string;   // ISO 8601 date string
  quantity: number;
  start_time: string; // Adjust format if more specific
}

export function getUsersByClinicOnly(id: string): Promise<PaginatedResponse<UserBasic[]>> {
  return getRequest<PaginatedResponse<UserBasic[]>>(get_users_clinic_only(id));
}
  
export function getRooms(): Promise<PaginatedResponse<Room[]>> {
  return getRequest<PaginatedResponse<Room[]>>('/rooms/', { responseType: 'json' });
}

export function getTables(): Promise<PaginatedResponse<Table[]>> {
  return getRequest<PaginatedResponse<Table[]>>('/tables/', { responseType: 'json' });
}

export function createMedication(drug: number, drugType: 'STIMULATION' | 'TRIGGER', startDate: string, quantity: number, startTime: string, numberOfDays: number): Promise<undefined> {
  return postRequest('/stimulation-medication/?limit=100', {
    'drug': drug,
    'drug_type': drugType,
    'start_date': startDate,
    'quantity': quantity,
    'start_time': startTime,
    'number_of_days': numberOfDays
  });
}

export function getStimEvents(): Promise<{objects: Stim[]}> {
  return getRequest<{objects: Stim[]}>('/stimulation-chart/');
}


export function getCycle(id: number): Promise<CycleData[]> {
  return getRequest<CycleData[]>(`/cycle/patient/${id}`);
}

export function getStim(id: number): Promise<Stim> {
  return getRequest<Stim>(`/stimulation-chart/${id}`);
}

export function updateEvent(body: unknown, id: string): Promise<undefined> {
  return putRequest(`/stimulation-events/${id}`, body);
}

export function createEvent(body: EventCycleRequest): Promise<undefined> {
  return postRequest('/stimulation-events/', body);
}

export function getEvent(id: number): Promise<EventData> {
  return getRequest<EventData>(`/stimulation-events/${id}`);
}

export function deleteEvent(id: number): Promise<undefined> {
  return deleteRequest(`/stimulation-events/${id}`);
}

export function getDrugs(type: 'Stim' | 'Trigger'): Promise<PaginatedResponse<Drug[]>> {
  const isTrigger = type === 'Trigger'? true: false;
  const isStim = type === 'Stim'? true: false;
  return getRequest<PaginatedResponse<Drug[]>>(`/item/?is_stimulation_drug=${isStim}&is_trigger_drug=${isTrigger}`);
}

export function getDrug(id: string): Promise<Drug> {
  return getRequest<Drug>(`/item/${id}`);
}

// export function getEvent(id: string): Promise<Drug> {
//   return getRequest<Drug>(`/stimulation-events/${id}`);
// }

export function getHormones(): Promise<Hormone[]> {
  return getRequest<Hormone[]>('/investigation/get_hormones/');
}

export function getDrugDetails(id: number): Promise<DrugSchema> {
  return getRequest<DrugSchema>(`/stimulation-medication/${id}`);
}

export function createDrug(body: DrugPostRequest, id?: number): Promise<undefined> {
  if (id) {
    return putRequest(`/stimulation-medication/${id}`, body);
  }
  return postRequest('/stimulation-medication/', body);
}

export function getTestResults(patientId: number): Promise<TestResult[]> {
  return getRequest<TestResult[]>(`/investigation/patient/${patientId}`);
}

export function createTest(body: TestRequest, id?:number): Promise<undefined> {
  if (id) {
    return putRequest(`/investigation/${id}/hormones`, body);
  }
  return postRequest('/investigation/create_hormones/', body);
}

export function getFSResults(stim: number): Promise<PaginatedResponse<FollicularStudyResultsList[]>> {
  return getRequest<PaginatedResponse<FollicularStudyResultsList[]>>(`/follicular-study-results/?stimulation_chart=${stim}`);
}

export function getFSDetailResults(id: number): Promise<FollicularStudyResultsList> {
  return getRequest<FollicularStudyResultsList>(`/follicular-study-results/${id}`);
}

export function createFSDetailResults(body: RequestBodyFS): Promise<FollicularStudyResultsList> {
  return postRequest('/follicular-study-results/', body);
}

export function createEmptyFSDetailResults(body: {stimulation_chart: number, date: string, time: string }): Promise<FollicularStudyResultsList> {
  return postRequest('/follicular-study-results/', body);
}

export function createFSDetailResultsImages(body: FollicularStudyResultsList) {
  return postRequest('/follicular-study-results/', body);
}

export function uploadFile(body: FileFollicularReq, file: File): Promise<UploadFileResponse> {
  return uploadFileURLV2(body, file);
}

export function deleteMed(id: number): Promise<any> {
  return deleteRequest(`/stimulation-medication/${id}/`);
}

export function deleteEvents(id: number): Promise<any> {
  return deleteRequest(`/stimulation-events/${id}/`);
}

export function scheduleFA(user: number, appointment_date: string, start_datetime: string, end_datetime: string, patient: number, department: number, ) {
  const body = {
    user: user,
    appointment_date: appointment_date,
    start_datetime: start_datetime,
    end_datetime: end_datetime,
    appointment_reason: 1,
    type: 'APPOINTMENT',
    department: department,
    patient: patient
  };
  return postRequest('/appointments/', body);
}